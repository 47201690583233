import React from "react";
import { Positions } from "../api/methods";

const DeletePositionsPopup = ({
  name = "",
  showPopup,
  id,
  deletePosition,
  applicationId = false,
  applicationUpdate = () => {},
  positionsCount,
  isShowDeletePositionsPopup,
  setIsShowDeletePositionsPopup,
}) => {
  return (
    <div
      className={`DeletePositionsPopup ${
        isShowDeletePositionsPopup ? "show" : ""
      }`}
    >
      <div className="window">
        <div className="rightsPopup__title">Удаление позиции</div>
        <div className="rightsPopup__text">
          Вы действительно хотите удалить позицию <span>{name}</span>?
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowDeletePositionsPopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              if (applicationId) {
                Positions.delete(id, () => {
                  if (positionsCount === 1) {
                    applicationUpdate(1);
                  }
                });
              }
              deletePosition(id);
              setIsShowDeletePositionsPopup(false);
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeletePositionsPopup;
