import T from "../types";
import { Materials } from "../../api/methods";

const initialState = [];

export default function positionsPurchase(state = initialState, action) {
  let newState = [...state];
  switch (action.type) {
    case T.ADD_NEW_PURCHASE_POSITION:
      newState.unshift({ ...action.newPosition, id: newState.length });
      return newState;
    case T.UPDATE_PURCHASE_POSITION:
      const indexUpdate = newState.findIndex(
        (item) => String(item.id) === String(action.id)
      );
      const field = newState[indexUpdate];
      field[action.name] = action.value;
      // if (action.name === "name") {
      //   Materials.getMaterialByName(action.value, (res) => {
      //     field.materialId = res.data[0].id;
      //     field.unitId = res.data[0]["unit.id"];
      //     console.log(newState);
      //     return newState;
      //   });
      // }
      return newState;

    case T.DELETE_PURCHASE_POSITION:
      const indexDelete = state.findIndex((item) => item.id === action.id);
      newState.splice(indexDelete, 1);
      return newState;
    case T.CLEAR_PURCHASE_POSITIONS:
      return [];
    default:
      return state;
  }
}
