import React from "react";

const Checkbox = ({ name = "", checked = null, onChangeHandler }) => {
  return (
    <label>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChangeHandler}
      />
      <span></span>
    </label>
  );
};

export default Checkbox;
