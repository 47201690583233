import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import UserEditContainer from "./containers/UserEditContainer";
import Filling from "./components/Filling";
import Authorization from "./components/routes/Authorization";
import Main from "./components/Main";
import RequestPurchase from "./containers/RequestPurchase";
import RequestStock from "./containers/RequestStock";
import MainTableContainer from "./containers/MainTableContainer";
import AccTab from "./components/Acc/AccTab";
import Store from "./components/Store";
import Purchasing from "./components/Purchasing";
import Screen from "./components/Screen";
import NotFound from "./components/routes/NotFound";
import Archive from "./components/Archive";
// import Material from "./components/Material";
import Objects from "./components/Objects";
import Organizations from "./components/Organizations";
import UsersList from "./components/routes/UsersList";
import CreatingContainer from "./containers/CreatingContainer";
import UserCreating from "./components/routes/UserCreating";
import RequestCreating from "./components/RequestCreating";
import ViewApplication from "./components/ViewApplication";
import Alert from "./components/Alert";
import Statuses from "./Statuses";

function App() {
  return (
    <div className="App">
      <Alert />
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={() => (
              <Screen
                subtitle="Главная страница"
                // user="Константинопольский Константин Константинович"
                backButton="Выход"
              >
                <Main />
              </Screen>
            )}
          />

          <Route
            exact
            path="/users"
            component={() => (
              <Screen
                subtitle="Список пользователей"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <UsersList />
              </Screen>
            )}
          />
          <Route
            exact
            path="/users/:id"
            component={(props) => (
              <Screen
                subtitle="Администрирование"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/users"
              >
                <UserEditContainer id={Number(props.match.params.id)} />
              </Screen>
            )}
          />
          <Route
            exact
            path="/new-user"
            component={() => (
              <Screen
                subtitle="Администрирование"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/users"
              >
                <UserCreating />
              </Screen>
            )}
          />
          <Route
            exact
            path="/filling"
            component={() => (
              <Screen
                subtitle="Составление заявки"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Filling />
              </Screen>
            )}
          />
          <Route
            exact
            path="/new-request"
            component={() => (
              <Screen
                subtitle="Составление заявки"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/filling"
              >
                <RequestCreating />
              </Screen>
            )}
          />
          <Route
            exact
            path="/filling/:id"
            component={(props) => (
              <Screen
                subtitle="Редактирование заявки"
                backButton="Назад"
                backButtonPath="/filling"
              >
                <CreatingContainer id={Number(props.match.params.id)} />
              </Screen>
            )}
          />
          <Route
            exact
            path="/main-table"
            component={() => (
              <Screen subtitle="Счета" backButton="Назад" backButtonPath="/">
                <MainTableContainer />
              </Screen>
            )}
          />
          <Route
            exact
            path="/purchasing/:id"
            component={(props) => (
              <Screen
                subtitle="Выставление счета / Закупка материалов"
                backButton="Назад"
                backButtonPath="/purchasing"
              >
                <RequestPurchase id={Number(props.match.params.id)} />
              </Screen>
            )}
          />
          <Route
            exact
            path="/store/:id"
            component={(props) => (
              <Screen
                subtitle="Редактирование заявки / Склад"
                backButton="Назад"
                backButtonPath="/store"
              >
                <RequestStock id={Number(props.match.params.id)} />
              </Screen>
            )}
          />
          <Route
            exact
            path="/accounting"
            component={() => (
              <Screen
                subtitle="Бухгалтерия"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <AccTab />
              </Screen>
            )}
          />
          <Route
            exact
            path="/store"
            component={() => (
              <Screen
                subtitle="Склад"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Store />
              </Screen>
            )}
          />
          <Route
            exact
            path="/purchasing"
            component={() => (
              <Screen
                subtitle="Закупка материалов"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Purchasing />
              </Screen>
            )}
          />
          <Route
            exact
            path="/archive"
            component={() => (
              <Screen
                subtitle="Архив"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Archive />
              </Screen>
            )}
          />
          <Route
            exact
            path="/archive/:id"
            component={(props) => (
              <Screen
                subtitle="Просмотр заявки"
                backButton="Назад"
                backButtonPath="/archive"
              >
                <ViewApplication id={Number(props.match.params.id)} />
              </Screen>
            )}
          />
          {/* <Route
            exact
            path="/material"
            component={() => (
              <Screen
                subtitle="Материалы"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Material />
              </Screen>
            )}
          /> */}
          <Route
            exact
            path="/objects"
            component={() => (
              <Screen
                subtitle="Объекты заказчиков"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Objects />
              </Screen>
            )}
          />
          <Route
            exact
            path="/organizations"
            component={() => (
              <Screen
                subtitle="Наши организации"
                // user="Константинопольский Константин Константинович"
                backButton="Назад"
                backButtonPath="/"
              >
                <Organizations />
              </Screen>
            )}
          />
          <Route exact path="/status" component={Statuses} />
          <Route exact path="/authorization" component={Authorization} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
