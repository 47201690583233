import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Input from "../Input";
import Title from "../Title";
import RootsTable from "../RootsTable";
import { usersWords as L } from "../../languages/languages";
import { Users, Regions } from "../../api/methods";

function UserCreating() {
  const history = useHistory();
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [regions, setRegions] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("selected");
  const [roots, setRoots] = useState([]);

  const [isFieldsEmpty, setIsFieldsEmpty] = useState(true);

  useEffect(() => {
    const isSmthEmpty = !(email && login && name && regions.length > 0);
    setIsFieldsEmpty(isSmthEmpty);
  }, [email, login, name, regions]);

  useEffect(() => {
    Regions.getAll((res) => {
      setAllRegions(res.data);
      setSelectedRegion(res.data[0]);
    });
  }, []);

  const navigateToUserScreen = (id) => history.push("/users/" + id);

  const addRegionHandler = () => {
    updateSelectedRegion();
    setRegions([...regions, selectedRegion]);
  };

  const updateSelectedRegion = () => {
    const filteredRegions = allRegions.filter(
      (r) => r.id !== selectedRegion.id && !isInUserRegions(r.id)
    );
    const newSelectedRegion =
      filteredRegions.length === 0 ? "" : filteredRegions[0];
    setSelectedRegion(newSelectedRegion);
  };

  const deleteRegionHandler = (region) => {
    setRegions(regions.filter((r) => r.id !== region.id));
    setSelectedRegion(region);
  };

  const isInUserRegions = (id) => regions.filter((r) => r.id === id).length > 0;

  const createUser = () => {
    const alwaysTrueRoots = [];
    L.allwaysAllowsRoots.forEach(({ name, title }) => {
      alwaysTrueRoots.push(name + "Edit");
      alwaysTrueRoots.push(name + "Read");
    });
    Users.createUser(
      {
        email,
        login,
        name,
        status: 1,
        regions: regions.map((region) => region.id),
        roots: [...roots, ...alwaysTrueRoots],
      },
      ({ data: { id = null } }) => {
        navigateToUserScreen(id);
      }
    );
  };

  return (
    <div className="UserCreating container">
      <div className="row">
        <div className="Edit col-lg-5">
          <Title title={L.creatingUser} />
          <div className="name">
            <Input
              title={L.nameTitle}
              placeholder={L.namePlaceholder}
              value={name}
              onChangeHandler={(e) => setName(e.target.value)}
            />
          </div>
          <div className="login">
            <Input
              title={L.loginTitle}
              placeholder={L.loginPlaceholder}
              value={login}
              onChangeHandler={(e) => setLogin(e.target.value)}
            />
          </div>
          <div className="email">
            <Input
              title={L.emailTitle}
              placeholder={L.emailPlaceholder}
              value={email}
              onChangeHandler={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="addRegions">
            <div className="Input">
              <div className="top">
                <div className="title">{L.addRegionsTitle}</div>
                {selectedRegion === null || selectedRegion === "" ? null : (
                  <div className="addRegion" onClick={addRegionHandler}>
                    {L.addButton}
                  </div>
                )}
              </div>
              <div className="arrow"></div>
              <select
                value={JSON.stringify(selectedRegion)}
                id="regionSelect"
                onChange={(e) => setSelectedRegion(JSON.parse(e.target.value))}
              >
                <option value="selected" disabled>
                  {L.regionsPlaceholder}
                </option>

                {allRegions
                  .sort((prev, next) => (prev.name > next.name ? 1 : -1))
                  .map((region) =>
                    regions.find((item) => item.id === region.id) ? null : (
                      <option key={region.id} value={JSON.stringify(region)}>
                        {region.name}
                      </option>
                    )
                  )}
              </select>
            </div>
          </div>
          {regions.length === 0 ? null : (
            <div className="regions">
              <div className="Input">
                <div className="top">
                  <div className="title">{L.regionsTitle}</div>
                </div>
                <div className="addedRegions">
                  {regions.map((region) => (
                    <div className="region" key={region.id}>
                      <div className="title">{region.name}</div>
                      <div
                        className="removeRegion close"
                        onClick={() => deleteRegionHandler(region)}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="rightsButton">
            <button
              className="Button"
              disabled={isFieldsEmpty}
              onClick={createUser}
            >
              {isFieldsEmpty ? L.inCorrectNameLoginEmail : L.createUser}
            </button>
          </div>
        </div>
        <div className="Rights col-lg-7">
          <Title title={L.editingRights} />
          <RootsTable roots={roots} setRoots={setRoots} />
        </div>
      </div>
    </div>
  );
}

export default UserCreating;
