import React, { useEffect, useState } from "react";
import Input from "../Input";
import Title from "../Title";
import RootsTable from "../RootsTable";
import { usersWords as L } from "../../languages/languages";
import { Users, Regions } from "../../api/methods";
import RightsPopup from "../RightsPopup";
import { useHistory } from "react-router-dom";
import RegionPopup from "../RegionPopup";

function UserEdit({
  user,
  fetchUser,
  editField,
  editRoots,
  addRegion,
  removeRegion,
  id,
}) {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [wasUpdated, setWasUpdated] = useState(false);
  const [regions, setRegions] = useState([]);
  const history = useHistory();
  const [isShowRegionPopup, setIsShowRegionPopup] = useState(false);

  useEffect(() => {
    getAllRegions();
  }, [isShowRegionPopup]);

  const [isFieldEmpty, setIsFieldEmpty] = useState(true);
  useEffect(() => {
    const { login, name } = user;
    setIsFieldEmpty(login === "" || name === "");
  }, [user]);

  const [isSaveButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => setIsButtonDisabled(!wasUpdated || isFieldEmpty), [
    wasUpdated,
    isFieldEmpty,
  ]);

  const getAllRegions = () => {
    Regions.getAll((res) => {
      setRegions(res.data);
      setSelectedRegion(res.data[0]);
    });
  };

  useEffect(() => {
    Users.getUserById(id, (res) => {
      fetchUser(res.data);
    });
    getAllRegions();
  }, [fetchUser, id]);

  const showPopup = () => {
    const popup = document.getElementsByClassName("RightsPopup")[0];
    popup.classList.toggle("show");
    getAllRegions();
  };

  const editRootsHandler = (e) => {
    setWasUpdated(true);
    editRoots(e.target.name);
  };

  const addRegionHandler = () => {
    setWasUpdated(true);
    addRegion(selectedRegion);
    updateSelectedRegion();
  };

  const updateSelectedRegion = () => {
    const filteredRegions = regions.filter(
      (r) => r.id !== selectedRegion.id && !isInUserRegions(r.id)
    );
    const newSelectedRegion =
      filteredRegions.length === 0 ? "" : filteredRegions[0];
    setSelectedRegion(newSelectedRegion);
  };

  const deleteRegionHandler = (region) => {
    setWasUpdated(true);
    removeRegion(region);
    setSelectedRegion(region);
  };

  const isInUserRegions = (id) =>
    user.regions.filter((r) => r.id === id).length > 0;

  return (
    <div className="UserEdit container">
      <div className="row">
        <div className="Edit col-lg-5">
          <Title title={L.editingUser} />
          <div className="name">
            <Input
              title={L.nameTitle}
              placeholder={L.namePlaceholder}
              value={user.name}
              name="name"
              onChangeHandler={(e) => {
                setWasUpdated(true);
                editField(e.target.name, e.target.value);
              }}
            />
          </div>
          <div className="login">
            <Input
              title={L.loginTitle}
              placeholder={L.loginPlaceholder}
              value={user.login}
              name="login"
              onChangeHandler={(e) => {
                setWasUpdated(true);
                editField(e.target.name, e.target.value);
              }}
            />
          </div>
          <div className="addRegions">
            <div className="Input">
              <div className="top">
                <div
                  className="editRegion"
                  onClick={() => setIsShowRegionPopup(true)}
                >
                  Регионы
                </div>
                {selectedRegion === null || selectedRegion === "" ? null : (
                  <div className="addRegion" onClick={addRegionHandler}>
                    {L.addButton}
                  </div>
                )}
              </div>
              <div className="arrow"></div>
              <select
                value={JSON.stringify(selectedRegion)}
                id="regionSelect"
                onChange={(e) => {
                  setSelectedRegion(JSON.parse(e.target.value));
                }}
              >
                <option disabled>{L.regionsPlaceholder}</option>
                {regions
                  .sort((prev, next) => (prev.name > next.name ? 1 : -1))
                  .map((region) => {
                    if (region == null) return <option>Пусто</option>;
                    return user.regions.find(
                      (item) => item.id === region.id
                    ) ? null : (
                      <option key={region.id} value={JSON.stringify(region)}>
                        {region.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="regions">
            <div className="Input">
              <div className="addedRegions">
                {user.regions.map((region) => {
                  if (region == null) return <option>Пусто</option>;
                  return (
                    <div className="region" key={region.id}>
                      <div className="title">{region.name}</div>
                      <div
                        className="removeRegion close"
                        onClick={() => deleteRegionHandler(region)}
                      ></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="rightsButton">
            <button
              className="Button"
              disabled={isSaveButtonDisabled}
              onClick={() => {
                Users.updateDataUser(
                  { ...user, regions: user.regions.map((region) => region.id) },
                  setWasUpdated(false)
                );
              }}
            >
              {isFieldEmpty
                ? L.inCorrectUserOrLogin
                : wasUpdated === false
                ? L.makeChanges
                : L.userButton}
            </button>
            <div className="buttonDelete" onClick={() => showPopup()}>
              {L.userDelete}
            </div>
            <RightsPopup
              name={user.name}
              showPopup={showPopup}
              title={L.userDeleteTitle}
              text={L.userDelete}
              onClickCancel={() => showPopup()}
              onClickOk={() =>
                Users.deleteUser(id, () => {
                  setWasUpdated(true);
                  history.push("/users");
                })
              }
            />
            <RegionPopup
              isShowRegionPopup={isShowRegionPopup}
              setIsShowRegionPopup={setIsShowRegionPopup}
            />
          </div>
        </div>
        <div className="Rights col-lg-7">
          <Title title={L.editingRights} />
          <RootsTable roots={user.roots} editRoots={editRootsHandler} />
        </div>
      </div>
    </div>
  );
}

export default UserEdit;
