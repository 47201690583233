import React from "react";
import { Materials } from "../api/methods";

const AddNewMaterialPopup = ({
  isShowAddNewMaterialPopup,
  setIsShowAddNewMaterialPopup,
  materialName,
  unitName,
  setIsMaterialIsSave,
  name,
  unitTableId,
  setMaterials,
  setMaterialId,
  inputName
}) => {
  return (
    <div
      className={`AddNewMaterialPopup ${
        isShowAddNewMaterialPopup ? "show" : ""
      }`}
    >
      <div className="window">
        <div className="rightsPopup__title">Добавление материала</div>
        <div className="rightsPopup__text">
          Вы хотите добавить материал <span>{materialName}</span> с единицами
          измерения <span>{unitName}</span>?
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowAddNewMaterialPopup(false)}
          >
            Нет
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              setIsMaterialIsSave(true);
              setIsShowAddNewMaterialPopup(false);
              Materials.create(
                {
                  Material: {
                    name,
                    unitId: Number(unitTableId),
                    count: 0,
                  },
                },
                (res) => {
                  Materials.getAll((res) => {
                    setMaterials(res.data);
                  });
                  setMaterialId(res.data.id);
                  inputName.current.focus();
                }
              );
            }}
          >
            Да
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewMaterialPopup;
