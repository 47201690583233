export const mainWords = {
  title: "Заявка",
  city: "Иваново",
  region: "Север",
  quit: "Выход",
  contentTitle: "Добро пожаловать в систему",
  leftMenuTitle: "",
  leftMenuLinks: [
    { title: "Архив заявок", url: "/archive", key: "archivingOfOrdering" },
    { title: "Пользователи", url: "/users", key: "users" },
    // { title: "Материалы", url: "/material"},
    // { title: "Объекты заказчиков", url: "/objects"},
    { title: "Счета", url: "/main-table", key: "accounts" },
    // { title: "Наши организации", url: "/organizations"},
  ],
  rightMenuTitle: "",
  rightMenuLinks: [
    { title: "Составление заявки", url: "/filling", key: "createOrder" },
    { title: "Склад", url: "/store", key: "warehouse" },
    {
      title: "Закупка материалов",
      url: "/purchasing",
      key: "purchaseMaterial",
    },
    { title: "Бухгалтерия", url: "/accounting", key: "accounting" },
  ],
};

export const mainTableWords = {
  title: "Заявка",
  subtitle: "Счета",
  allAccounts: "Все счета",
  archive: "Архив",
  mainTableTitles: [
    { title: "", className: "id" },
    { title: "Номер счета", className: "accountNumber" },
    { title: "Сумма по счету", className: "invoiceAmount" },
    { title: "Поставщик", className: "supplier" },
    { title: "Заказчик", className: "client" },
    { title: "Дата оплаты", className: "dateOfPayment" },
  ],
  units: [
    { name: "шт" },
    { name: "мм" },
    { name: "см" },
    { name: "м" },
    { name: "км" },
    { name: "мм2" },
    { name: "см2" },
    { name: "м2" },
    { name: "км2" },
    { name: "га" },
  ],
};

export const usersWords = {
  title: "Заявка +",
  subtitle: "Администрирование",
  editingUser: "Редактирование пользователя",
  creatingUser: "Создание пользователя",
  loginTitle: "Логин",
  loginPlaceholder: "Введите логин",
  showButton: "Показать",
  addRegionsTitle: "Добавление региона",
  regionsPlaceholder: "Название региона",
  addButton: "Добавить",
  regionsTitle: "Регионы",
  userButton: "Сохранить",
  makeChanges: "Внесите изменения",
  inCorrectNameLoginEmail: "Заполните необходимые поля",
  createUser: "Создать пользователя",
  inCorrectUserOrLogin: "Введите Имя/Логин",
  editingRights: "Права доступа",
  userDelete: "Удалить пользователя",
  userDeleteTitle: "Удаление пользователя",
  rightsFirstColumn: "Раздел",
  rightsSecondColumn: "Просмотр",
  rightsThirdColumn: "Редактирование",
  regions: [
    "Иваново",
    "Москва",
    "Кохма",
    "Шуя",
    "Караганда",
    "Родники",
    "Палех",
    "Санкт-Петербург",
    "Сыктывкар",
    "Дурдом",
  ],
  roots: [
    { title: "Составление заявки", name: "createOrder" },
    { title: "Склад", name: "warehouse" },
    { title: "Закупка материалов", name: "purchaseMaterial" },
    { title: "Бухгалтерия", name: "accounting" },
    { title: "Пользователи", name: "users" },
    { title: "Счета", name: "accounts" },
    { title: "Архив заявок", name: "archivingOfOrdering" },
  ],
  allwaysAllowsRoots: [
    { title: "Человек, который измеряет", name: "measuringEmployees" },
    { title: "Объекты заказчиков", name: "objectsOfCustomer" },
    { title: "Наши организации", name: "ourOrganization" },
    { title: "Товары", name: "goods" },
    { title: "Единицы измерения", name: "units" },
  ],
  nameTitle: "Имя",
  emailTitle: "Email",
  namePlaceholder: "Введите имя",
  emailPlaceholder: "Введите email",
};

export const fillingWords = {
  title: "Заявка +",
  subtitle: "Составление заявки",
  subtitleStore: "Склад",
  subtitlePurchasing: "Закупка материалов",
  quit: "Выход",
  sortTitle: "Сортировать",
  searchTitle: "Искать по",
  searchSelectOptions: [
    { name: "Номеру счета", id: "contractNumber" },
    { name: "Порядковому номеру", id: "id" },
    { name: "Названию", id: "object" },
    { name: "Дате", id: "dateOfCreating" },
    { name: "Адресу", id: "addressOfOrganisation" },
    { name: "Компании", id: "organisation" },
  ],
  sortSelectOptions: [
    { name: "По убыванию", id: "descending" },
    { name: "По возрастанию", id: "ascending" },
  ],
  searchPlaceholder: "Поиск",
  createNewRequestButton: "+ Создать новую заявку",
  archiveButton: "Архив",
};

export const creatingWords = {
  order: "Заказ",
  title: "Редактирование заявки",
  updateNameMaterial: "Обновить название материала",
  backButton: "Назад",
  orderNumberTitle: "Номер заказа",
  requestStateTitle: "Состояние заявки",
  dateOfCreatingTitle: "Дата создания заявки",
  objectTitle: "Название объекта",
  addressTitle: "Адрес",
  objectAddressTitle: "Адрес обьекта",
  organizationTitle: "Название организации",
  billingDateTitle: "Дата выставления счета",
  createDateTitle: "Дата составления",
  deliveryDateTitle: "Дата доставки",
  closingDateTitle: "Дата оплаты и закрытия",
  tradeDateTitle: "Дата передачи со склада",
  requesterTitle: "Составитель",
  сalculationerTitle: "Произвел расчет",
  exchangeMade: "Обмер произвел",
  contractNumberTitle: "Номер договора",
  documentTitle: "Скачать отчет",
  printTitle: "Скачать отчет",
  noteTitle: "Примечание для ответственного",
  closeRequest: "Закрыть заявку",
  applicationDelete: "Удалить заявку",
  applicationDeleteTitle: "Удаление заявки",
  create: "Создать заявку",
  addNewMaterial: "Добавить материал",
  disabledCreate: "Заполните необходимые поля",
  noPositions: "Позиции не созданы",
  attach: "Прикрепить",
  info: "Инфо",
  informationTitle: "Информация",
  informationPopup: [
    { title: "Счет", subtitle: "Номер счета" },
    { title: "Товар", subtitle: ["Срок поставки", "Доставить до"] },
  ],
  createNewAccount: "Создать новый счет",
  unpin: "Открепить счет",
  notAccount: "* не относится к счету",
  noPurchaseRequired: "Закупка не требуется",
  requestStates: [
    "Новая",
    "Открыта",
    "Закрыта",
    "Изменено",
    "Создается",
    "Обработано",
    "Передано в закупку",
  ],
  colorDesctiption: [
    { color: "#d6ffdb", name: "Новая" },
    { color: "#c4ceff", name: "Обработана закупкой" },
    { color: "#f0d2ff", name: "Изменена составителем" },
    { color: "#ffffc6", name: "Изменена кладовщиком" },
  ],
  requestsTableTitles: [
    { title: "Позиция", className: "name" },
    { title: "Кол-во", className: "count" },
    { title: "Единица", className: "materialId" },
    { title: "Дата добавления", className: "dateAdding" },
    { title: "Срок поставки", className: "dateDelivery" },
    { title: "Состояние", className: "stateId" },
    { title: "Ссылка", className: "link" },
    { title: "Масса, кг", className: "weight" },
    { title: "Объем, куб. м", className: "volume" },
  ],
  requestsPurchaseTableTitles: [
    { title: "Позиция", className: "name" },
    { title: "Заявлено", className: "count" },
    { title: "Единица", className: "materialId" },
    { title: "На складе", className: "inStock" },
    { title: "Докупить", className: "buy" },
    { title: "Ссылка", className: "link" },
    { title: "Номер счета", className: "accountId" },
    { title: "Дата оплаты", className: "dateAccount" },
  ],

  requestsStockTableTitles: [
    { title: "Позиция", className: "name" },
    { title: "Кол-во", className: "count" },
    { title: "Единица", className: "materialId" },
    { title: "В наличии", className: "available" },
    { title: "Отпущено", className: "released" },
    { title: "Возврат", className: "return" },
    { title: "Докупить", className: "buy" },
    { title: "Состояние", className: "stateId" },
    { title: "Номер счета", className: "accountId" },
  ],

  lowerTableButton: "Добавить позицию",
  units: [
    { name: "м2", value: "m2" },
    { name: "шт", value: "sht" },
    { name: "мм", value: "mm" },
    { name: "м", value: "m" },
  ],
  completionOptionEdit: "Редактировать",
  completionOptionDelete: "Удалить",
  editPopupSave: "Сохранить",
  editPopupCancel: "Отмена",
  deletePopupSave: "Удалить",
  deletePopupCancel: "Не удалять",
  addToBase: "Добавить в базу",
  requestStatesColors: [
    { name: "Открыта", className: "state-blue" },
    { name: "Нет в наличии", className: "state-gray" },
    { name: "В обработке", className: "state-yellow" },
    { name: "Закрыта", className: "state-green" },
    { name: "В наличии", className: "state-blue" },
    { name: "Отпущено", className: "state-green" },
    { name: "Изменено", className: "state-blue" },
    { name: "Создается", className: "state-green" },
    { name: "Обработано", className: "state-green" },
    { name: "Передано в закупку", className: "state-green" },
  ],
  newPosition: ["Название", "URL", "Примечание к товару", "Название позиции"],
};

export const authorizationWords = {
  title: "Авторизация",
  loginPlaceholder: "Логин",
  passwordPlaceholder: "Пароль",
  signInButton: "Войти",
};

export const AccWords = {
  AccWordsTitle: [
    { title: " ", className: "id" },
    { title: "Номер счета", className: "accountNumber" },
    { title: "Дата выставления", className: "dateAccount" },
    { title: "Сумма,р", className: "invoiceAmount" },
    { title: "Заказчик", className: "client" },
    { title: "Поставщик", className: "supplier" },
  ],
};

export const usersTableWords = {
  title: "Заявка",
  subtitle: "Счета",
  allAccounts: "Все счета",
  archive: "Архив",
  usersTableTitles: [
    { title: "№", className: "id" },
    { title: "ФИО", className: "name" },
    { title: "Логин", className: "login" },
    // {title:"Почта",className:"email"},
  ],
};

export const accountCreateCard = {
  title: "Создание счета",
  account: "Счет",
  saveData: "Сохранить данные о счете",
  fillFields: "Заполните все поля",
  cancel: "Отмена",
  createCardDiv: { title: "Порядковый номер", className: "serial-Number" },
  createCardInput: [
    { title: "Номер счета", className: "acc-Num", name: "num", type: "" },
    {
      title: "Сумма по счету",
      className: "acc-Sum",
      name: "sum",
      type: "number",
    },
    {
      title: "Дата выставления",
      className: "acc-Date",
      type: "date",
      name: "date",
    },
    { title: "Заказчик", className: "acc-Cust", name: "cust" },
    { title: "Поставщик", className: "acc-Sup", name: "sup" },
  ],
};

export const storagePopupWords = {
  title: "Информация о счете",
  id: "Порядковый номер",
  accoutNumber: "Номер счета",
  date: "Дата выставления",
  sum: "Сумма",
  supplier: "Поставщик",
  cient: "Заказщик",
  positionName: "Название позиции",
  dateDelivery: "Доставить до",
  count: "Кол-во",
  toBuy: "Докупить",
  released: "Отпущено",
  returned: "Возврат",
  exit: "Скрыть",
};

export const materialNotFoundAlert =
  "Материал не найден. Пожалуйста, выберите из списка или создайте новый";
