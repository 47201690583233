import React from "react";
import { Link } from "react-router-dom";
import I from "../img";
import { mainWords as L } from "../languages/languages";

const Main = () => {
  const roots = JSON.parse(localStorage.getItem("roots"));

  return (
    <div className="Main">
      <div className="content">
        <div className="window">
          <img
            src={I.mainHeader}
            alt="content-header"
            className="window__header"
          />
          <div className="window__content">
            <div className="window__title">{L.contentTitle}</div>
            <div className="menu">
              <div className="menu__left">
                <div className="menu__title">{L.leftMenuTitle}</div>
                {L.leftMenuLinks.map((link) => {
                  if (!roots) {
                    return null;
                  }
                  if (
                    roots[link.key + "Edit"] === 1 ||
                    roots[link.key + "Read"] === 1
                  ) {
                    return (
                      <Link to={link.url} key={link.key}>
                        {link.title}
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className="menu__right">
                <div className="menu__title">{L.rightMenuTitle}</div>
                {L.rightMenuLinks.map((link) => {
                  if (!roots) {
                    return null;
                  }
                  if (
                    roots[link.key + "Edit"] === 1 ||
                    roots[link.key + "Read"] === 1
                  ) {
                    return (
                      <Link to={link.url} key={link.key}>
                        {link.title}
                      </Link>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
