import React from "react";
import { Materials } from "../api/methods";

const RemoveMaterialPopup = ({
  isShowDeleteMaterialPopup,
  setIsShowDeleteMaterialPopup,
  materialDelete,
  searchCompletion,
  name,
  loadAllData,
}) => {
  return (
    <div
      className={`RemoveMaterialPopup ${
        isShowDeleteMaterialPopup ? "show" : ""
      }`}
    >
      <div className="window">
        <div className="rightsPopup__title">Удаление материала</div>
        <div className="rightsPopup__text">
          Вы действительно хотите удалить материал "
          <span>{materialDelete.name}</span>"?
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowDeleteMaterialPopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              Materials.delete(materialDelete.materialId, () => {
                searchCompletion(name);
                setIsShowDeleteMaterialPopup(false);
                loadAllData();
              });
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveMaterialPopup;
