import React from "react";
import { usersTableWords as L } from "../../languages/languages";

function MainTitle(){
  return(
    <div className="title">
    {L.usersTableTitles.map((title,index)=>(
      <div className={title.className} key={index}>
        {title.title}
      </div>
    ))} 
    </div>
  );
};

export default MainTitle;