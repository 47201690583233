import React from "react";
import Popup from "./Popup";
import Title from "./Title";
import { creatingWords as L } from "../languages/languages";

const DeletePopup = ({ field, deleteCompletion, getCompletions }) => {
	const hide = (e) => {
		e.target.parentNode.parentNode.parentNode.classList.remove("show");
	};

	const content = (
		<>
			<Title title="Удаление" />
			<div className="field"></div>
			<div className="value"></div>
			<div className="answers">
				<div className="negative" onClick={hide}>
					{L.deletePopupCancel}
				</div>
				<div
					className="positive"
					onClick={(e) => {
						deleteCompletion(e, field);
						getCompletions();
					}}
				>
					{L.deletePopupSave}
				</div>
			</div>
		</>
	);

	return <Popup content={content} className="delete" />;
};

export default DeletePopup;
