import React, { useEffect } from "react";
import AutoComplete from "./AutoComplete";

const Textarea = ({
  title = "",
  className = "",
  name = "",
  placeholder = null,
  value = "",
  required = false,
  disabled = false,
  extra = "",
  completions = [],
  chooseCompletion,
  showEditPopup,
  showDeletePopup,
  onChangeHandler,
  onBlurHandler,
  dataId,
  rows="1"
}) => {
  return (
    <div className={"Input" + (className === "" ? "" : " " + className)}>
      <div className="top">
        <div className="title">{title}</div>
        {extra}
      </div>
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        rows={rows}
        data-id={dataId}
      ></textarea>
      <AutoComplete
        completions={completions}
        chooseCompletion={(e) => {
          chooseCompletion(e);
        }}
        showEditPopup={showEditPopup}
        showDeletePopup={showDeletePopup}
      />
    </div>
  );
};

// function autosize() {
//   let tx = document.querySelectorAll(".request textarea");

//   for (var i = 0; i < tx.length; i++) {
//     tx[i].setAttribute(
//       "style",
//       "height:" +
//         (tx[i].scrollHeight === 60
//           ? tx[i].scrollHeight
//           : tx[i].scrollHeight + 2) +
//         "px;overflow-y:hidden;"
//     );

//     function OnInput() {
//       this.style.height = "auto";
//       this.style.height = this.scrollHeight + 2 + "px";
//     }

//     tx[i].addEventListener("input", OnInput, false);
//   }
// }

// window.onload = autosize;

export default Textarea;
