import React from "react";
import LowerTableHeader from "./LowerTableHeader";
import LowerTableContent from "./LowerTableContent";

const LowerTable = ({
  positions = [],
  addNewPosition,
  dateChangeHandler,
  button = false,
  columnNames = [],
  fetchPositions,
  id,
  dateTranslate,
  deletePosition,
  updatePosition,
  completionsName,
  onAccountChange,
  fetchCompletions,
  units,
  disabled = false,
  applicationUpdate,
  loadAllData,
  positionsInfo = "",
}) => {
  return (
   // <div style={{overflow: "scroll"}}>
      <div className="Lower-table">
        {positions.length === 0 && !button ? (
          <></>
        ) : (
          <LowerTableHeader
            columnNames={columnNames}
            button={button}
            loadAllData={loadAllData}
          />
        )}
        <LowerTableContent
          loadAllData={loadAllData}
          positions={positions}
          dateChangeHandler={dateChangeHandler}
          addNewPosition={addNewPosition}
          button={button}
          columnNames={columnNames}
          fetchPositions={fetchPositions}
          id={id}
          onAccountChange={onAccountChange}
          dateTranslate={dateTranslate}
          deletePosition={deletePosition}
          updatePosition={updatePosition}
          completionsName={completionsName}
          fetchCompletions={fetchCompletions}
          units={units}
          disabled={disabled}
          applicationUpdate={applicationUpdate}
          positionsInfo={positionsInfo}
        />
      </div>
    //</div>
  );
};

export default LowerTable;
