import T from "../types";

const initialState = {
	accounts: [],
	filters: [
		{ field: "client", value: "" },
		{ field: "supplier", value: "" },
		{ field: "dateOfPayment", value: "" },
		{ field: "accountNumber", value: "" },
		{ field: "invoiceAmount", value: "" },
	],
};

export default function accounts(state = initialState, action) {
	let newState;
	switch (action.type) {
		case T.FETCH_ACCOUNTS:
			initialState.accounts = action.accounts;
			return { ...state, accounts: action.accounts };
		case T.FILTER_ACCOUNTS:
			state = { ...initialState };
			newState = { ...state };
			for (const filter of state.filters) {
				if (filter.value !== "") {
					newState.accounts = newState.accounts.filter((account) => {
						if (account[filter.field] !== null) {
							return String(account[filter.field])
								.toUpperCase()
								.includes(filter.value.toUpperCase());
						}
						return false;
					});
				}
			}
			return newState;
		case T.ADD_ACCOUNTS_FILTER:
			const index = state.filters.findIndex((item) => {
				return item.field === action.field;
			});
			newState = Object.assign({}, state);
			newState.filters[index].value = action.value;
			return newState;
		default:
			return state;
	}
}
