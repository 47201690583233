import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import LowerTable from "../components/LowerTable/LowerTable";
import DeletePopup from "../components/DeletePopup";
import EditPopup from "../components/EditPopup";
import { creatingWords as L } from "../languages/languages";
import { connect } from "react-redux";
import { Applications } from "../api/methods";
import { Units } from "../api/methods";
import {
  searchCompletions,
  chooseCompletion,
  editCompletion,
  deleteCompletion,
  addNewCompletion,
  pushRequestData,
  addNewPosition,
  fetchPositions,
  deletePosition,
  updatePosition,
  fetchUnits,
  getCompletions,
} from "../store/actions";
import DownloadReport from "../components/DownloadReport";
import { formatDate } from "../functions/functions";

const RequestPurchase = React.memo(
  ({
    completions,
    getCompletions,
    searchCompletions,
    chooseCompletion,
    addNewCompletion,
    editCompletion,
    deleteCompletion,
    fetchPositions,
    positions,
    id,
    updatePosition,
    deletePosition,
    fetchUnits,
    units,
  }) => {
    // positions= positions.filter((pos=>pos.inStock ))
    const [purchaseColor, setPurchaseColor] = useState(false);
    const [requestState, setRequestState] = useState({ name: "", id });
    const [object, setObject] = useState("");
    const [organization, setOrganization] = useState("");
    const [address, setAddress] = useState("");
    const [requester, setRequester] = useState("");
    const [exchangeMade, setExchangeMade] = useState("");
    const [contractNumber, setContractNumber] = useState("");
    const [comment, setComment] = useState("");
    const [billingDate, setBillingDate] = useState({ input: "", span: "" });
    const [closingDate, setClosingDate] = useState({ input: "", span: "" });
    const [dateOfCreating, setDateOfCreating] = useState({
      input: "",
      span: "",
    });

    const applicationUpdate = () => {
      Applications.update(
        {
          stateId: purchaseColor ? 7 : requestState.id,
          condition: requestState,
          dateOfCreating: dateOfCreating.input,
          object: object,
          addressOfOrganisation: address,
          organisation: organization,
          nameCreator: requester,
          contractNumber: `${contractNumber}`,
          dateAccount: closingDate.input,
          dateOfPayment: billingDate.input,
          nameUserCompleteCalculation:
            exchangeMade === null ? "" : exchangeMade,
          comment: comment ? comment : "",
        },
        () => {},
        id
      );
    };

    const onAccountChange = () => {
      console.log("dasdas");
      Applications.update(
        {
          stateId: 7,
          condition: requestState,
          dateOfCreating: dateOfCreating.input,
          object: object,
          addressOfOrganisation: address,
          organisation: organization,
          nameCreator: requester,
          contractNumber: `${contractNumber}`,
          dateAccount: closingDate.input,
          dateOfPayment: billingDate.input,
          nameUserCompleteCalculation:
            exchangeMade === null ? "" : exchangeMade,
          comment: comment ? comment : "",
        },
        loadAllData,
        id
      );
    };

    const dateTranslate = (date, type) => {
      if (date === null) return "";

      let stringDate = new Date(date);
      stringDate = stringDate.toLocaleDateString("ru-RU", {
        year: "numeric",
        month: type === "input" ? "numeric" : "long",
        day: "numeric",
      });
      if (type === "input") {
        stringDate = stringDate.split(".").reverse().join("-");
      }
      return stringDate;
    };

    const loadAllData = () => {
      Applications.getApplication(id, (res) => {
        console.log(res.data);
        setRequestState({
          name: res.data[0].states.name,
          id: res.data[0].states.id,
        });
        setObject(res.data[0].object);
        setOrganization(res.data[0].organisation);
        setAddress(res.data[0].addressOfOrganisation);
        setRequester(res.data[0].nameCreator);
        setExchangeMade(res.data[0].nameUserCompleteCalculation);
        setContractNumber(res.data[0].contractNumber);
        setComment(res.data[0].comment);
        setDateOfCreating({
          input: dateTranslate(res.data[0].dateOfCreating, "input"),
          span: dateTranslate(res.data[0].dateOfCreating, "span"),
        });
        setBillingDate({
          input: dateTranslate(res.data[0].dateOfPayment, "input"),
          span: dateTranslate(res.data[0].dateOfPayment, "span"),
        });
        setClosingDate({
          input: dateTranslate(res.data[0].dateAccount, "input"),
          span: dateTranslate(res.data[0].dateAccount, "span"),
        });
        fetchPositions(res.data[0].positions);
      });
      Units.getAll((res) => {
        fetchUnits(res.data);
      });
    };

    useEffect(() => {
      getCompletions();
      Applications.getApplication(id, (res) => {
        console.log(res.data);
        setRequestState({
          name: res.data[0].states.name,
          id: res.data[0].states.id,
        });
        setObject(res.data[0].object);
        setOrganization(res.data[0].organisation);
        setAddress(res.data[0].addressOfOrganisation);
        setRequester(res.data[0].nameCreator);
        setExchangeMade(res.data[0].nameUserCompleteCalculation);
        setContractNumber(res.data[0].contractNumber);
        setComment(res.data[0].comment);
        setDateOfCreating({
          input: dateTranslate(res.data[0].dateOfCreating, "input"),
          span: dateTranslate(res.data[0].dateOfCreating, "span"),
        });
        setBillingDate({
          input: dateTranslate(res.data[0].dateOfPayment, "input"),
          span: dateTranslate(res.data[0].dateOfPayment, "span"),
        });
        setClosingDate({
          input: dateTranslate(res.data[0].dateAccount, "input"),
          span: dateTranslate(res.data[0].dateAccount, "span"),
        });
        fetchPositions(res.data[0].positions);
      });
      Units.getAll((res) => {
        fetchUnits(res.data);
      });
    }, [id, fetchPositions, fetchUnits]);

    const onChangeHandler = (e) => {
      const { value, parentNode, previousSibling } = e.target;

      const text = value;
      const field = parentNode.parentNode.className;
      const fieldValue = completions[field];
      if (
        !fieldValue?.find(
          (completion) => completion.name.toUpperCase() === text.toUpperCase()
        ) &&
        value
      ) {
        previousSibling.lastChild.classList.remove("add-to-base--hidden");
      } else {
        previousSibling.lastChild.classList.add("add-to-base--hidden");
      }
      searchCompletions(text, field);
    };

    const showEditPopup = (e) => {
      const popup =
        e.target.parentNode.parentNode.parentNode.parentNode.nextSibling;
      const defaultValue = e.target.parentNode.previousSibling.textContent;
      popup.firstChild.firstChild.nextSibling.textContent = defaultValue;
      popup.firstChild.firstChild.nextSibling.nextSibling.firstChild.nextSibling.value = defaultValue;
      popup.classList.add("show");
    };

    const showDeletePopup = (e) => {
      const popup =
        e.target.parentNode.parentNode.parentNode.parentNode.nextSibling
          .nextSibling;
      const text = e.target.parentNode.previousSibling.textContent;
      let field =
        e.target.parentNode.parentNode.parentNode.parentNode.parentNode
          .className;
      switch (field) {
        case "object":
          field = "Объект";
          break;
        case "organization":
          field = "Организация";
          break;
        case "address":
          field = "Адрес";
          break;
        case "requester":
          field = "Составитель";
          break;
        case "calculationer":
          field = "Произвел расчет";
          break;
        default:
          break;
      }
      popup.firstChild.firstChild.nextSibling.textContent = field;
      popup.firstChild.firstChild.nextSibling.nextSibling.textContent = text;
      popup.classList.add("show");
    };
    return (
      <>
        <div className="Request-purchase container">
          <div className="request">
            <div className="order-number">
              <Input
                title={L.orderNumberTitle}
                value={id}
                required={true}
                disabled={true}
              />
            </div>
            <div className="request-state">
              <Input
                title={L.requestStateTitle}
                value={requestState.name}
                required={true}
                disabled={true}
              />
            </div>
            <div className="date-of-creating">
              <Input
                title={L.dateOfCreatingTitle}
                type="date"
                disabled
                required={true}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  setDateOfCreating({
                    input: e.target.value,
                    span: dateTranslate(e.target.value, "span"),
                  });
                }}
                onBlurHandler={applicationUpdate}
                value={dateOfCreating.input}
                spanText={dateOfCreating.span}
              />
            </div>
            <div className="object">
              <Textarea
                value={object}
                title={L.objectTitle}
                required={true}
                extra={
                  <div
                    className="add-to-base add-to-base--hidden"
                    onClick={(e) => {
                      addNewCompletion(e);
                      getCompletions();
                    }}
                  >
                    {L.addToBase}
                  </div>
                }
                completions={completions.object}
                chooseCompletion={(e) => chooseCompletion(e, setObject)}
                showEditPopup={showEditPopup}
                showDeletePopup={showDeletePopup}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  onChangeHandler(e);
                  setObject(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
                rows={"3"}
              />
              <EditPopup
                field="object"
                editCompletion={editCompletion}
                getCompletions={getCompletions}
              />
              <DeletePopup
                field="object"
                deleteCompletion={deleteCompletion}
                getCompletions={getCompletions}
              />
            </div>
            <div className="organization">
              <Textarea
                value={organization}
                title={L.organizationTitle}
                required={true}
                extra={
                  <div
                    className="add-to-base add-to-base--hidden"
                    onClick={(e) => {
                      addNewCompletion(e);
                      getCompletions();
                    }}
                  >
                    {L.addToBase}
                  </div>
                }
                completions={completions.organization}
                chooseCompletion={(e) => chooseCompletion(e, setOrganization)}
                showEditPopup={showEditPopup}
                showDeletePopup={showDeletePopup}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  onChangeHandler(e);
                  setOrganization(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
                rows={"3"}
              />
              <EditPopup
                field="organization"
                editCompletion={editCompletion}
                getCompletions={getCompletions}
              />
              <DeletePopup
                field="organization"
                deleteCompletion={deleteCompletion}
                getCompletions={getCompletions}
              />
            </div>
            <div className="address">
              <Textarea
                value={address}
                title={L.objectAddressTitle}
                required={true}
                // extra={
                //   <div
                //     className="add-to-base add-to-base--hidden"
                //     onClick={(e) => {
                // 			addNewCompletion(e);
                // 			getCompletions();
                // 		}}
                //   >
                //     {L.addToBase}
                //   </div>
                // }
                // completions={completions.address}
                // chooseCompletion={(e) => chooseCompletion(e, setAddress)}
                // showEditPopup={showEditPopup}
                // showDeletePopup={showDeletePopup}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  // onChangeHandler(e);
                  setAddress(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
                rows={"3"}
              />
              {/* <EditPopup
						field="address"
						editCompletion={editCompletion}
						getCompletions={getCompletions}
					/>
					<DeletePopup
						field="address"
						deleteCompletion={deleteCompletion}
						getCompletions={getCompletions}
					/> */}
            </div>
            <div className="billing-date">
              <Input
                title={L.createDateTitle}
                type="date"
                required={true}
                onBlurHandler={applicationUpdate}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  setBillingDate({
                    input: e.target.value,
                    span: dateTranslate(e.target.value, "span"),
                  });
                }}
                value={billingDate.input}
                spanText={billingDate.span}
              />
            </div>
            <div className="closing-date">
              <Input
                title={L.tradeDateTitle}
                type="date"
                required={true}
                onBlurHandler={applicationUpdate}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  setClosingDate({
                    input: e.target.value,
                    span: dateTranslate(e.target.value, "span"),
                  });
                }}
                value={closingDate.input}
                spanText={closingDate.span}
              />
            </div>
            <div className="requester">
              <Textarea
                value={requester}
                title={L.requesterTitle}
                placeholder="Иванов Иван Иванович"
                required={true}
                extra={
                  <div
                    className="add-to-base add-to-base--hidden"
                    onClick={(e) => {
                      addNewCompletion(e);
                      getCompletions();
                    }}
                  >
                    {L.addToBase}
                  </div>
                }
                completions={completions.requester}
                chooseCompletion={(e) => chooseCompletion(e, setRequester)}
                showEditPopup={showEditPopup}
                showDeletePopup={showDeletePopup}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  onChangeHandler(e);
                  return setRequester(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
              />
              <EditPopup
                field="requester"
                editCompletion={editCompletion}
                getCompletions={getCompletions}
              />
              <DeletePopup
                field="requester"
                deleteCompletion={deleteCompletion}
                getCompletions={getCompletions}
              />
            </div>
            <div className="calculationer">
              <Textarea
                value={exchangeMade}
                title={L.exchangeMade}
                placeholder="Иванов И.И."
                required={true}
                extra={
                  <div
                    className="add-to-base add-to-base--hidden"
                    onClick={(e) => {
                      addNewCompletion(e);
                      getCompletions();
                    }}
                  >
                    {L.addToBase}
                  </div>
                }
                completions={completions.exchangeMade}
                chooseCompletion={(e) => chooseCompletion(e, setExchangeMade)}
                showEditPopup={showEditPopup}
                showDeletePopup={showDeletePopup}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  onChangeHandler(e);
                  return setExchangeMade(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
              />
              <EditPopup
                field="calculationer"
                editCompletion={editCompletion}
                getCompletions={getCompletions}
              />
              <DeletePopup
                field="calculationer"
                deleteCompletion={deleteCompletion}
                getCompletions={getCompletions}
              />
            </div>
            <div className="contract-number">
              <Input
                value={contractNumber}
                title={L.contractNumberTitle}
                required={true}
                onChangeHandler={(e) => {
                  setPurchaseColor(true);
                  setContractNumber(e.target.value);
                }}
                onBlurHandler={applicationUpdate}
              />
            </div>
            <div className="document">
              <DownloadReport id={id} />
            </div>
            <div className="note">
              <div className="Input">
                <div className="top">
                  <div className="title">{L.noteTitle}</div>
                </div>
                <textarea
                  onBlur={() => applicationUpdate()}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="Request-purchase-table container">
          <LowerTable
            positions={positions}
            columnNames={L.requestsPurchaseTableTitles}
            deletePosition={deletePosition}
            updatePosition={updatePosition}
            onAccountChange={onAccountChange}
            dateChangeHandler={dateTranslate}
            addNewPosition={addNewPosition}
            fetchPositions={fetchPositions}
            id={id}
            dateTranslate={dateTranslate}
            units={units}
            positionsInfo={L.noPurchaseRequired}
          />
        </div>
      </>
    );
  }
);

const mapStateToProps = (state) => ({
  completions: state.completions,
  positions: state.positions,
  units: state.units,
});

const mapDispatchToProps = (dispatch) => ({
  getCompletions: () => {
    return dispatch(getCompletions());
  },

  searchCompletions: (text, field) => {
    return dispatch(searchCompletions(text, field));
  },

  chooseCompletion: (e, setValue) => {
    const text = e.target.textContent;
    const field =
      e.target.parentNode.parentNode.parentNode.parentNode.className;
    const input = e.target.parentNode.parentNode.previousSibling;
    input.value = text;
    setValue(text);
    e.target.parentNode.parentNode.previousSibling.previousSibling.lastChild.classList.add(
      "add-to-base--hidden"
    );
    dispatch(pushRequestData(text, field));
    return dispatch(chooseCompletion(text, field));
  },

  addNewCompletion: (e) => {
    const text = e.target.parentNode.nextSibling.value;
    const field = e.target.parentNode.parentNode.parentNode.className;
    e.target.classList.add("add-to-base--hidden");
    return dispatch(addNewCompletion(text, field));
  },

  editCompletion: (e, field) => {
    const oldText =
      e.target.parentNode.previousSibling.previousSibling.textContent;
    const newText =
      e.target.parentNode.previousSibling.firstChild.nextSibling.value;
    if (newText === "") {
      e.target.parentNode.previousSibling.firstChild.nextSibling.focus();
    } else {
      e.target.parentNode.parentNode.parentNode.classList.remove("show");
      return dispatch(editCompletion(oldText, newText, field));
    }
  },

  deleteCompletion: (e, field) => {
    const text = e.target.parentNode.previousSibling.textContent;
    e.target.parentNode.parentNode.parentNode.classList.remove("show");
    return dispatch(deleteCompletion(text, field));
  },

  pushRequestData: (e) => {
    const value = e.target.value;
    let field = e.target;
    while (field.className !== "Input") {
      field = field.parentNode;
    }
    field = field.parentNode.className;
    return dispatch(pushRequestData(value, field));
  },

  addNewPosition: (newPosiition) => {
    return dispatch(addNewPosition(newPosiition));
  },

  fetchPositions: (positions) => {
    return dispatch(fetchPositions(positions));
  },

  deletePosition: (id) => {
    return dispatch(deletePosition(id));
  },

  updatePosition: (id, name, value) => {
    return dispatch(updatePosition(id, name, value));
  },

  fetchUnits: (units) => {
    return dispatch(fetchUnits(units));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestPurchase);
