import React, { useState } from "react";
import UnitEditPopup from "../UnitEditPopup";

const LowerTableHeader = ({
  columnNames = [],
  button,
  loadAllData = () => {},
}) => {
  const [isShowUnitEditPopup, setIsShowUnitEditPopup] = useState(false);
  return (
    <>
      <div className="Lower-table-header">
        {columnNames.map((title, index) => (
          <div
            className={title.className}
            key={index}
            onClick={() => {
              if (title.className === "materialId" && button === true) {
                setIsShowUnitEditPopup(true);
              }
            }}
          >
            {title.title}
          </div>
        ))}
      </div>
      <UnitEditPopup
        isShowUnitEditPopup={isShowUnitEditPopup}
        setIsShowUnitEditPopup={setIsShowUnitEditPopup}
        loadAllData={loadAllData}
      />
    </>
  );
};

export default LowerTableHeader;
