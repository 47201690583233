import React from "react";
import { useEffect, useState } from "react";
import { Units } from "../api/methods";
import Input from "./Input";

const ChangeUnitPopup = ({
  isShowChangeUnitPopup,
  setIsShowChangeUnitPopup,
  unit,
  getAllUnits,
  loadAllData,
}) => {
  const [changeUnit, setChangeUnit] = useState("");
  const [newName, setNewName] = useState("");

  useEffect(() => {
    Units.getUnit(unit, (res) => {
      setChangeUnit(res.data.name);
    });
  }, [unit]);

  return (
    <div className={`ChangeUnitPopup ${isShowChangeUnitPopup ? " show" : ""}`}>
      <div className="window">
        <div className="rightsPopup__title">Изменение единицы измерения</div>
        <div className="rightsPopup__content">
          Изменить <span>{changeUnit}</span> на
          <Input
            value={newName}
            onChangeHandler={(e) => setNewName(e.target.value)}
          />
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowChangeUnitPopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              Units.update(
                {
                  Unit: {
                    id: Number(unit),
                    name: newName,
                  },
                },
                () => {
                  getAllUnits();
                  setIsShowChangeUnitPopup(false);
                  loadAllData();
                }
              );
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeUnitPopup;
