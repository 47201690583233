import T from "./types";

export const editInput = (text) => ({
  type: T.EDIT_INPUT,
  text,
});

export const fetchUser = (user) => ({
  type: T.FETCH_USER,
  user,
});

export const editField = (field, value) => ({
  type: T.EDIT_FIELD,
  field,
  value,
});

export const editRoots = (root) => ({
  type: T.EDIT_ROOTS,
  root,
});

export const addRegion = (region) => ({
  type: T.ADD_REGION,
  region,
});

export const removeRegion = (region) => ({
  type: T.REMOVE_REGION,
  region,
});

export const changeCheckbox = (root) => ({
  type: T.CHANGE_CHECKBOX,
  root,
});

export const searchRequests = (text, searchBy, requests) => ({
  type: T.SEARCH_REQUESTS,
  text,
  searchBy,
  requests,
});

export const getCompletions = () => ({
  type: T.GET_COMPLETIONS,
});

export const searchCompletions = (text, field) => ({
  type: T.SEARCH_COMPLETIONS,
  text,
  field,
});

export const chooseCompletion = (text, field) => ({
  type: T.CHOOSE_COMPLETION,
  text,
  field,
});

export const addNewCompletion = (text, field) => ({
  type: T.ADD_NEW_COMPLETION,
  text,
  field,
});

export const editCompletion = (oldText, newText, field) => ({
  type: T.EDIT_COMPLETION,
  oldText,
  newText,
  field,
});

export const deleteCompletion = (text, field) => ({
  type: T.DELETE_COMPLETION,
  text,
  field,
});

export const addNewPosition = (newPosition) => ({
  type: T.ADD_NEW_POSITION,
  newPosition: newPosition,
});

export const addNewPurchasePosition = (newPosition) => ({
  type: T.ADD_NEW_PURCHASE_POSITION,
  newPosition: newPosition,
});

export const pushRequestData = (value, field) => ({
  type: T.PUSH_REQUEST_DATA,
  value,
  field,
});

export const filterAccounts = () => ({
  type: T.FILTER_ACCOUNTS,
});

export const addAccountsFilter = (value, field) => ({
  type: T.ADD_ACCOUNTS_FILTER,
  value,
  field,
});

export const fetchUsers = (users) => ({
  type: T.FETCH_USERS,
  users,
});

export const fetchRequests = (requests) => ({
  type: T.FETCH_REQUESTS,
  requests,
});

export const fetchPositions = (positions) => ({
  type: T.FETCH_POSITIONS,
  positions,
});

export const deletePosition = (id) => ({
  type: T.DELETE_POSITION,
  id,
});

export const deletePurchasePosition = (id) => ({
  type: T.DELETE_PURCHASE_POSITION,
  id,
});

export const updatePosition = (id, name, value) => ({
  type: T.UPDATE_POSITION,
  id,
  name,
  value,
});

export const updatePurchasePosition = (id, name, value) => ({
  type: T.UPDATE_PURCHASE_POSITION,
  id,
  name,
  value,
});

export const fetchCompletions = (completions) => ({
  type: T.FETCH_COMPLETIONS,
  completions,
});

export const fetchUnits = (units) => ({
  type: T.FETCH_UNITS,
  units,
});

export const sortRequests = (sortBy, requests) => ({
  type: T.SORT_REQUESTS,
  sortBy,
  requests,
});

export const fetchAccounting = (accounting) => ({
  type: T.FETCH_ACCOUNTING,
  accounting,
});

export const fetchAccounts = (accounts) => ({
  type: T.FETCH_ACCOUNTS,
  accounts,
});

export const clearPurchasePosition = () => ({
  type: T.CLEAR_PURCHASE_POSITIONS,
});

export const showAlert = (text) => ({
  type: T.SHOW_ALERT,
  text,
});

export const closeAlert = () => ({
  type: T.CLOSE_ALERT,
});
