import React from "react";
import { Accounts } from "../../api/methods";
import { mainTableWords as L } from "../../languages/languages";
import MainCell from "./MainCell";

function MainRow({
  row = {},
  setAccountId,
  setIsActiveStoragePopup,
  getAllAccounts,
}) {
  const dateTranslate = (date) => {
    if (date === null) return "";
    let stringDate = new Date(date);
    stringDate = stringDate.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return stringDate;
  };

  const onClickRemoveAccount = () => {
    const isDelete = window.confirm(
      `Вы действительно хотите удалить счет с номером ${row.accountNumber} ?`
    );

    if (isDelete) {
      Accounts.delete(row.id, () => getAllAccounts());
    }
  };

  return (
    <div className="Main-Str ">
      {L.mainTableTitles.map((title, index) => {
        let SizeContent = row[title.className]?.length;
        let Size = 48;
        let contentNew;
        let newClass;
        if (SizeContent > Size) {
          contentNew = row[title.className].slice(0, 43) + "...";
          newClass = title.className + " active";
        } else {
          contentNew = row[title.className];
          newClass = title.className;
        }
        if (title.className === "dateOfPayment") {
          contentNew = dateTranslate(row[title.className]);
        }
        if (row[title.className] === null) {
          contentNew = "Не проставлена";
        }
        let content = (
          <div
            className={newClass}
            key={index}
            onClick={
              title.className === "accountNumber"
                ? () => {
                    setAccountId(row.id);
                    console.log(row.id);
                    setIsActiveStoragePopup(true);
                  }
                : null
            }
          >
            {contentNew}
          </div>
        );
        return (
          <MainCell
            content={content}
            key={index}
            className={title.className}
            AllText={row[title.className]}
          />
        );
      })}
      <div className="removeAccount-block">
        <div
          className="removeAccount close"
          onClick={onClickRemoveAccount}
        ></div>
      </div>
    </div>
  );
}

export default MainRow;
