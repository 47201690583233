import React, { useState } from "react";
import LowerTableRow from "./LowerTableRow";
import LowerTableButton from "./LowerTableButton";
import { creatingWords as C } from "../../languages/languages";

const LowerTableContent = ({
  positions = [],
  dateChangeHandler,
  addNewPosition,
  button = false,
  columnNames = [],
  id,
  dateTranslate,
  deletePosition,
  updatePosition,
  completionsName,
  onAccountChange,
  fetchCompletions,
  units,
  disabled,
  applicationUpdate,
  loadAllData,
  positionsInfo,
}) => {
  const [isCreating, setIsCreating] = useState(false);
  let positionsLength = positions.length;
  const hideCreatingRow = () => {
    setIsCreating(false);
    const element = document.getElementsByClassName(
      "Lower-table-row-content"
    )[0];
    element.classList.toggle("active");
  };

  return (
    <div className="Lower-table-content">
      {button && !disabled ? (
        <div className="lower-table-up">
          {isCreating ? null : (
            <LowerTableButton
              addNewPosition={addNewPosition}
              positions={positions}
              onHandlerClick={() => {
                setIsCreating(true);
                const element = document.getElementsByClassName(
                  "Lower-table-row-content"
                )[0];
                element.classList.toggle("active");
                const target = document.getElementsByClassName("name")[1];
                target.focus();
              }}
            />
          )}
          <LowerTableRow
            row={newPosition}
            dateChangeHandler={dateChangeHandler}
            columnNames={columnNames}
            addNewPosition={addNewPosition}
            setIsCreating={setIsCreating}
            check={true}
            dateTranslate={dateTranslate}
            button={button}
            id={id}
            completionsName={completionsName}
            fetchCompletions={fetchCompletions}
            units={units}
            applicationUpdate={applicationUpdate}
            loadAllData={loadAllData}
            hideCreatingRow={hideCreatingRow}
            deletePosition={deletePosition}
            positionsLength={positionsLength}
            isCreating={isCreating}
          />
        </div>
      ) : null}

      {positions.length === 0 ? (
        <div className="positionsInfo">{positionsInfo}</div>
      ) : (
        positions.map((row) => {
          return (
            <LowerTableRow
              row={row}
              key={row.id}
              dateChangeHandler={dateChangeHandler}
              columnNames={columnNames}
              button={button}
              onAccountChange={onAccountChange}
              dateTranslate={dateTranslate}
              updatePosition={updatePosition}
              id={id}
              completionsName={completionsName}
              fetchCompletions={fetchCompletions}
              units={units}
              disabled={disabled}
              applicationUpdate={applicationUpdate}
              loadAllData={loadAllData}
              deletePosition={deletePosition}
              positionsLength={positionsLength}
            />
          );
        })
      )}
    </div>
  );
};

const newPosition = {
  name: "",
  count: "",
  unit: "м2",
  dateAdding: "25 марта 2020",
  deliveryDate: "",
  stateId: 1,
  link: "",
  url: "",
  weight: "",
  volume: "",
  notes: "",
};

export default LowerTableContent;
