import React from "react";
import { useEffect, useState } from "react";
import { Customers, Providers } from "../api/methods";
import Input from "./Input";

const UpdatePopup = ({
  isShowEditPopup,
  setIsShowEditPopup,
  idToUpdate = "",
  getAllCustomers,
  subjectUpdate,
  getAllProviders,
}) => {
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  useEffect(() => {
    console.log(idToUpdate);
    subjectUpdate === "cust"
      ? Customers.getCustomer(idToUpdate, (res) => {
          setOldName(res.data.name);
        })
      : Providers.getProvider(idToUpdate, (res) => {
          setOldName(res.data.name);
        });
  }, [idToUpdate]);
  return (
    <div className={`EditCustomerPopup ${isShowEditPopup ? "show" : ""}`}>
      <div className="window">
        <div className="rightsPopup__title">
          {subjectUpdate === "cust"
            ? "Редактирование заказчика"
            : "Редактирование поставщика"}
        </div>
        <div className="rightsPopup__text">{oldName}</div>
        <div className="rigthsPopup__content">
          <Input
            required={true}
            value={newName}
            onChangeHandler={(e) => setNewName(e.target.value)}
          />
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => {
              setIsShowEditPopup(false);
              setNewName("");
            }}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              subjectUpdate === "cust"
                ? Customers.update(
                    {
                      Customer: {
                        id: idToUpdate,
                        name: newName,
                      },
                    },
                    () => {
                      getAllCustomers();
                      setNewName("");
                      setIsShowEditPopup(false);
                    }
                  )
                : Providers.update(
                    {
                      id: Number(idToUpdate),
                      name: newName,
                    },
                    () => {
                      getAllProviders();
                      setNewName("");
                      setIsShowEditPopup(false);
                    }
                  );
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePopup;
