import React from "react";
import UsersRow from "./UsersRow";

function UsersStr({ users }) {
  return (
    <div className="Main-alter">
      {users.map((item) => {
        return <UsersRow row={item} key={item.id} />;
      })}
    </div>
  );
}

export default UsersStr;
