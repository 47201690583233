import React from "react";
import { useEffect, useState } from "react";
import { Customers, Providers } from "../api/methods";
import Input from "./Input";

const DeleteCustomerPopup = ({
  isShowDeletePopup,
  setIsShowDeletePopup,
  idToUpdate = "",
  getAllCustomers,
  getAllProviders,
  subjectUpdate,
}) => {
  const [oldName, setOldName] = useState("");
  useEffect(() => {
    subjectUpdate === "cust"
      ? Customers.getCustomer(idToUpdate, (res) => {
          setOldName(res.data.name);
        })
      : Providers.getProvider(idToUpdate, (res) => {
          setOldName(res.data.name);
        });
  }, [idToUpdate]);
  return (
    <div className={`DeleteCustomerPopup ${isShowDeletePopup ? "show" : ""}`}>
      <div className="window">
        <div className="rightsPopup__title">
          {subjectUpdate === "cust"
            ? "Удаление заказчика"
            : "Удаление поставщика"}
        </div>
        <div className="rightsPopup__text">
          Вы действительно хотите удалить заказчика <span>{oldName}</span>?
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowDeletePopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              subjectUpdate === "cust"
                ? Customers.delete(idToUpdate, () => {
                    getAllCustomers();
                    setIsShowDeletePopup(false);
                  })
                : Providers.delete(idToUpdate, () => {
                    getAllProviders();
                    setIsShowDeletePopup(false);
                  });
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCustomerPopup;
