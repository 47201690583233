import React from "react";
import { useHistory } from "react-router-dom";
import { fillingWords as L } from "../languages/languages";
import RequestContainer from "../containers/RequestContainer";
import PrintString from "./PrintString";

function Store() {
  const history = useHistory();
  return (
    <>
      <RequestContainer hoverElement={<PrintString />} path="store/">
        <div className="archive" onClick={() => history.push("/archive")}>
          {L.archiveButton}
        </div>
      </RequestContainer>
    </>
  );
}

export default Store;
