import React from "react";
import { useEffect, useState } from "react";
import { Regions } from "../api/methods";
import Input from "./Input";
import Select from "./Select";
import DeleteRegionPopup from "./DeleteRegionPopup";
import ChangeRegionPopup from "./ChangeRegionPopup";
import { useDispatch } from "react-redux";
import { showAlert } from "../store/actions";

const RegionPopup = ({ isShowRegionPopup, setIsShowRegionPopup }) => {
  const getAllRegions = () => {
    Regions.getAll((res) => {
      setAllRegions(res.data);
      setRegion(res.data[0].id);
    });
  };

  useEffect(() => {
    getAllRegions();
  }, []);

  const [allRegions, setAllRegions] = useState([]);
  const [newRegionName, setNewRegionName] = useState("");
  const [region, setRegion] = useState();
  const [isShowChangeRegionPopup, setIsShowChangeRegionPopup] = useState(false);
  const [isShowDeleteRegionPopup, setIsShowDeleteRegionPopup] = useState(false);

  const dispatch = useDispatch();

  const createRegion = () => {
    const success = () => {
      getAllRegions();
      setNewRegionName("");
      dispatch(showAlert("Регион был успешно создан"));
    };
    if (newRegionName !== "")
      Regions.create(
        {
          Region: {
            name: newRegionName,
          },
        },
        success
      );
  };

  return (
    <>
      <div className={`RegionPopup ${isShowRegionPopup ? "show" : ""}`}>
        <div className="window">
          <div className="rightsPopup__title">
            Регионы
            <div
              className="rightsPopup__buttons-true"
              onClick={() => {
                setIsShowRegionPopup(false);
              }}
            >
              Закрыть
            </div>
          </div>
          <div className="rightsPopup__content">
            <div className="unitEditAdd">
              <Input
                classNameInput="unitEditAdd__input"
                value={newRegionName}
                onChangeHandler={(e) => setNewRegionName(e.target.value)}
              />
              <div className="unitEditAdd__button" onClick={createRegion}>
                +
              </div>
            </div>
            <div className="unitEditChange">
              <Select
                options={allRegions}
                value={region}
                onChangeHandler={(e) => setRegion(e.target.value)}
              />
              <div
                className="unitEditChange__button-change"
                onClick={() => setIsShowChangeRegionPopup(true)}
              >
                Изменить
              </div>
              <div
                className="unitEditChange__button-delete"
                onClick={() => setIsShowDeleteRegionPopup(true)}
              >
                Удалить
              </div>
            </div>
          </div>
          <div className="rightsPopup__buttons"></div>
        </div>
      </div>
      <ChangeRegionPopup
        isShowChangeRegionPopup={isShowChangeRegionPopup}
        setIsShowChangeRegionPopup={setIsShowChangeRegionPopup}
        region={region}
        getAllRegions={getAllRegions}
      />
      <DeleteRegionPopup
        isShowDeleteRegionPopup={isShowDeleteRegionPopup}
        setIsShowDeleteRegionPopup={setIsShowDeleteRegionPopup}
        region={region}
        getAllRegions={getAllRegions}
      />
    </>
  );
};

export default RegionPopup;
