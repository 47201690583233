import Creating from "../components/Creating";
import { connect } from "react-redux";
import {
  searchCompletions,
  chooseCompletion,
  editCompletion,
  deleteCompletion,
  addNewCompletion,
  pushRequestData,
  addNewPosition,
  fetchPositions,
  deletePosition,
  updatePosition,
  fetchCompletions,
  fetchUnits,
  getCompletions,
} from "../store/actions";

const mapStateToProps = (state) => ({
  completions: state.completions,
  positions: state.positions,
  completionsName: state.completionsName,
  units: state.units,
});

const mapDispatchToProps = (dispatch) => ({
  getCompletions: () => {
    return dispatch(getCompletions());
  },

  searchCompletions: (text, field) => {
    return dispatch(searchCompletions(text, field));
  },

  chooseCompletion: (e, setValue) => {
    const text = e.target.textContent;

    const field =
      e.target.parentNode.parentNode.parentNode.parentNode.className;
    const input = e.target.parentNode.parentNode.previousSibling;
    input.value = text;
    setValue(text);
    input.focus();
    e.target.parentNode.parentNode.previousSibling.previousSibling.lastChild.classList.add(
      "add-to-base--hidden"
    );
    dispatch(pushRequestData(text, field));
    return dispatch(chooseCompletion(text, field));
  },

  addNewCompletion: (e) => {
    const text = e.target.parentNode.nextSibling.value;
    const field = e.target.parentNode.parentNode.parentNode.className;
    e.target.classList.add("add-to-base--hidden");
    return dispatch(addNewCompletion(text, field));
  },

  editCompletion: (e, field) => {
    const oldText =
      e.target.parentNode.previousSibling.previousSibling.textContent;
    const newText =
      e.target.parentNode.previousSibling.firstChild.nextSibling.value;
    if (newText === "") {
      e.target.parentNode.previousSibling.firstChild.nextSibling.focus();
    } else {
      e.target.parentNode.parentNode.parentNode.classList.remove("show");
      return dispatch(editCompletion(oldText, newText, field));
    }
  },

  deleteCompletion: (e, field) => {
    const text = e.target.parentNode.previousSibling.textContent;
    e.target.parentNode.parentNode.parentNode.classList.remove("show");
    return dispatch(deleteCompletion(text, field));
  },

  pushRequestData: (e) => {
    const value = e.target.value;
    let field = e.target;
    while (field.className !== "Input") {
      field = field.parentNode;
    }
    field = field.parentNode.className;
    return dispatch(pushRequestData(value, field));
  },

  addNewPosition: (newPosiition) => {
    return dispatch(addNewPosition(newPosiition));
  },

  fetchPositions: (positions) => {
    return dispatch(fetchPositions(positions));
  },

  deletePosition: (id) => {
    return dispatch(deletePosition(id));
  },

  updatePosition: (id, name, value) => {
    return dispatch(updatePosition(id, name, value));
  },

  fetchCompletions: (completions) => {
    return dispatch(fetchCompletions(completions));
  },

  fetchUnits: (units) => {
    return dispatch(fetchUnits(units));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Creating);
