import React from "react";
import CreatingContainer from "../containers/CreatingContainer";

const ViewApplication = ({ id }) => {
  return (
    <div className="View-Application">
      <CreatingContainer id={id} disabled={true} />
    </div>
  );
};

export default ViewApplication;
