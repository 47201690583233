import React from "react";
import { useEffect, useState } from "react";
import { Regions } from "../api/methods";
import Input from "./Input";

const ChangeRegionPopup = ({
  isShowChangeRegionPopup,
  setIsShowChangeRegionPopup,
  region,
  getAllRegions,
}) => {
  const [changeRegion, setChangeRegion] = useState("");
  const [newName, setNewName] = useState("");

  useEffect(() => {
    Regions.getById(region, (res) => {
      setChangeRegion(res.data.name);
    });
  }, [region]);

  return (
    <div
      className={`ChangeRegionPopup ${isShowChangeRegionPopup ? " show" : ""}`}
    >
      <div className="window">
        <div className="rightsPopup__title">Изменение единицы измерения</div>
        <div className="rightsPopup__content">
          Изменить <span>{changeRegion}</span> на
          <Input
            value={newName}
            onChangeHandler={(e) => setNewName(e.target.value)}
          />
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowChangeRegionPopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              Regions.update(
                {
                  Region: {
                    id: Number(region),
                    name: newName,
                  },
                },
                () => {
                  getAllRegions();
                  setIsShowChangeRegionPopup(false);
                }
              );
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeRegionPopup;
