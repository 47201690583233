export const API_URL = "http://start.spbkomplekt.ru/api/";

export const ROOTS = {
  createOrderRead: "createOrderRead",
  createOrderEdit: "createOrderEdit",
  warehouseRead: "warehouseRead",
  warehouseEdit: "warehouseEdit",
  purchaseMaterialRead: "purchaseMaterialRead",
  purchaseMaterialEdit: "purchaseMaterialEdit",
  accountingRead: "accountingRead",
  accountingEdit: "accountingEdit",
  usersRead: "usersRead",
  usersEdit: "usersEdit",
  ourOrganizationRead: "ourOrganizationRead",
  ourOrganizationEdit: "ourOrganizationEdit",
  objectsOfCustomerRead: "objectsOfCustomerRead",
  objectsOfCustomerEdit: "objectsOfCustomerEdit",
  scoresRead: "scoresRead",
  scoresEdit: "scoresEdit",
  goodsRead: "goodsRead",
  goodsEdit: "goodsEdit",
  archivingOfOrderingRead: "archivingOfOrderingRead",
  archivingOfOrderingEdit: "archivingOfOrderingEdit",
  unitsRead: "unitsRead",
  unitsEdit: "unitsEdit",
  measuringEmployeesRead: "measuringEmployeesRead",
  measuringEmployeesEdit: "measuringEmployeesEdit",
};
