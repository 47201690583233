const types = {
  EDIT_INPUT: "EDIT_INPUT",
  ADD_REGION: "ADD_REGION",
  REMOVE_REGION: "REMOVE_REGION",
  CHANGE_CHECKBOX: "CHANGE_CHECKBOX",
  SORT_REQUESTS: "SORT_REQUESTS",
  SEARCH_REQUESTS: "SEARCH_REQUESTS",
  GET_COMPLETIONS: "GET_COMPLETIONS",
  SEARCH_COMPLETIONS: "SEARCH_COMPLETIONS",
  CHOOSE_COMPLETION: "CHOOSE_COMPLETION",
  ADD_NEW_COMPLETION: "ADD_NEW_COMPLETION",
  EDIT_COMPLETION: "EDIT_COMPLETION",
  DELETE_COMPLETION: "DELETE_COMPLETION",
  ADD_NEW_POSITION: "ADD_NEW_POSITION",
  PUSH_REQUEST_DATA: "PUSH_REQUEST_DATA",
  FILTER_ACCOUNTS: "FILTER_ACCOUNTS",
  ADD_ACCOUNTS_FILTER: "ADD_ACCOUNTS_FILTER",
  FETCH_USER: "FETCH_USER",
  EDIT_FIELD: "EDIT_FIELD",
  EDIT_ROOTS: "EDIT_ROOTS",
  UPDATE_USER: "UPDATE_USER",
  FETCH_USERS: "FETCH_USERS",
  FETCH_REQUESTS: "FETCH_REQUESTS",
  FETCH_POSITIONS: "FETCH_POSITIONS",
  DELETE_POSITION: "DELETE_POSITION",
  UPDATE_POSITION: "UPDATE_POSITION",
  ADD_NEW_PURCHASE_POSITION: "ADD_NEW_PURCHASE_POSITION",
  UPDATE_PURCHASE_POSITION: "UPDATE_PURCHASE_POSITION",
  DELETE_PURCHASE_POSITION: "DELETE_PURCHASE_POSITION",
  FETCH_COMPLETIONS: "FETCH_COMPLETIONS",
  FETCH_UNITS: "FETCH_UNITS",
  FETCH_ACCOUNTING: "FETCH_ACCOUNTING",
  FETCH_ACCOUNTS: "FETCH_ACCOUNTS",
  CLEAR_PURCHASE_POSITIONS: "CLEAR_PURCHASE_POSITIONS",
  SHOW_ALERT: "SHOW_ALERT",
  CLOSE_ALERT: "CLOSE_ALERT",
};

export default types;
