import { connect } from "react-redux";
import { searchRequests, fetchRequests, sortRequests } from "../store/actions";
import Request from "../components/Request";

const mapStateToProps = (state) => ({
  requests:
    document.location.pathname === "/archive"
      ? state.requests.filter((request) => request.stateId === 3)
      : state.requests.filter((request) => request.stateId !== 3),
});

const mapDispatchToProps = (dispatch) => ({
  searchRequests: (defaultApplications) => {
    const text = document.getElementsByClassName("search")[0].firstChild
      .firstChild.nextSibling.value;
    const searchBy = document.getElementById("searchBySelect").value;
    return dispatch(searchRequests(text, searchBy, defaultApplications));
  },

  sortRequests: (defaultApplications, sortBy) => {
    return dispatch(sortRequests(sortBy, defaultApplications));
  },

  fetchRequests: (requests) => {
    return dispatch(fetchRequests(requests));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Request);
