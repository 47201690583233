import React from "react";
import { AccWords as A } from "../../languages/languages";

function AccTitle() {
  return (
    <div className="title-acc">
      {A.AccWordsTitle.map((title, index) => (
        <div className={title.className} key={index}>
          {title.title}
        </div>
      ))}
    </div>
  );
}

export default AccTitle;
