import T from "../types";

const initialState = [];

export default function requests(state = initialState, action) {
  switch (action.type) {
    case T.FETCH_REQUESTS:
      return action.requests;
    case T.SEARCH_REQUESTS:
      if (action.searchBy !== "dateOfCreating") {
        return action.requests
          .slice()
          .filter((request) =>
            String(request[action.searchBy])
              .toUpperCase()
              .includes(action.text.toUpperCase())
          );
      } else {
        return action.requests.slice().filter((request) => {
          const date = new Date(request["dateOfCreating"]);
          const stringDate = date.toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          const numberDate = date.toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          console.log(numberDate);
          return (
            stringDate.toUpperCase().includes(action.text.toUpperCase()) ||
            String(numberDate).toUpperCase().includes(action.text.toUpperCase())
          );
        });
      }
    case T.SORT_REQUESTS:
      if (action.sortBy === "descending") {
        return action.requests
          .slice()
          .sort((firstApplications, secondApplications) => {
            return secondApplications.id - firstApplications.id;
          });
      } else {
        if (action.sortBy === "ascending") {
          return action.requests
            .slice()
            .sort((firstApplications, secondApplications) => {
              return firstApplications.id - secondApplications.id;
            });
        } else {
          if (action.sortBy === "last") {
            return action.requests
              .slice()
              .sort((firstApplications, secondApplications) => {
                if (
                  secondApplications.dateOfPayment >
                  firstApplications.dateOfPayment
                ) {
                  return 1;
                } else {
                  if (
                    secondApplications.dateOfPayment ===
                    firstApplications.dateOfPayment
                  ) {
                    return 0;
                  } else {
                    return -1;
                  }
                }
              });
          } else {
            break;
          }
        }
      }
    default:
      return state;
  }
}
