import React from "react";
import { AccWords as A } from "../../languages/languages";
import AccCell from "./AccCell";

function AccRow({ row = {}, onClickHandler }) {
  return (
    <div
      className="Acc-Str"
      style={{ background: row.dateOfPayment ? "#63ff0033" : "", height: 50 }}
      onClick={onClickHandler}
    >
      {A.AccWordsTitle.map((title, index) => {
        let SizeContent = row[title.className].length;
        let Size = 48;
        let contentNew;
        let newClass;
        if (SizeContent > Size) {
          contentNew = row[title.className].slice(0, 43) + "...";
          newClass = title.className + " active";
        } else {
          contentNew = row[title.className];
          newClass = title.className;
        }

        if (title.className === "dateAccount") {
          contentNew = new Date(contentNew).toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        }

        let content = (
          <div className={newClass} key={index}>
            {contentNew}
          </div>
        );
        return (
          <AccCell
            content={content}
            key={index}
            className={title.className}
            AllText={row[title.className]}
          />
        );
      })}
    </div>
  );
}

export default AccRow;
