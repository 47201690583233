import { connect } from "react-redux";
import MainTable from "../components/MainTable/MainTable";
import {
	filterAccounts,
	addAccountsFilter,
	fetchAccounts,
} from "../store/actions";

const mapStateToProps = (state) => ({
	accounts: state.accounts.accounts,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAccounts: (accounts) => dispatch(fetchAccounts(accounts)),
	filterAccounts: (e) => {
		const value = e.target.value;
		const field = e.target.name;
		dispatch(addAccountsFilter(value, field));
		return dispatch(filterAccounts());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(MainTable);
