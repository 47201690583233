import { combineReducers } from "redux";
import user from "./user";
import requests from "./requests";
import completions from "./completions";
import positions from "./positions";
import requestData from "./requestData";
import positionsPurchase from "./positionsPurchase";
import positionsStock from "./positionsStock";
import accounts from "./accounts";
import completionsName from "./completionsName";
import units from "./units";
import accounting from "./accounting";
import alert from "./alert";

export default combineReducers({
  requests,
  completions,
  positions,
  requestData,
  positionsPurchase,
  positionsStock,
  accounts,
  user,
  completionsName,
  units,
  accounting,
  alert,
});
