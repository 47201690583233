import React from "react";

const Popup = ({ content, className }) => {
  return (
    <div className={"Popup " + className}>
      <div className="window">{content}</div>
    </div>
  );
};

export default Popup;
