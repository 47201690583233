import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import LowerTable from "../components/LowerTable/LowerTable";
import DeletePopup from "../components/DeletePopup";
import EditPopup from "../components/EditPopup";
import { creatingWords as L } from "../languages/languages";
import { Applications, Positions } from "../api/methods";
import { connect } from "react-redux";
import {
  searchCompletions,
  chooseCompletion,
  editCompletion,
  deleteCompletion,
  addNewCompletion,
  pushRequestData,
  addNewPurchasePosition,
  updatePurchasePosition,
  deletePurchasePosition,
  fetchCompletions,
  fetchUnits,
  getCompletions,
  clearPurchasePosition,
} from "../store/actions";
import { useHistory } from "react-router-dom";
import { Units } from "../api/methods";

function RequestCreating({
  completions,
  getCompletions,
  searchCompletions,
  chooseCompletion,
  addNewCompletion,
  editCompletion,
  deleteCompletion,
  positionsPurchase,
  addNewPurchasePosition,
  updatePurchasePosition,
  deletePurchasePosition,
  fetchCompletions,
  completionsName,
  fetchUnits,
  units,
  clearPurchasePosition,
}) {
  const [object, setObject] = useState("");
  const [organization, setOrganization] = useState("");
  const [address, setAddress] = useState("");
  const [requester, setRequester] = useState("");
  const [calculationer, setCalculationer] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [comment, setComment] = useState("");
  const [billingDate, setBillingDate] = useState({ input: "", span: "" });
  const [closingDate, setClosingDate] = useState({ input: "", span: "" });
  const [disabled, setDisabled] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getCompletions();
    Units.getAll((res) => {
      fetchUnits(res.data);
      console.log(res.data);
    });
    return () => {
      clearPurchasePosition();
    };
  }, []);

  const dateTranslate = (date, type) => {
    let stringDate = new Date(date);
    stringDate = stringDate.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: type === "input" ? "numeric" : "long",
      day: "numeric",
    });
    if (type === "input") {
      stringDate = stringDate.split(".").reverse().join("-");
    }
    if (stringDate === "Invalid Date") {
      stringDate = "";
    }
    return stringDate;
  };

  const onChangeHandler = (e) => {
    const { value, parentNode, previousSibling } = e.target;

    const text = value;
    const field = parentNode.parentNode.className;
    const fieldValue = completions[field];
    if (
      !fieldValue?.find(
        (completion) => completion.name.toUpperCase() === text.toUpperCase()
      ) &&
      value
    ) {
      previousSibling.lastChild.classList.remove("add-to-base--hidden");
    } else {
      previousSibling.lastChild.classList.add("add-to-base--hidden");
    }
    searchCompletions(text, field);
  };

  const showEditPopup = (e) => {
    const popup =
      e.target.parentNode.parentNode.parentNode.parentNode.nextSibling;
    const defaultValue = e.target.parentNode.previousSibling.textContent;
    popup.firstChild.firstChild.nextSibling.textContent = defaultValue;
    popup.firstChild.firstChild.nextSibling.nextSibling.firstChild.nextSibling.value = defaultValue;
    popup.classList.add("show");
  };

  const showDeletePopup = (e) => {
    const popup =
      e.target.parentNode.parentNode.parentNode.parentNode.nextSibling
        .nextSibling;
    const text = e.target.parentNode.previousSibling.textContent;
    let field =
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode.className;
    switch (field) {
      case "object":
        field = "Объект";
        break;
      case "organization":
        field = "Организация";
        break;
      case "address":
        field = "Адрес";
        break;
      case "requester":
        field = "Составитель";
        break;
      case "calculationer":
        field = "Произвел расчет";
        break;
      default:
        break;
    }
    popup.firstChild.firstChild.nextSibling.textContent = field;
    popup.firstChild.firstChild.nextSibling.nextSibling.textContent = text;
    popup.classList.add("show");
  };

  return (
    <div className="Request-Creating container">
      <div className="request">
        <div className="object">
          <Textarea
            value={object}
            title={L.objectTitle + " *"}
            required={true}
            extra={
              <div
                className="add-to-base add-to-base--hidden"
                onClick={(e) => {
                  addNewCompletion(e);
                  getCompletions();
                }}
              >
                {L.addToBase}
              </div>
            }
            completions={completions.object}
            chooseCompletion={(e) => chooseCompletion(e, setObject)}
            showEditPopup={showEditPopup}
            showDeletePopup={showDeletePopup}
            onChangeHandler={(e) => {
              onChangeHandler(e);
              setObject(e.target.value);
            }}
          />
          <EditPopup
            field="object"
            editCompletion={editCompletion}
            getCompletions={getCompletions}
          />
          <DeletePopup
            field="object"
            deleteCompletion={deleteCompletion}
            getCompletions={getCompletions}
          />
        </div>
        <div className="organization">
          <Textarea
            value={organization}
            title={L.organizationTitle + " *"}
            required={true}
            extra={
              <div
                className="add-to-base add-to-base--hidden"
                onClick={(e) => {
                  addNewCompletion(e);
                  getCompletions();
                }}
              >
                {L.addToBase}
              </div>
            }
            completions={completions.organization}
            chooseCompletion={(e) => chooseCompletion(e, setOrganization)}
            showEditPopup={showEditPopup}
            showDeletePopup={showDeletePopup}
            onChangeHandler={(e) => {
              onChangeHandler(e);
              setOrganization(e.target.value);
            }}
          />
          <EditPopup
            field="organization"
            editCompletion={editCompletion}
            getCompletions={getCompletions}
          />
          <DeletePopup
            field="organization"
            deleteCompletion={deleteCompletion}
            getCompletions={getCompletions}
          />
        </div>
        <div className="address">
          <Textarea
            value={address}
            title={L.addressTitle + " *"}
            required={true}
            // extra={
            //   <div
            //     className="add-to-base add-to-base--hidden"
            //     onClick={(e) => {
            // 			addNewCompletion(e);
            // 			getCompletions();
            // 		}}
            //   >
            //     {L.addToBase}
            //   </div>
            // }
            // completions={completions.address}
            // chooseCompletion={(e) => chooseCompletion(e, setAddress)}
            // showEditPopup={showEditPopup}
            // showDeletePopup={showDeletePopup}
            onChangeHandler={(e) => {
              // onChangeHandler(e);
              setAddress(e.target.value);
            }}
          />
          {/* <EditPopup
						field="address"
						editCompletion={editCompletion}
						getCompletions={getCompletions}
					/>
					<DeletePopup
						field="address"
						deleteCompletion={deleteCompletion}
						getCompletions={getCompletions}
					/> */}
        </div>
        <div className="billing-date">
          <Input
            title={L.billingDateTitle}
            type="date"
            required={true}
            onChangeHandler={(e) => {
              setBillingDate({
                input: e.target.value,
                span: dateTranslate(e.target.value, "span"),
              });
            }}
            value={billingDate.input}
            spanText={billingDate.span}
          />
        </div>
        <div className="closing-date">
          <Input
            title={L.closingDateTitle}
            type="date"
            required={true}
            onChangeHandler={(e) => {
              setClosingDate({
                input: e.target.value,
                span: dateTranslate(e.target.value, "span"),
              });
            }}
            value={closingDate.input}
            spanText={closingDate.span}
          />
        </div>
        <div className="requester">
          <Textarea
            value={requester}
            title={L.requesterTitle + " *"}
            placeholder="Иванов Иван Иванович"
            required={true}
            extra={
              <div
                className="add-to-base add-to-base--hidden"
                onClick={(e) => {
                  addNewCompletion(e);
                  getCompletions();
                }}
              >
                {L.addToBase}
              </div>
            }
            completions={completions.requester}
            chooseCompletion={(e) => chooseCompletion(e, setRequester)}
            showEditPopup={showEditPopup}
            showDeletePopup={showDeletePopup}
            onChangeHandler={(e) => {
              onChangeHandler(e);
              setRequester(e.target.value);
            }}
          />
          <EditPopup
            field="requester"
            editCompletion={editCompletion}
            getCompletions={getCompletions}
          />
          <DeletePopup
            field="requester"
            deleteCompletion={deleteCompletion}
            getCompletions={getCompletions}
          />
        </div>
        <div className="calculationer">
          <Textarea
            value={calculationer}
            title={L.сalculationerTitle}
            placeholder="Иванов И.И."
            required={true}
            extra={
              <div
                className="add-to-base add-to-base--hidden"
                onClick={(e) => {
                  addNewCompletion(e);
                  getCompletions();
                }}
              >
                {L.addToBase}
              </div>
            }
            completions={completions.calculationer}
            chooseCompletion={(e) => chooseCompletion(e, setCalculationer)}
            showEditPopup={showEditPopup}
            showDeletePopup={showDeletePopup}
            onChangeHandler={(e) => {
              onChangeHandler(e);
              return setCalculationer(e.target.value);
            }}
          />
          <EditPopup
            field="calculationer"
            editCompletion={editCompletion}
            getCompletions={getCompletions}
          />
          <DeletePopup
            field="calculationer"
            deleteCompletion={deleteCompletion}
            getCompletions={getCompletions}
          />
        </div>
        <div className="contract-number">
          <Input
            type="input"
            value={contractNumber}
            title={L.contractNumberTitle + " *"}
            required={true}
            onChangeHandler={(e) => setContractNumber(e.target.value)}
          />
        </div>
        <div></div>
        <div className="note">
          <div className="Input">
            <div className="top">
              <div className="title">{L.noteTitle}</div>
            </div>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="create-button">
        <button
          onClick={() => {
            Applications.create(
              {
                dateOfCreating: new Date(),
                object: object,
                addressOfOrganisation: address,
                organisation: organization,
                nameCreator: requester,
                contractNumber: `${contractNumber}`,
                dateAccount: closingDate.input,
                dateOfPayment: billingDate.input,
                nameUserCompleteCalculation: calculationer,
                comment: comment,
                dateOfCreating: new Date(),
              },
              (res) => {
                for (let i = 0; i < positionsPurchase.length; i++) {
                  delete positionsPurchase[i].name;
                  delete positionsPurchase[i].unitId;
                  console.log(positionsPurchase[i]);
                  Positions.create({
                    Position: {
                      ...positionsPurchase[i],
                      applicationId: res.data.id,
                    },
                  });
                }
                clearPurchasePosition(); //TODO: ПРОВЕРИТЬ
                if (positionsPurchase.length > 0) {
                  Applications.update(
                    {
                      stateId: 2,
                      object: object,
                      addressOfOrganisation: address,
                      organisation: organization,
                      nameCreator: requester,
                      contractNumber: `${contractNumber}`,
                      dateAccount: closingDate.input,
                      dateOfPayment: billingDate.input,
                      nameUserCompleteCalculation: calculationer,
                      comment: comment,
                      dateOfCreating: new Date(),
                    },
                    () => {},
                    res.data.id
                  );
                }
                history.push("/filling/" + res.data.id);
              }
            );
          }}
          disabled={
            object === "" ||
            organization === "" ||
            address === "" ||
            requester === "" ||
            contractNumber === ""
          }
          className="Button"
        >
          {object === "" ||
          organization === "" ||
          address === "" ||
          requester === "" ||
          contractNumber === ""
            ? L.disabledCreate
            : L.create}
        </button>
      </div>
      <LowerTable
        dateChangeHandler={dateTranslate}
        addNewPosition={addNewPurchasePosition}
        button={true}
        columnNames={L.requestsTableTitles}
        positions={positionsPurchase}
        id={false}
        dateTranslate={dateTranslate}
        updatePosition={updatePurchasePosition}
        deletePosition={deletePurchasePosition}
        fetchCompletions={fetchCompletions}
        completionsName={completionsName}
        units={units}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  positionsPurchase: state.positionsPurchase,
  completions: state.completions,
  completionsName: state.completionsName,
  units: state.units,
});

const mapDispatchToProps = (dispatch) => ({
  getCompletions: () => {
    return dispatch(getCompletions());
  },

  searchCompletions: (text, field) => {
    return dispatch(searchCompletions(text, field));
  },

  chooseCompletion: (e, setValue) => {
    const text = e.target.textContent;
    const field =
      e.target.parentNode.parentNode.parentNode.parentNode.className;
    const input = e.target.parentNode.parentNode.previousSibling;
    input.value = text;
    setValue(text);
    e.target.parentNode.parentNode.previousSibling.previousSibling.lastChild.classList.add(
      "add-to-base--hidden"
    );
    dispatch(pushRequestData(text, field));
    return dispatch(chooseCompletion(text, field));
  },

  addNewCompletion: (e) => {
    const text = e.target.parentNode.nextSibling.value;
    const field = e.target.parentNode.parentNode.parentNode.className;
    e.target.classList.add("add-to-base--hidden");
    return dispatch(addNewCompletion(text, field));
  },

  editCompletion: (e, field) => {
    const oldText =
      e.target.parentNode.previousSibling.previousSibling.textContent;
    const newText =
      e.target.parentNode.previousSibling.firstChild.nextSibling.value;
    if (newText === "") {
      e.target.parentNode.previousSibling.firstChild.nextSibling.focus();
    } else {
      e.target.parentNode.parentNode.parentNode.classList.remove("show");
      return dispatch(editCompletion(oldText, newText, field));
    }
  },

  deleteCompletion: (e, field) => {
    const text = e.target.parentNode.previousSibling.textContent;
    e.target.parentNode.parentNode.parentNode.classList.remove("show");
    return dispatch(deleteCompletion(text, field));
  },

  pushRequestData: (e) => {
    const value = e.target.value;
    let field = e.target;
    while (field.className !== "Input") {
      field = field.parentNode;
    }
    field = field.parentNode.className;
    return dispatch(pushRequestData(value, field));
  },

  addNewPurchasePosition: (newPosiition) => {
    return dispatch(addNewPurchasePosition(newPosiition));
  },

  updatePurchasePosition: (id, name, value) => {
    return dispatch(updatePurchasePosition(id, name, value));
  },

  deletePurchasePosition: (id) => {
    return dispatch(deletePurchasePosition(id));
  },

  clearPurchasePosition: () => {
    return dispatch(clearPurchasePosition());
  },

  fetchCompletions: (completions) => {
    return dispatch(fetchCompletions(completions));
  },

  fetchUnits: (units) => {
    return dispatch(fetchUnits(units));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCreating);
