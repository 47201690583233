import React from "react";

const Select = ({
  title = "",
  options = [],
  className = "",
  name = "",
  onClickHandler,
  onBlurHandler,
  onChangeHandler,
  classNameSelect = "",
  disabled = false,
  value = "",
  id = "",
  dataId = "",
}) => {
  return (
    <div className={"Select" + (className === "" ? "" : " " + className)}>
      <select
        name={name}
        defaultValue="default"
        onClick={onClickHandler}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        disabled={disabled}
        className={classNameSelect}
        value={value}
        id={id}
        data-id={dataId}
      >
        <option key="0" disabled value="default">
          {title}
        </option>

        {options.map((option, index) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </select>
      <div className="arrow"></div>
    </div>
  );
};
export default Select;
