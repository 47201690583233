import React from "react";
import AutoComplete from "./AutoComplete";

const Input = ({
  title = "",
  className = "",
  type = "text",
  name = "",
  placeholder = null,
  required = false,
  disabled = false,
  extra = "",
  completions = [],
  chooseCompletion = () => {
    return;
  },
  showEditPopup,
  showDeletePopup,
  onChangeHandler,
  onBlurHandler,
  classNameInput = "",
  value = "",
  spanText = "",
  dataId = "",
  onFocusHandler = "",
  inputRef = null,
  children,
  min = "",
  max = "",
}) => {
  if (type === "date") {
    return (
      <div className={"Input" + (className === "" ? "" : " " + className)}>
        <div className="top">
          <div className="title">{title}</div>
          {extra}
        </div>
        <label className="date">
          <input
            style={{ paddingLeft: disabled ? 0 : null }}
            type={type}
            name={name}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            className={classNameInput}
            value={value === "Invalid Date" ? "" : value}
            data-id={dataId}
            ref={inputRef}
          />
          <span>{spanText}</span>
        </label>
      </div>
    );
  } else {
    return (
      <div className={"Input" + (className === "" ? "" : " " + className)}>
        <div className="top">
          <div className="title">{title}</div>
          {extra}
        </div>
        {type === "date" ? <label className="date"></label> : null}
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          value={value}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          className={classNameInput}
          data-id={dataId}
          autoComplete="off"
          onFocus={onFocusHandler}
          ref={inputRef}
          min={min}
          max={max}
        />
        {children}
        <AutoComplete
          completions={completions}
          chooseCompletion={chooseCompletion}
          showEditPopup={showEditPopup}
          showDeletePopup={showDeletePopup}
          onChangeHandler={onChangeHandler}
        />
      </div>
    );
  }
};

export default Input;
