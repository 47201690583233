import React from "react";
import { usersTableWords as L } from "../../languages/languages";
import UsersCell from "./UsersCell";
import { useHistory } from "react-router-dom";

function UsersRow({ row = {} }) {
  const history = useHistory();
  return (
    <div className="Main-Str" onClick={() => history.push("/users/" + row.id)}>
      {L.usersTableTitles.map((title, index) => {
        let SizeContent = row[title.className].length;
        let Size = 48;
        let contentNew;
        let newClass;
        if (SizeContent > Size) {
          contentNew = row[title.className].slice(0, 43) + "...";
          newClass = title.className + " active";
        } else {
          contentNew = row[title.className];
          newClass = title.className;
        }
        let content = (
          <div className={newClass} key={index}>
            {contentNew}
          </div>
        );
        return (
          <UsersCell
            content={content}
            key={index}
            className={title.className}
            AllText={row[title.className]}
          />
        );
      })}
    </div>
  );
}

export default UsersRow;
