const initialState = [
  {
    positionStock: "Новая позиция",
    numberStock: 1400,
    available: 800,
    buy: 600,
    return: 100,
    released: 800,
    unitStock: "м2",
    state: 5,
    accountNumber: "137202037",
  },
  {
    positionStock: "Еще одна позиция",
    numberStock: 23,
    available: 13,
    buy: 0,
    return: 100,
    released: 800,
    unitStock: "м",
    state: 3,
    accountNumber: "001202037",
  },
  {
    positionStock: "Новая позиция",
    numberStock: 1400,
    available: 800,
    buy: 600,
    return: 100,
    released: 800,
    unitStock: "м2",
    state: 5,
    accountNumber: "137202037",
  },
  {
    positionStock: "Еще одна позиция",
    numberStock: 23,
    available: 13,
    buy: 10,
    return: 100,
    released: 800,
    unitStock: "м",
    state: 3,
    accountNumber: "001202037",
  },
  {
    positionStock: "Новая позиция",
    numberStock: 1400,
    available: 800,
    buy: 600,
    return: 100,
    released: 800,
    unitStock: "м2",
    state: 5,
    accountNumber: "137202037",
  },
  {
    positionStock: "Еще одна позиция",
    numberStock: 23,
    available: 13,
    buy: 10,
    return: 100,
    released: 800,
    unitStock: "м",
    state: 3,
    accountNumber: "001202037",
  },
  {
    positionStock: "Новая позиция",
    numberStock: 1400,
    available: 800,
    buy: 600,
    return: 100,
    released: 800,
    unitStock: "м2",
    state: 5,
    accountNumber: "137202037",
  },
  {
    positionStock: "Еще одна позиция",
    numberStock: 23,
    available: 13,
    buy: 10,
    return: 100,
    released: 800,
    unitStock: "м",
    state: 3,
    accountNumber: "001202037",
  },
];

export default function positionsStock(state = initialState, action) {
  return state;
}
