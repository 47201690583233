import React from "react";
import { useEffect, useState } from "react";
import { Regions, Units } from "../api/methods";
import Input from "./Input";

const DeleteRegionPopup = ({
  isShowDeleteRegionPopup,
  setIsShowDeleteRegionPopup,
  region,
  getAllRegions,
}) => {
  const [changeRegion, setChangeRegion] = useState("");

  useEffect(() => {
    Regions.getById(region, (res) => {
      setChangeRegion(res.data.name);
    });
  }, [region]);

  return (
    <div
      className={`DeleteRegionPopup ${isShowDeleteRegionPopup ? " show" : ""}`}
    >
      <div className="window">
        <div className="rightsPopup__title">Удаление региона</div>
        <div className="rightsPopup__content">
          Вы действительно хотите удалить <span>{changeRegion}</span> ?
        </div>
        <div className="rightsPopup__buttons">
          <div
            className="rightsPopup__buttons-false"
            onClick={() => setIsShowDeleteRegionPopup(false)}
          >
            Отмена
          </div>
          <div
            className="rightsPopup__buttons-true"
            onClick={() => {
              Regions.delete(region, () => {
                getAllRegions();
                setIsShowDeleteRegionPopup(false);
              });
            }}
          >
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteRegionPopup;
