import T from "../types";

let initialState = [];

export default function units(state = initialState, action) {
  switch (action.type) {
    case T.FETCH_UNITS:
      return action.units;
    default:
      return state;
  }
}
