import React from "react";
import { useEffect, useState } from "react";
import { Units } from "../api/methods";
import Input from "./Input";
import Select from "./Select";
import ChangeUnitPopup from "./ChangeUnitPopup";
import DeleteUnitPopup from "./DeleteUnitPopup";
import { useDispatch } from "react-redux";
import { showAlert } from "../store/actions";

const UnitEditPopup = ({
  isShowUnitEditPopup,
  setIsShowUnitEditPopup,
  loadAllData,
}) => {
  const getAllUnits = () => {
    Units.getAll((res) => {
      setAllUnits(res.data);
      setUnit(res.data[0].id);
    });
  };

  useEffect(() => {
    getAllUnits();
  }, []);

  const [allUnits, setAllUnits] = useState([]);
  const [newUnitName, setNewUnitName] = useState("");
  const [unit, setUnit] = useState(1);
  const [isShowChangeUnitPopup, setIsShowChangeUnitPopup] = useState(false);
  const [isShowDeleteUnitPopup, setIsShowDeleteUnitPopup] = useState(false);

  const dispatch = useDispatch();
  const createUnit = () => {
    const success = () => {
      getAllUnits();
      setNewUnitName("");
      dispatch(showAlert("Новая единица измерения успешно добавлена"));
    };
    if (newUnitName !== "")
      Units.create(
        {
          Unit: {
            name: newUnitName,
          },
        },
        success
      );
  };
  return (
    <>
      <div className={`UnitEditPopup ${isShowUnitEditPopup ? "show" : ""}`}>
        <div className="window">
          <div className="rightsPopup__title">
            Единицы измерения
            <div
              className="rightsPopup__buttons-true"
              onClick={() => {
                setIsShowUnitEditPopup(false);
              }}
            >
              Закрыть
            </div>
          </div>
          <div className="rightsPopup__content">
            <div className="unitEditAdd">
              <Input
                classNameInput="unitEditAdd__input"
                value={newUnitName}
                onChangeHandler={(e) => setNewUnitName(e.target.value)}
              />
              <div
                className="unitEditAdd__button"
                onClick={() => {
                  createUnit();
                  loadAllData();
                }}
              >
                +
              </div>
            </div>
            <div className="unitEditChange">
              <Select
                options={allUnits}
                value={unit}
                onChangeHandler={(e) => setUnit(e.target.value)}
              />
              <div
                className="unitEditChange__button-change"
                onClick={() => {
                  setIsShowChangeUnitPopup(true);
                }}
              >
                Изменить
              </div>
              <div
                className="unitEditChange__button-delete"
                onClick={() => {
                  setIsShowDeleteUnitPopup(true);
                }}
              >
                Удалить
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangeUnitPopup
        isShowChangeUnitPopup={isShowChangeUnitPopup}
        setIsShowChangeUnitPopup={setIsShowChangeUnitPopup}
        unit={unit}
        getAllUnits={getAllUnits}
        loadAllData={loadAllData}
      />
      <DeleteUnitPopup
        isShowDeleteUnitPopup={isShowDeleteUnitPopup}
        setIsShowDeleteUnitPopup={setIsShowDeleteUnitPopup}
        unit={unit}
        getAllUnits={getAllUnits}
        loadAllData={loadAllData}
      />
    </>
  );
};

export default UnitEditPopup;
