import React from "react";
import { creatingWords as L } from "../languages/languages";

const AutoComplete = ({
  completions = [],
  chooseCompletion,
  showEditPopup,
  showDeletePopup,
  onChangeHandler = () => {
    return;
  },
}) => (
  <div className="autocomplete">
    {completions.map((completion, index) => {
      return (
        <div className="completion" key={index}>
          <div
            className="text"
            onClick={(e) => {
              onChangeHandler(e);
              chooseCompletion(e);
            }}
          >
            {completion.name}
          </div>
          <div className="options">
            <div
              className="edit"
              onClick={showEditPopup}
              data-id={completion.id}
            >
              {L.completionOptionEdit}
            </div>
            <div
              className="delete"
              onClick={showDeletePopup}
              data-id={completion.id}
            >
              {L.completionOptionDelete}
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default AutoComplete;
