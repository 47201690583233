import T from "../types";

const initialState = {
  "date-of-creating": "",
  "object": "",
  "organization": "",
  "address": "",
  "billing-date": "",
  "closing-date": "",
  "requester": "",
  "calculationer": "",
  "contract-number": "",
  "note": "",
};

export default function requestData(state = initialState, action) {
  switch (action.type) {
    // case T.FETCH_REQUEST_DATA:
    //   return state;
    case T.PUSH_REQUEST_DATA:
      return Object.assign({}, state, {[action.field]: action.value});
    default:
      return state;
  }
}
