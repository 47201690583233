import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import UsersTable from "../UsersTable/UsersTable";
import { Users } from "../../api/methods";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    Users.getUsers((res) => {
      setUsers(res.data);
    });
  }, []);
  const history = useHistory();

  return (
    <div className="Users-List container">
      <Button
        title="+ Добавить пользователя"
        onClickHandler={() => {
          history.push("/new-user");
        }}
      />
      <UsersTable users={users} />
    </div>
  );
};

export default UsersList;
