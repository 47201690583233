import React from "react";

const Button = ({
  title = "",
  onClickHandler = () => console.log("Нет хендлера для кнопки"),
}) => (
  <div className="Button" onClick={onClickHandler}>
    {title}
  </div>
);

export default Button;
