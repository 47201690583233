import React from "react";
import MainRow from "./MainRow";

function MainStr({ accounts, setAccountId, setIsActiveStoragePopup, getAllAccounts }) {
  return (
    <div className="Main-alter">
      {accounts.map((item) => {
        return (
          <MainRow
            row={item}
            key={item.id}
            setAccountId={setAccountId}
            setIsActiveStoragePopup={setIsActiveStoragePopup}
            getAllAccounts={getAllAccounts}
          />
        );
      })}
    </div>
  );
}

export default MainStr;
