import React, { useEffect } from "react";
import RequestsTable from "../components/RequestsTable";
import { fillingWords as L } from "../languages/languages";
import Input from "./Input";
import Select from "./Select";
import { Applications } from "../api/methods";
import { useState } from "react";

function Request({
  requests,
  searchRequests,
  fetchRequests,
  children,
  hoverElement,
  path = "",
  sortRequests,
}) {
  const [defaultRequests, setDefaultRequests] = useState();
  const [searchValue, setSearchValue] = useState();
  const [searchBySelect, setSearchBySelect] = useState();
  const [sortingBySelect, setSortingBySelect] = useState();

  useEffect(() => {
    Applications.getAll((res) => {
      fetchRequests(res.data.reverse());
      sortRequests(res.data, "descending");
      setDefaultRequests(res.data);
    });
  }, [fetchRequests]);

  return (
    <div className="Request container">
      <div className="filter">
        <div className="sort-select">
          <div className="Input">
            <div className="top">
              <div className="title">{L.sortTitle}</div>
            </div>
            <Select
              defaultValue="last"
              options={L.sortSelectOptions}
              title="Выберите сортировку"
              onClickHandler={(e) => {
                e.target.classList.toggle("arrow-active");
              }}
              onBlurHandler={(e) => {
                e.target.classList.remove("arrow-active");
              }}
              value={sortingBySelect}
              onChangeHandler={(e) => {
                setSortingBySelect(e.target.value);
                sortRequests(requests, e.target.value);
              }}
            />
          </div>
        </div>
        <div className="search-select">
          <div className="Input">
            <div className="top">
              <div className="title">{L.searchTitle}</div>
            </div>
            <Select
              id="searchBySelect"
              defaultValue="title"
              onClickHandler={(e) => {
                e.target.classList.toggle("arrow-active");
                searchRequests(defaultRequests);
              }}
              onBlurHandler={(e) => {
                e.target.classList.remove("arrow-active");
              }}
              options={L.searchSelectOptions}
              title="Выберите"
              value={searchBySelect}
              onChangeHandler={(e) => {
                setSearchBySelect(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="search">
          <Input
            placeholder={L.searchPlaceholder}
            onChangeHandler={(e) => {
              searchRequests(defaultRequests);
              setSearchValue(e.target.value);
            }}
            value={searchValue}
          />
        </div>
        {children}
      </div>
      <RequestsTable
        requests={requests}
        hoverElement={hoverElement}
        path={path}
      />
    </div>
  );
}

export default Request;
