import T from "../types";

const initialState = [];

export default function accounting(state = initialState, action) {
  switch (action.type) {
    case T.FETCH_ACCOUNTING:
      return action.accounting;
    default:
      return state;
  }
}
