import React from "react";
import AccString from "./AccString";
import AccTitle from "./AccTitle";
import { fetchAccounting } from "../../store/actions";
import { connect } from "react-redux";

function AccTab({ accounting, fetchAccounting }) {
  return (
    <div className="Acc-tab">
      <AccTitle />
      <AccString accounting={accounting} fetchAccounting={fetchAccounting} />
    </div>
  );
}

const mapStateToProps = (state) => ({ accounting: state.accounting });

const mapDispatchToProps = (dispatch) => ({
  fetchAccounting: (accounts) => {
    return dispatch(fetchAccounting(accounts));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccTab);
