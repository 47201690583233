import React from "react";
import Button from "../Button";
import Input from "../Input";
import { useState } from "react";
import { Accounts } from "../../api/methods";

function AdditInfo({ account, setUpdateApplication }) {
  const dateTranslate = (date, type) => {
    if (date === null) return "";
    let stringDate = new Date(date);
    stringDate = stringDate.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: type === "input" ? "numeric" : "long",
      day: "numeric",
    });
    if (type === "input") {
      stringDate = stringDate.split(".").reverse().join("-");
    }
    return stringDate;
  };
  const [dateOfPayment, setDate] = useState(
    dateTranslate(account.dateOfPayment, "input") || ""
  );

  const sendDateOfPaymentSuccess = () => {
    setUpdateApplication((prev) => setUpdateApplication(!prev));
  };

  const sendDateOfPayment = () => {
    Accounts.update(
      { ...account, dateOfPayment },
      account.id,
      sendDateOfPaymentSuccess
    );
  };
  return (
    <div id="add" className="Addit-Info active">
      <div className="Addit-Text">Дата оплаты</div>
      <Input
        type="date"
        classNameInput="Addit-Date"
        value={dateOfPayment}
        onChangeHandler={(e) => setDate(e.target.value)}
      />
      <Button
        title="Сохранить"
        onClickHandler={() => {
          sendDateOfPayment();
        }}
      />
    </div>
  );
}

export default AdditInfo;
