import React, { useEffect } from "react";
import Header from "./Header";
import { Units } from "../api/methods";
import { useHistory } from "react-router-dom";

const Screen = ({
  subtitle = "",
  user = "",
  backButton = "",
  backButtonPath = "/",
  children,
}) => {
  const history = useHistory();

  const isAuthorized = () => {
    Units.getAll();
  };

  useEffect(isAuthorized, []);

  return (
    <>
      <Header
        subtitle={subtitle}
        user={user}
        backButton={backButton}
        backButtonPath={backButtonPath}
      />
      {children}
    </>
  );
};

export default Screen;
