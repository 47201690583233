import React from "react";
import Popup from "./Popup";
import Title from "./Title";
import Input from "./Input";
import { creatingWords as L } from "../languages/languages";
import { useState } from "react";

const EditPopup = ({ field, editCompletion, getCompletions }) => {
	const [value, setValue] = useState("");

	const hide = (e) => {
		e.target.parentNode.parentNode.parentNode.classList.remove("show");
	};

	const content = (
		<>
			<Title title="Редактирование" />
			<div className="oldValue"></div>
			<Input
				required={true}
				value={value}
				onChangeHandler={(e) => setValue(e.target.value)}
			/>
			<div className="buttons">
				<div
					className="save"
					onClick={(e) => {
						editCompletion(e, field);
						getCompletions();
					}}
				>
					{L.editPopupSave}
				</div>
				<div className="cancel" onClick={hide}>
					{L.editPopupCancel}
				</div>
			</div>
		</>
	);

	return <Popup content={content} className="edit" />;
};

export default EditPopup;
