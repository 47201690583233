import React from "react";
import { Applications } from "../api/methods";
import { creatingWords } from "../languages/languages";

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function toBinary(string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

function fromBinary(binary) {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const DownloadReport = ({ id = null }) => {
  const download = () => {
    const date = new Date();
    const options = {
      year: "2-digit",
      month: "long",
      day: "2-digit",
    };
    const dateTimeFormat = new Intl.DateTimeFormat("ru-RU", options);
    const [
      { value: day },
      ,
      { value: month },
      ,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);

    Applications.getApplicationReport(id, (res) => {
      var blob = new Blob([res.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = href;
      link.setAttribute(
        "download",
        `Заявка №${id} — Отчет за ${day} ${month} ${year}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };
  return (
    <div className="document" onClick={download}>
      <div className="excel"></div>
      {creatingWords.documentTitle}
    </div>
  );
};
export default DownloadReport;
