import React, { useState, useEffect } from "react";
import MainStr from "./MainStr";
import MainTitle from "./MainTitle";
import MainTableFilters from "./MainTableFilters";
import { Accounts } from "../../api/methods";
import StoragePopup from "../StoragePopup";

function MainTable({ accounts, fetchAccounts, filterAccounts }) {
  useEffect(() => {
    getAllAccounts();
  }, []);

  const getAllAccounts = () => {
    Accounts.getAll((res) => fetchAccounts(res.data));
  };

  const [isActiveStoragePopup, setIsActiveStoragePopup] = useState(false);
  const [accountId, setAccountId] = useState(null);

  return (
    <div className="Main-table container">
      <MainTableFilters filterAccounts={filterAccounts} />
      <MainTitle />
      <MainStr
        accounts={accounts}
        setAccountId={setAccountId}
        setIsActiveStoragePopup={setIsActiveStoragePopup}
        getAllAccounts={getAllAccounts}
      />
      <StoragePopup
        id={accountId}
        active={isActiveStoragePopup}
        onClickExit={setIsActiveStoragePopup}
      />
    </div>
  );
}

export default MainTable;
