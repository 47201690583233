import React, { useState, useEffect } from "react";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import LowerTable from "../components/LowerTable/LowerTable";
import DeletePopup from "../components/DeletePopup";
import EditPopup from "../components/EditPopup";
import { creatingWords as L } from "../languages/languages";
import { Applications, Materials, Units } from "../api/methods";
import RightsPopup from "./RightsPopup";
import { useHistory } from "react-router-dom";
import DownloadReport from "./DownloadReport";

function Creating({
  completions,
  getCompletions,
  searchCompletions,
  chooseCompletion,
  addNewCompletion,
  editCompletion,
  deleteCompletion,
  fetchPositions,
  addNewPosition,
  id,
  positions,
  deletePosition,
  updatePosition,
  completionsName,
  fetchCompletions,
  units,
  fetchUnits,
  disabled = false,
}) {
  const [createStatus, setCreateStatus] = useState(false);
  const [requestState, setRequestState] = useState({ name: "", id });
  const [object, setObject] = useState("");
  const [organization, setOrganization] = useState("");
  const [address, setAddress] = useState("");
  const [requester, setRequester] = useState("");
  const [calculationer, setCalculationer] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [comment, setComment] = useState("");
  const [billingDate, setBillingDate] = useState({ input: "", span: "" });
  const [closingDate, setClosingDate] = useState({ input: "", span: "" });
  const [dateOfCreating, setDateOfCreating] = useState({ input: "", span: "" });

  const applicationUpdate = (stateId = null) => {
    Applications.update(
      {
        stateId: createStatus ? 8 : requestState.id,
        condition: requestState,
        dateOfCreating: dateOfCreating.input,
        object: object,
        addressOfOrganisation: address,
        organisation: organization,
        nameCreator: requester,
        contractNumber: `${contractNumber}`,
        dateAccount: closingDate.input,
        dateOfPayment: billingDate.input,
        nameUserCompleteCalculation:
          calculationer === null ? "" : calculationer,
        comment: comment ? comment : "",
      },
      () => {
        loadAllData();
      },
      id
    );
  };

  const newUpdatePosition = (posId, name, value) => {
    updatePosition(posId, name, value);

    Applications.update(
      {
        stateId: 8,
        condition: requestState,
        dateOfCreating: dateOfCreating.input,
        object: object,
        addressOfOrganisation: address,
        organisation: organization,
        nameCreator: requester,
        contractNumber: `${contractNumber}`,
        dateAccount: closingDate.input,
        dateOfPayment: billingDate.input,
        nameUserCompleteCalculation:
          calculationer === null ? "" : calculationer,
        comment: comment ? comment : "",
      },
      () => {
        loadAllData();
      },
      id
    );
    loadAllData();
  };

  const applicationUpdatePositions = (state) => {
    if (state !== 3) {
      Applications.update(
        {
          stateId: requestState.id === 3 ? 3 : 8,
          condition: requestState,
          dateOfCreating: dateOfCreating.input,
          object: object,
          addressOfOrganisation: address,
          organisation: organization,
          nameCreator: requester,
          contractNumber: `${contractNumber}`,
          dateAccount: closingDate.input,
          dateOfPayment: billingDate.input,
          nameUserCompleteCalculation:
            calculationer === null ? "" : calculationer,
          comment: comment,
        },
        () => {},
        id
      );
    }
  };

  const dateTranslate = (date, type) => {
    if (date === null) return "";
    let stringDate = new Date(date);
    stringDate = stringDate.toLocaleDateString("ru-RU", {
      year: "numeric",
      month: type === "input" ? "numeric" : "long",
      day: "numeric",
    });
    if (type === "input") {
      stringDate = stringDate.split(".").reverse().join("-");
    }
    return stringDate;
  };

  const loadAllData = () => {
    Applications.getApplication(id, (res) => {
      setRequestState({
        name: res.data[0].states.name,
        id: res.data[0].states.id,
      });
      setObject(res.data[0].object);
      setOrganization(res.data[0].organisation);
      setAddress(res.data[0].addressOfOrganisation);
      setRequester(res.data[0].nameCreator);
      setCalculationer(res.data[0].nameUserCompleteCalculation);
      setContractNumber(res.data[0].contractNumber);
      setComment(res.data[0].comment);
      setDateOfCreating({
        input: dateTranslate(res.data[0].dateOfCreating, "input"),
        span: dateTranslate(res.data[0].dateOfCreating, "span"),
      });
      setBillingDate({
        input: dateTranslate(res.data[0].dateOfPayment, "input"),
        span: dateTranslate(res.data[0].dateOfPayment, "span"),
      });
      setClosingDate({
        input: dateTranslate(res.data[0].dateAccount, "input"),
        span: dateTranslate(res.data[0].dateAccount, "span"),
      });
      fetchPositions(res.data[0].positions);
      Materials.getAll((res) => {
        fetchCompletions(res.data);
      });
      Units.getAll((res) => {
        fetchUnits(res.data);
      });
    });
  };

  useEffect(() => {
    getCompletions();
    loadAllData();
  }, [id, fetchPositions, fetchCompletions, fetchUnits]);

  const onChangeHandler = (e) => {
    const { value, parentNode, previousSibling } = e.target;

    const text = value;
    const field = parentNode.parentNode.className;
    const fieldValue = completions[field];
    if (
      !fieldValue?.find(
        (completion) => completion.name.toUpperCase() === text.toUpperCase()
      ) &&
      value
    ) {
      previousSibling.lastChild.classList.remove("add-to-base--hidden");
    } else {
      previousSibling.lastChild.classList.add("add-to-base--hidden");
    }
    searchCompletions(text, field);
  };

  const showEditPopup = (e) => {
    const popup =
      e.target.parentNode.parentNode.parentNode.parentNode.nextSibling;
    const defaultValue = e.target.parentNode.previousSibling.textContent;
    popup.firstChild.firstChild.nextSibling.textContent = defaultValue;
    popup.firstChild.firstChild.nextSibling.nextSibling.firstChild.nextSibling.value = defaultValue;
    popup.classList.add("show");
  };

  const showDeletePopup = (e) => {
    const popup =
      e.target.parentNode.parentNode.parentNode.parentNode.nextSibling
        .nextSibling;
    const text = e.target.parentNode.previousSibling.textContent;
    let field =
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode.className;
    switch (field) {
      case "object":
        field = "Объект";
        break;
      case "organization":
        field = "Организация";
        break;
      case "address":
        field = "Адрес";
        break;
      case "requester":
        field = "Составитель";
        break;
      case "calculationer":
        field = "Произвел расчет";
        break;
      default:
        break;
    }
    popup.firstChild.firstChild.nextSibling.textContent = field;
    popup.firstChild.firstChild.nextSibling.nextSibling.textContent = text;
    popup.classList.add("show");
  };

  const showPopup = () => {
    const popup = document.getElementsByClassName("RightsPopup")[0];
    popup.classList.toggle("show");
  };

  const history = useHistory();

  return (
    <>
      <div className="Creating container">
        <div className="request">
          <div className="order-number">
            <Input
              title={L.orderNumberTitle}
              value={id}
              required={true}
              disabled={true}
            />
          </div>
          <div className="request-state">
            <Input
              title={L.requestStateTitle}
              value={requestState?.name}
              required={true}
              disabled={true}
            />
          </div>
          <div className="date-of-creating">
            <Input
              title={L.dateOfCreatingTitle}
              type="date"
              required={true}
              disabled={disabled}
              onChangeHandler={(e) => {
                setDateOfCreating({
                  input: e.target.value,
                  span: dateTranslate(e.target.value, "span"),
                });
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
              value={dateOfCreating.input}
              spanText={dateOfCreating.span}
            />
          </div>
          <div className="object">
            <Textarea
              value={object}
              title={L.objectTitle}
              required={true}
              disabled={disabled}
              extra={
                <div
                  className="add-to-base add-to-base--hidden"
                  onClick={(e) => {
                    addNewCompletion(e);
                    getCompletions();
                  }}
                >
                  {L.addToBase}
                </div>
              }
              completions={completions.object}
              chooseCompletion={(e) => chooseCompletion(e, setObject)}
              showEditPopup={showEditPopup}
              showDeletePopup={showDeletePopup}
              onChangeHandler={(e) => {
                onChangeHandler(e);
                setObject(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
              rows="3"
            />
            <EditPopup
              field="object"
              editCompletion={editCompletion}
              getCompletions={getCompletions}
            />
            <DeletePopup
              field="object"
              deleteCompletion={deleteCompletion}
              getCompletions={getCompletions}
            />
          </div>
          <div className="organization">
            <Textarea
              value={organization}
              title={L.organizationTitle}
              required={true}
              disabled={disabled}
              extra={
                <div
                  className="add-to-base add-to-base--hidden"
                  onClick={(e) => {
                    addNewCompletion(e);
                    getCompletions();
                  }}
                >
                  {L.addToBase}
                </div>
              }
              completions={completions.organization}
              chooseCompletion={(e) => chooseCompletion(e, setOrganization)}
              showEditPopup={showEditPopup}
              showDeletePopup={showDeletePopup}
              onChangeHandler={(e) => {
                onChangeHandler(e);
                setOrganization(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
              rows="3"
            />
            <EditPopup
              field="organization"
              editCompletion={editCompletion}
              getCompletions={getCompletions}
            />
            <DeletePopup
              field="organization"
              deleteCompletion={deleteCompletion}
              getCompletions={getCompletions}
            />
          </div>
          <div className="address">
            <Textarea
              value={address}
              title={L.addressTitle}
              required={true}
              disabled={disabled}
              rows="3"
              // extra={
              // 	<div
              // 		className="add-to-base add-to-base--hidden"
              // 		onClick={(e) => {
              // 			addNewCompletion(e);
              // 			getCompletions();
              // 		}}
              // 	>
              // 		{L.addToBase}
              // 	</div>
              // }
              // completions={completions.address}
              // chooseCompletion={(e) => chooseCompletion(e, setAddress)}
              // showEditPopup={showEditPopup}
              // showDeletePopup={showDeletePopup}
              onChangeHandler={(e) => {
                // onChangeHandler(e);
                setAddress(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
            />
            {/* <EditPopup
						field="address"
						editCompletion={editCompletion}
						getCompletions={getCompletions}
					/>
					<DeletePopup
						field="address"
						deleteCompletion={deleteCompletion}
						getCompletions={getCompletions}
					/> */}
          </div>
          {/* <div className="billing-date">
          <Input
            title={L.billingDateTitle}
            type="date"
            required={true}
            disabled={disabled}
            onBlurHandler={applicationUpdate}
            onChangeHandler={(e) => {
              setBillingDate({
                input: e.target.value,
                span: dateTranslate(e.target.value, "span"),
              });
              setCreateStatus(true);
            }}
            value={billingDate.input}
            spanText={billingDate.span}
          />
        </div> */}
          <div className="closing-date">
            <Input
              title={L.closingDateTitle}
              type="date"
              required={true}
              disabled={disabled}
              onBlurHandler={applicationUpdate}
              onChangeHandler={(e) => {
                setClosingDate({
                  input: e.target.value,
                  span: dateTranslate(e.target.value, "span"),
                });
                setCreateStatus(true);
              }}
              value={closingDate.input}
              spanText={closingDate.span}
            />
          </div>
          <div className="requester">
            <Textarea
              value={requester}
              title={L.requesterTitle}
              placeholder="Иванов Иван Иванович"
              required={true}
              disabled={disabled}
              extra={
                <div
                  className="add-to-base add-to-base--hidden"
                  onClick={(e) => {
                    addNewCompletion(e);
                    getCompletions();
                  }}
                >
                  {L.addToBase}
                </div>
              }
              completions={completions.requester}
              chooseCompletion={(e) => chooseCompletion(e, setRequester)}
              showEditPopup={showEditPopup}
              showDeletePopup={showDeletePopup}
              onChangeHandler={(e) => {
                onChangeHandler(e);
                return setRequester(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
            />
            <EditPopup
              field="requester"
              editCompletion={editCompletion}
              getCompletions={getCompletions}
            />
            <DeletePopup
              field="requester"
              deleteCompletion={deleteCompletion}
              getCompletions={getCompletions}
            />
          </div>
          <div className="calculationer">
            <Textarea
              value={calculationer}
              title={L.сalculationerTitle}
              placeholder="Иванов И.И."
              required={true}
              disabled={disabled}
              extra={
                <div
                  className="add-to-base add-to-base--hidden"
                  onClick={(e) => {
                    addNewCompletion(e);
                    getCompletions();
                  }}
                >
                  {L.addToBase}
                </div>
              }
              completions={completions.calculationer}
              chooseCompletion={(e) => chooseCompletion(e, setCalculationer)}
              showEditPopup={showEditPopup}
              showDeletePopup={showDeletePopup}
              onChangeHandler={(e) => {
                onChangeHandler(e);
                return setCalculationer(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
            />
            <EditPopup
              field="calculationer"
              editCompletion={editCompletion}
              getCompletions={getCompletions}
            />
            <DeletePopup
              field="calculationer"
              deleteCompletion={deleteCompletion}
              getCompletions={getCompletions}
            />
          </div>
          <div className="contract-number">
            <Input
              value={contractNumber}
              title={L.contractNumberTitle}
              required={true}
              disabled={disabled}
              onChangeHandler={(e) => {
                setContractNumber(e.target.value);
                setCreateStatus(true);
              }}
              onBlurHandler={applicationUpdate}
            />
          </div>
          <div className="billing-date"></div>
          <div className="documents">
            <DownloadReport id={id} />
          </div>
          <div className="note">
            <div className="Input">
              <div className="top">
                <div className="title">{L.noteTitle}</div>
              </div>
              <textarea
                onBlur={() => applicationUpdate()}
                value={comment}
                disabled={disabled}
                onChange={(e) => {
                  setComment(e.target.value);
                  setCreateStatus(true);
                }}
              ></textarea>
            </div>
          </div>
        </div>
        {!disabled ? (
          <div className="delete-button-container">
            <div className="buttonDelete" onClick={() => showPopup()}>
              {L.applicationDelete}
            </div>
            <RightsPopup
              name={id}
              text="Вы действительно хотите удалить заявку с номером"
              title={L.applicationDeleteTitle}
              onClickCancel={() => showPopup()}
              onClickOk={() =>
                Applications.delete(id, () => history.push("/filling"))
              }
            />
          </div>
        ) : null}
      </div>
      <div className="Lower-table-container container">
        <LowerTable
          disabled={disabled}
          dateChangeHandler={dateTranslate}
          positions={positions}
          addNewPosition={addNewPosition}
          button={true}
          columnNames={L.requestsTableTitles}
          fetchPositions={fetchPositions}
          id={id}
          dateTranslate={dateTranslate}
          deletePosition={deletePosition}
          updatePosition={newUpdatePosition}
          completionsName={completionsName}
          fetchCompletions={fetchCompletions}
          units={units}
          applicationUpdate={applicationUpdatePositions}
          loadAllData={loadAllData}
        />
      </div>
    </>
  );
}

export default Creating;
