import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Title from '../Title';
import Input from '../Input';
import Button from '../Button';
import { authorizationWords as L } from '../../languages/languages';
import { Users } from '../../api/methods';
import { Units } from '../../api/methods';

const Authorization = () => {
	const history = useHistory();

	const isAuthorized = () => {
		Units.getAll(res => {
			// if (res.length !== 0) history.push("/");
		});
	};

	useEffect(isAuthorized, []);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const navigateToMain = () => {
		history.push('/');
	};

	const loginSuccess = res => {
		const { accessToken, refreshToken, roots, regions } = res.data;
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('refreshToken', refreshToken);
		localStorage.setItem('roots', JSON.stringify(roots));
		localStorage.setItem('regions', JSON.stringify(regions));
		localStorage.setItem('regionId', regions[0]?.id);
		navigateToMain();
	};

	const tryLogin = () => {
		Users.auth({ email, password }, loginSuccess);
	};

	return (
		<div className="Authorization">
			<div className="window">
				<Title title={L.title} />
				<Input
					placeholder={L.loginPlaceholder}
					className="login"
					required={true}
					value={email}
					onChangeHandler={e => setEmail(e.target.value)}
				/>
				<Input
					placeholder={L.passwordPlaceholder}
					className="password"
					type="password"
					required={true}
					value={password}
					onChangeHandler={e => setPassword(e.target.value)}
				/>
				<Button onClickHandler={tryLogin} title={L.signInButton} />
			</div>
		</div>
	);
};

export default Authorization;
