import { connect } from "react-redux";
import {
  addRegion,
  removeRegion,
  fetchUser,
  editField,
  editRoots,
} from "../store/actions";
import UserEdit from "../components/routes/UserEdit";

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (user) => {
    return dispatch(fetchUser(user));
  },
  editField: (field, value) => {
    return dispatch(editField(field, value));
  },
  editRoots: (root) => {
    return dispatch(editRoots(root));
  },
  addRegion: (region) => {
    return dispatch(addRegion(region));
  },
  removeRegion: (region) => {
    return dispatch(removeRegion(region));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
