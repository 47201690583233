import React from "react";
// import { Positions } from "../../api/methods";

const LowerTableButton = ({ onHandlerClick }) => {
  return (
    <div className="Lower-table-button">
      <div className="button">
        <span
          onClick={() => {
            onHandlerClick();
          }}
        >
          + Добавить позицию
        </span>
      </div>
    </div>
  );
};
export default LowerTableButton;
