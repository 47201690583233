import React from "react";

function PrintString() {
  return (
    <div className="Print-String">
      <div className="Print-Button">Печать</div>
      <div className="Back-Button">Закрыть заявку</div>
    </div>
  );
}

export default PrintString;
