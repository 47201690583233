import React from "react";
import Input from "./Input";
import Select from "./Select";

const NameInputPopup = ({
  value = "",
  onClickCancel,
  onClickOk,
  isShowPopup = false,
  onChangeName,
  units = [],
  setUnit,
  unitId,
}) => {
  return (
    <div className={`NameInputPopup ${isShowPopup ? "show" : ""}`}>
      <div className="window">
        <div className="rightsPopup__title">Создание нового материала</div>
        <div className="NameInputPopup_content">
          <Input
            name="nameInputPopup"
            className="nameInputPopup"
            value={value}
            onChangeHandler={(e) => onChangeName(e.target.value)}
            title="Название материала"
          />
          <div className="selectTitle">Единица измерения</div>
          <Select
            classNameSelect="unitSelect"
            value={unitId}
            onClickHandler={(e) => {
              e.target.classList.toggle("arrow-active");
            }}
            onBlurHandler={(e) => {
              e.target.classList.remove("arrow-active");
            }}
            onChangeHandler={(e) => setUnit(e.target.value)}
            options={units}
          />
        </div>
        <div className="rightsPopup__buttons">
          <div className="rightsPopup__buttons-false" onClick={onClickCancel}>
            Отмена
          </div>
          <div className="rightsPopup__buttons-true" onClick={onClickOk}>
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameInputPopup;
