import T from "../types";

const initialState = {
  name: "",
  login: "",
  regions: [],
  roots: {},
};

export default function user(state = initialState, action) {
  let newState = { ...state };
  switch (action.type) {
    case T.FETCH_USER:
      return action.user;
    case T.EDIT_FIELD:
      newState[action.field] = action.value;
      return newState;
    case T.EDIT_ROOTS:
      newState.roots[action.root] = state.roots[action.root] === 1 ? 0 : 1;
      return newState;
    case T.ADD_REGION:
      if (state.regions.includes(action.region) || action.region === "selected") {
        return state;
      } else {
        newState.regions.push(action.region);
        return newState;
      }
    case T.REMOVE_REGION:
      newState.regions.splice(newState.regions.indexOf(action.region), 1);
      return newState;
    default:
      return state;
  }
}
