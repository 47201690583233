import React from "react";
import Input from "./Input";
import { accountCreateCard as A } from "../languages/languages";
import { useState } from "react";
import { Accounts, Providers, Customers } from "../api/methods";
import { useEffect } from "react";
import UpdatePopup from "./UpdatePopup";
import DeleteCustomerPopup from "./DeleteCustomerPopup";

const AccountCreatingCard = ({
  isShowCreatePopup,
  setIsShowCreatePopup,
  serialNumber,
  setSelectNumberAccount,
  setAccountCreated,
  accountCreated,
  setNextAccountId,
}) => {
  useEffect(() => {
    getAllCustomers();
    getAllProviders();
  }, []);

  const [inputsValue, setInputsValue] = useState({
    num: "",
    sum: 0,
    date: "",
    cust: "",
    sup: "",
  });
  const [isCreate, setIsCreate] = useState(false);
  const [allCustomers, setAllCustomers] = useState();
  const [selectionCustomers, setSelectionCustomers] = useState();
  const [allProviders, setAllProviders] = useState();
  const [selectionProviders, setSelectionProviders] = useState();
  const [isShowEditPopup, setIsShowEditPopup] = useState(false);
  const [idToUpdate, setIdToUpdate] = useState("");
  const [isShowAddToBaseCustomer, setIsShowAddToBaseCustomer] = useState(false);
  const [isShowAddToBaseProvider, setIsShowAddToBaseProvider] = useState(false);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);
  const [subjectUpdate, setSubjectUpdate] = useState("");

  useEffect(() => {
    if (allCustomers !== undefined) updateSelectionCustomers(inputsValue.cust);
  }, [inputsValue.cust]);

  useEffect(() => {
    if (allProviders !== undefined) updateSelectionProviders(inputsValue.sup);
  }, [inputsValue.sup]);

  const getAllCustomers = () => {
    Customers.getAll((res) => {
      setAllCustomers(res.data);
      setSelectionCustomers(res.data);
    });
  };

  const getAllProviders = () => {
    Providers.getAll((res) => {
      setAllProviders(res.data);
      setSelectionProviders(res.data);
    });
  };

  const clearInput = () => {
    setInputsValue({ num: "", sum: 0, date: "", cust: "", sup: "" });
  };

  const updateSelectionCustomers = (value) => {
    setSelectionCustomers(
      allCustomers.filter((customer) =>
        customer.name.toUpperCase().includes(value.toUpperCase())
      )
    );
    let customerIsReally = allCustomers.findIndex((customer) => {
      return customer.name === value;
    });
    if (customerIsReally === -1 && value !== "") {
      setIsShowAddToBaseCustomer(true);
    } else {
      setIsShowAddToBaseCustomer(false);
    }
  };

  const updateSelectionProviders = (value) => {
    setSelectionProviders(
      allProviders.filter((provider) =>
        provider.name.toUpperCase().includes(value.toUpperCase())
      )
    );
    let providerIsReally = allProviders.findIndex((provider) => {
      return provider.name === value;
    });
    if (providerIsReally === -1 && value !== "") {
      setIsShowAddToBaseProvider(true);
    } else {
      setIsShowAddToBaseProvider(false);
    }
  };

  useEffect(() => {
    if (
      inputsValue.num !== "" &&
      inputsValue.sum !== "" &&
      inputsValue.date !== "" &&
      inputsValue.cust !== "" &&
      inputsValue.sup !== ""
    ) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [
    inputsValue.num,
    inputsValue.sum,
    inputsValue.date,
    inputsValue.cust,
    inputsValue.sup,
  ]);

  return (
    <>
      <div className={`accountCreatingCard ${isShowCreatePopup ? "show" : ""}`}>
        <div className="window">
          <div className="card-title">{A.title}</div>
          <div className="table">
            <div className={A.createCardDiv.className + "-title"}>
              {A.createCardDiv.title}
              <div className={A.createCardDiv.className}>{serialNumber}</div>
            </div>
            {A.createCardInput.map((item, index) => {
              let customer = item.className === "acc-Cust" ? true : false;
              let provider = item.className === "acc-Sup" ? true : false;
              return (
                <div key={index} className={item.className + "-title"}>
                  {item.title}
                  <Input
                    classNameInput={item.className}
                    type={item.type}
                    name={item.name}
                    onChangeHandler={(e) => {
                      setInputsValue({
                        ...inputsValue,
                        [item.name]: e.target.value,
                      });
                    }}
                    value={inputsValue[item.name]}
                    completions={
                      customer
                        ? selectionCustomers
                        : provider
                        ? selectionProviders
                        : []
                    }
                    chooseCompletion={
                      customer || provider
                        ? (e) => {
                            setInputsValue({
                              ...inputsValue,
                              [provider ? "sup" : "cust"]: e.target.textContent,
                            });
                          }
                        : () => {}
                    }
                    showEditPopup={
                      customer || provider
                        ? (e) => {
                            setIdToUpdate(e.target.dataset.id);
                            setSubjectUpdate(provider ? "sup" : "cust");
                            setIsShowEditPopup(true);
                          }
                        : () => {}
                    }
                    showDeletePopup={
                      customer || provider
                        ? (e) => {
                            setIdToUpdate(e.target.dataset.id);
                            setSubjectUpdate(provider ? "sup" : "cust");
                            setIsShowDeletePopup(true);
                          }
                        : () => {}
                    }
                    extra={
                      (customer && isShowAddToBaseCustomer) ||
                      (provider && isShowAddToBaseProvider) ? (
                        <div
                          className="add-to-base"
                          onClick={() => {
                            customer
                              ? Customers.create(
                                  {
                                    Customer: {
                                      name: inputsValue.cust,
                                    },
                                  },
                                  () => getAllCustomers()
                                )
                              : Providers.create(
                                  {
                                    name: inputsValue.sup,
                                  },
                                  () => getAllProviders()
                                );
                          }}
                        >
                          Добавить в базу
                        </div>
                      ) : (
                        ""
                      )
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className="conclusion">
            {isCreate ? (
              <div
                className="save-data"
                onClick={() => {
                  Accounts.create(
                    {
                      accountNumber: inputsValue.num,
                      invoiceAmount: inputsValue.sum,
                      dateAccount: inputsValue.date,
                      // dateOfPayment: inputsValue.num,
                      client: inputsValue.cust,
                      supplier: inputsValue.sup,
                    },
                    () => {
                      setIsShowCreatePopup(false);
                      setSelectNumberAccount(serialNumber);
                      setAccountCreated(!accountCreated);
                      setNextAccountId(serialNumber + 1);
                      clearInput();
                    }
                  );
                }}
              >
                {A.saveData}
              </div>
            ) : (
              <div className="fillFields">{A.fillFields}</div>
            )}
            <div
              className="cancel"
              onClick={() => {
                setIsShowCreatePopup(false);
                clearInput();
              }}
            >
              {A.cancel}
            </div>
          </div>
        </div>
      </div>
      <UpdatePopup
        isShowEditPopup={isShowEditPopup}
        setIsShowEditPopup={setIsShowEditPopup}
        idToUpdate={idToUpdate}
        getAllCustomers={getAllCustomers}
        getAllProviders={getAllProviders}
        subjectUpdate={subjectUpdate}
      />
      <DeleteCustomerPopup
        isShowDeletePopup={isShowDeletePopup}
        setIsShowDeletePopup={setIsShowDeletePopup}
        idToUpdate={idToUpdate}
        getAllCustomers={getAllCustomers}
        getAllProviders={getAllProviders}
        subjectUpdate={subjectUpdate}
      />
    </>
  );
};

export default AccountCreatingCard;
