import T from "../types";
import {
	Buildings,
	OurOrganizations,
	Customers,
	measuringEmployees,
} from "../../api/methods";

let initialState = {};

export default function completions(state = initialState, action) {
	switch (action.type) {
		case T.GET_COMPLETIONS:
			Buildings.getAll((res) => {
				initialState.object = res.data;
			});
			OurOrganizations.getAll((res) => {
				initialState.organization = res.data;
			});
			Customers.getAll((res) => {
				initialState.requester = res.data;
			});
			measuringEmployees.getAll((res) => {
				initialState.calculationer = res.data;
			});
			return initialState;
		case T.SEARCH_COMPLETIONS:
			const { field } = action;
			const searchString = action.text.toUpperCase();
			return {
				...initialState,
				[field]: initialState[field].filter((completion) => {
					completion = completion.name.toUpperCase();
					return (
						completion.includes(searchString) && completion !== searchString
					);
				}),
			};
		case T.CHOOSE_COMPLETION:
			let newChooseState = Object.assign({}, state);
			newChooseState[action.field] = [];
			return newChooseState;
		case T.ADD_NEW_COMPLETION:
			switch (action.field) {
				case "object":
					Buildings.create({
						Building: {
							name: action.text,
							address: "string",
							customerId: 2,
						},
					});
					break;
				case "organization":
					OurOrganizations.create({ OurOrganization: { name: action.text } });
					break;
				case "requester":
					Customers.create({ Customer: { name: action.text } });
					break;
				case "calculationer":
					measuringEmployees.create({
						MeasuringEmployee: { name: action.text },
					});
					break;
				default:
					break;
			}
			return state;

		case T.EDIT_COMPLETION:
			const editId = state[action.field].find(
				(item) => item.name === action.oldText
			).id;

			switch (action.field) {
				case "object":
					Buildings.update({
						Building: {
							id: editId,
							name: action.newText,
							address: "string",
							customerId: 2,
						},
					});
					break;
				case "organization":
					OurOrganizations.update({
						OurOrganization: { id: editId, name: action.newText },
					});
					break;
				case "requester":
					Customers.update({ Customer: { id: editId, name: action.newText } });
					break;
				case "calculationer":
					measuringEmployees.update({
						MeasuringEmployee: { id: editId, name: action.newText },
					});
					break;
				default:
					break;
			}
			return state;

		case T.DELETE_COMPLETION:
			const deleteId = state[action.field].find(
				(item) => item.name === action.text
			).id;
			switch (action.field) {
				case "object":
					Buildings.delete(deleteId);
					break;
				case "organization":
					OurOrganizations.delete(deleteId);
					break;
				case "requester":
					Customers.delete(deleteId);
					break;
				case "calculationer":
					measuringEmployees.delete(deleteId);
					break;
				default:
					break;
			}
			// const newDeleteState = Object.assign({}, state);
			// const deleteIndex = newDeleteState[action.field].indexOf(action.text);
			// newDeleteState[action.field].splice(deleteIndex, 1);
			// initialState[action.field].splice(
			// 	initialState[action.field].indexOf(action.text),
			// 	1
			// );
			return state;
		default:
			return state;
	}
}
