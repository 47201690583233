import React from "react";
import { useHistory } from "react-router-dom";
import { fillingWords as L } from "../languages/languages";
import RequestContainer from "../containers/RequestContainer";

function Purchasing() {
  const history = useHistory();
  return (
    <RequestContainer path="purchasing/">
      <div className="archive" onClick={() => history.push("/archive")}>
        {L.archiveButton}
      </div>
    </RequestContainer>
  );
}

export default Purchasing;
