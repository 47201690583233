import React from "react";

function MainCell({ content, className, AllText }) {
	return (
		<div className={className}>
			{content}
			<div className="hide-block">{AllText}</div>
		</div>
	);
}
export default MainCell;
