import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { States } from "./api/methods";

const Statuses = () => {
  const [state, setState] = useState({
    statuses: [],
    newState: "",
  });

  const handleChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const loadStates = () => {
    const loadAllSuccess = (res) => {
      setState({ ...state, statuses: res.data });
    };
    States.getAll(loadAllSuccess);
  };
  useEffect(() => {
    loadStates();
  }, []);

  const createStatus = () => {
    States.create({ State: { name: state.newState } }, loadStates);
  };
  return (
    <div>
      {state.statuses.map(({ id, name }) => (
        <div>
          {id} __ {name}
        </div>
      ))}
      <input
        value={state.newState}
        name="newState"
        onChange={handleChange}
      ></input>
      <button onClick={createStatus}>Добавить статус</button>
    </div>
  );
};

export default Statuses;
