import React, { useState, useEffect } from "react";
import Input from "./Input";
import { storagePopupWords as L } from "../languages/languages";
import { Accounts } from "../api/methods";

const StoragePopup = ({ id, active = false, onClickExit }) => {
  const initialState = {
    id: null,
    accountNumber: "string",
    invoiceAmount: null,
    dateAccount: "string",
    dateOfPayment: null,
    client: "string",
    supplier: "string",
    positions: [],
  };
  const [account, setAccount] = useState(initialState);

  useEffect(() => {
    Accounts.getAccount(id, (res) => setAccount(res.data));
  }, [id]);

  return (
    <div className={"StoragePopup" + (active ? " show" : "")}>
      <div className="window">
        <div className="StoragePopup__header">
          <div className="StoragePopup__title">{L.title}</div>
          <div
            className="StoragePopup__exit"
            onClick={() => onClickExit(false)}
          >
            {L.exit}
          </div>
        </div>
        <div className="StoragePopup__fields">
          <Input
            title={L.id}
            className="StoragePopup__id"
            value={account.id}
            disabled
          />
          <Input
            title={L.accoutNumber}
            className="StoragePopup__account"
            value={account.accountNumber}
            disabled
          />
          <Input
            title={L.date}
            type="date"
            className="StoragePopup__date"
            value={account.dateAccount?.slice(0, 10)}
            disabled
          />
          <Input
            title={L.sum}
            className="StoragePopup__sum"
            value={account.invoiceAmount}
            disabled
          />
          <Input
            title={L.supplier}
            className="StoragePopup__supplier"
            value={account.supplier}
            disabled
          />
          <Input
            title={L.cient}
            className="StoragePopup__client"
            value={account.client}
            disabled
          />
        </div>
        <div className="StoragePopup__positions">
          {account.positions.length === 0 ? null : (
            <>
              <div className="titles">
                <div className="titles__name">{L.positionName}</div>
                <div className="titles__date">{L.dateDelivery}</div>
                <div className="titles__count">{L.count}</div>
                <div className="titles__returned">{L.returned}</div>
                <div className="titles__released">{L.released}</div>
              </div>
              {account.positions.map((position) => (
                <div className="position" key={position.id}>
                  <Input
                    className="position__name"
                    value={position.name}
                    disabled
                  />
                  <Input
                    type="date"
                    className="position__date"
                    value={position.realDateDelivery?.slice(0, 10)}
                    disabled
                  />
                  <Input
                    className="position__count"
                    value={position.count}
                    disabled
                  />
                  <Input
                    className="position__returned"
                    value={position.returned}
                    disabled
                  />
                  <Input
                    className="position__released"
                    value={position.released}
                    disabled
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoragePopup;
