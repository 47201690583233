import React from "react";

const Organizations = () => {
  return (
    <div className="Organizations">

    </div>
  )
}

export default Organizations;