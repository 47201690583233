import React from "react";

const RightsPopup = ({
  name = "",
  title = "",
  text = "",
  onClickCancel,
  onClickOk,
  children,
}) => {
  return (
    <div className="RightsPopup">
      <div className="window">
        <div className="rightsPopup__title">{title}</div>
        {children}
        <div className="rightsPopup__text">
          {text} <span>{name}</span>?
        </div>
        <div className="rightsPopup__buttons">
          <div className="rightsPopup__buttons-false" onClick={onClickCancel}>
            Отмена
          </div>
          <div className="rightsPopup__buttons-true" onClick={onClickOk}>
            ОК
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightsPopup;
