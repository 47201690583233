import React from "react";
import AccRow from "./AccRow";
import AdditInfo from "./AdditInfo";
import { useState } from "react";
import { useEffect } from "react";
import { Accounts } from "../../api/methods";

function AccString({ accounting, fetchAccounting }) {
  const [updateApplication, setUpdateApplication] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  useEffect(() => {
    Accounts.getAll((res) => {
      let { data } = res;

      let ascending = false;
      let nullPosition = ascending ? 1 : -1;
      data = data.sort(function (a, b) {
        if (a.dateOfPayment == null) return nullPosition;
        if (b.dateOfPayment == null) return -nullPosition;
        if (a.dateOfPayment < b.dateOfPayment) return -nullPosition;
        if (a.dateOfPayment > b.dateOfPayment) return nullPosition;
        return 0;
      });
      console.log(data);
      fetchAccounting(data);
    });
  }, [fetchAccounting, updateApplication]);

  return (
    <div className="Acc-alter">
      {accounting.map((item, index) => {
        return (
          <div key={item.id} className="Acc-hide">
            <AccRow
              row={item}
              key={index}
              onClickHandler={() =>
                selectedRow === item.id
                  ? setSelectedRow(0)
                  : setSelectedRow(item.id)
              }
            />
            {selectedRow === item.id ? (
              <AdditInfo
                account={item}
                setUpdateApplication={setUpdateApplication}
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default AccString;
