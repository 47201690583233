import React, { useState } from "react";
import Input from "../Input";
import { mainTableWords as L } from "../../languages/languages";

const MainTableFilters = ({ filterAccounts }) => {
  const [customer, setCustomer] = useState("");
  const [provider, setProvider] = useState("");
  const [date, setDate] = useState("");
  const [account, setAccount] = useState("");
  const [sum, setSum] = useState("");

  return (
    <div className="main-table-filters container">
      <div className="menu">
        <div className="allAccounts">{L.allAccounts}</div>
        <div className="archive">{L.archive}</div>
      </div>
      <div className="filters">
        <Input
          className="client"
          name="client"
          value={customer}
          placeholder="Заказчик"
          onChangeHandler={(e) => {
            setCustomer(e.target.value);
            filterAccounts(e);
          }}
        />
        <Input
          className="supplier"
          name="supplier"
          value={provider}
          placeholder="Поставщик"
          onChangeHandler={(e) => {
            setProvider(e.target.value);
            filterAccounts(e);
          }}
        />
        <Input
          className="dateOfPayment"
          name="dateOfPayment"
          value={date}
          placeholder="Дата оплаты"
          onChangeHandler={(e) => {
            setDate(e.target.value);
            filterAccounts(e);
          }}
        />
        <Input
          className="accountNumber"
          name="accountNumber"
          value={account}
          placeholder="Номер счета"
          onChangeHandler={(e) => {
            setAccount(e.target.value);
            filterAccounts(e);
          }}
        />
        <Input
          className="invoiceAmount"
          name="invoiceAmount"
          value={sum}
          placeholder="Сумма"
          onChangeHandler={(e) => {
            setSum(e.target.value);
            filterAccounts(e);
          }}
        />
      </div>
    </div>
  );
};

export default MainTableFilters;
