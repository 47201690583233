import React, { useEffect } from "react";
import { usersWords as L } from "../languages/languages";
import Checkbox from "./Checkbox";

const RootsTable = ({ roots, editRoots, setRoots }) => {
  const rootsChangeHandler = ({ target: { name } }) => {
    if (roots.includes(name)) {
      const index = roots.indexOf(name);
      const newArr = [...roots];
      newArr.splice(index, 1);
      setRoots(newArr);
    } else {
      roots.push(name);
    }
  };
  const isCheckedEditRoots = (name, type) =>
    typeof roots === "undefined" ? false : roots[name + type] === 1;

  return (
    <div className="Rights-Table">
      <div className="row head" key="0">
        <div className="column">{L.rightsFirstColumn}</div>
        <div className="column">{L.rightsSecondColumn}</div>
        <div className="column">{L.rightsThirdColumn}</div>
      </div>
      {setRoots
        ? L.roots.map((root) => {
            return (
              <div className="row" key={root.name}>
                <div className="column">{root.title}</div>
                <div className="column">
                  <Checkbox
                    name={root.name + "Read"}
                    checked={roots.includes(root.name + "Read") ? true : null}
                    onChangeHandler={rootsChangeHandler}
                  />
                </div>
                <div className="column">
                  <Checkbox
                    name={root.name + "Edit"}
                    checked={roots.includes(root.name + "Edit") ? true : null}
                    onChangeHandler={rootsChangeHandler}
                  />
                </div>
              </div>
            );
          })
        : L.roots.map(({ name, title }) => {
            return (
              <div className="row" key={name}>
                <div className="column">{title}</div>
                <div className="column">
                  <Checkbox
                    name={name + "Read"}
                    checked={isCheckedEditRoots(name, "Read")}
                    onChangeHandler={editRoots}
                  />
                </div>
                <div className="column">
                  <Checkbox
                    name={name + "Edit"}
                    checked={isCheckedEditRoots(name, "Edit")}
                    onChangeHandler={editRoots}
                  />
                </div>
              </div>
            );
          })}
      {L.allwaysAllowsRoots.map(({ name, title }) => (
        <div className="row" style={{ display: "none" }} key={name}>
          <div className="column">{title}</div>
          <div className="column">
            <Checkbox
              name={name + "Read"}
              checked={true}
              onChangeHandler={editRoots}
            />
          </div>
          <div className="column">
            <Checkbox
              name={name + "Edit"}
              checked={true}
              onChangeHandler={editRoots}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RootsTable;

// roots.map((root, index) => {
//   roots.includes();
//   if (!L.roots[index]) return null;
//   const { title } = L.roots[index];
//   return (
//     <div className="row" key={index + 1}>
//       <div className="column">{title}</div>
//       <div className="column">
//         <Checkbox
//           name={Object.keys(root)[0]}
//           checked={root[Object.keys(root)[0]] === 1 ? true : null}
//           onChangeHandler={editRoots}
//         />
//       </div>
//       <div className="column">
//         <Checkbox
//           name={Object.keys(root)[1]}
//           checked={root[Object.keys(root)[1]] === 1 ? true : null}
//           onChangeHandler={editRoots}
//         />
//       </div>
//     </div>
//   );
// });
