import React from "react";
import { fillingWords as L } from "../languages/languages";
import RequestContainer from "../containers/RequestContainer";
import { useHistory } from "react-router-dom";

function Filling() {
  const history = useHistory();
  return (
    <RequestContainer path="filling/">
      <div
        className="create-new-request"
        onClick={() => history.push("/new-request")}
      >
        {L.createNewRequestButton}
      </div>
      <div className="archive" onClick={() => history.push("/archive")}>
        {L.archiveButton}
      </div>
    </RequestContainer>
  );
}

export default Filling;
