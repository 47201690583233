import T from "../types";

const initialState = {
  text: "",
  isShow: false,
};

export default function alert(state = initialState, action) {
  switch (action.type) {
    case T.SHOW_ALERT:
      return { text: action.text, isShow: true };
    case T.CLOSE_ALERT:
      return { ...state, isShow: false };
    default:
      return state;
  }
}
