import T from "../types";

const initialState = [];

export default function positions(state = initialState, action) {
  let newState = [...state];
  switch (action.type) {
    case T.FETCH_POSITIONS:
      return action.positions;
    case T.DELETE_POSITION:
      const indexDelete = state.findIndex((item) => item.id === action.id);
      newState.splice(indexDelete, 1);
      return newState;
    case T.UPDATE_POSITION:
      const indexUpdate = newState.findIndex(
        (item) => String(item.id) === String(action.id)
      );
      const field = newState[indexUpdate];
      field[action.name] = action.value;
      return newState;
    case T.ADD_NEW_POSITION:
      newState.push(action.newPosition);
      return newState;
    default:
      return state;
  }
}
