import React, { useEffect } from "react";
import { creatingWords as C } from "../languages/languages";
import Select from "./Select";
import Input from "./Input";
import { useState } from "react";

const InformationsPopup = ({
  isShowInformationPopup = false,
  setIsShowInformationPopup,
  dateTranslate = () => {},
  dateDelivery,
  allAccountsId,
  setIsShowCreatePopup,
  selectNumberAccount,
  setSelectNumberAccount,
  dataId,
  updatePositions,
  setRealDateDelivery,
  realDateDelivery,
  setAccountId,
  setAccountNumber,
  accountId,
}) => {
  const [realDateDeliveryPopup, setRealDateDeliveryPopup] = useState({
    input: realDateDelivery.input,
    span: realDateDelivery.span,
  });
  return (
    <div className={`InformationPopup ${isShowInformationPopup ? "show" : ""}`}>
      <div className="window">
        <div className="title">{C.informationTitle}</div>
        <div className="popup-body">
          <div className="cell-popup">
            <div className="column">
              <div className="subtitle">{C.informationPopup[0].title}</div>
              <div className="cell-popup-name">
                {C.informationPopup[0].subtitle}
              </div>
              <Select
                options={allAccountsId}
                value={selectNumberAccount}
                onChangeHandler={(e) => {
                  setSelectNumberAccount(Number(e.target.value));
                }}
              />
            </div>
          </div>
          <div className="cell-popup">
            <div className="column">
              <div className="subtitle">{C.informationPopup[1].title}</div>
              <div className="cell-popup-name">
                {C.informationPopup[1].subtitle[0]}
              </div>
              <Input
                type="date"
                value={realDateDeliveryPopup.input}
                onChangeHandler={(e) => {
                  setRealDateDeliveryPopup({
                    input: e.target.value,
                    span: dateTranslate(e.target.value, "span"),
                  });
                }}
                spanText={
                  realDateDeliveryPopup.span === "Invalid Date" ||
                  realDateDeliveryPopup.span === ""
                    ? "дд.мм.гггг"
                    : realDateDeliveryPopup.span
                }
                name="realDateDelivery"
                dataId={dataId}
              />
            </div>
            {dateDelivery === "" ? (
              <></>
            ) : (
              <div className="column without-subtitle">
                <div className="cell-popup-name">
                  {C.informationPopup[1].subtitle[1]}
                </div>
                <div className="delivery-date">
                  {dateDelivery === "Invalid Date" ? "" : dateDelivery}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="popup-footer">
          <div
            className="popup-footer-save"
            onClick={() => {
              //setAccountId(1);
              setIsShowInformationPopup(false);
              setAccountId(selectNumberAccount);
              setRealDateDelivery({
                input: realDateDeliveryPopup.input,
                span: realDateDeliveryPopup.span,
              });
              const index = allAccountsId.findIndex(
                (account) => account.id === selectNumberAccount
              );
              setAccountNumber(allAccountsId[index].name);
              updatePositions(
                dataId,
                selectNumberAccount,
                realDateDeliveryPopup
              );
            }}
          >
            {C.editPopupSave}
          </div>
          {accountId ? (
            <div
              className="popup-footer-unpin"
              onClick={() => {
                setAccountId(null);
                setSelectNumberAccount(2);
                setIsShowInformationPopup(false);
                updatePositions(dataId, null);
              }}
            >
              {C.unpin}
            </div>
          ) : null}
          <div
            className="popup-footer-button"
            onClick={() => setIsShowCreatePopup(true)}
          >
            {C.createNewAccount}
          </div>

          {/* <div className="popup-footer-note">{C.notAccount}</div> */}
          <div
            className="popup-footer-cancel"
            onClick={() => {
              setIsShowInformationPopup(false);
              setRealDateDeliveryPopup(realDateDelivery);
              setSelectNumberAccount(selectNumberAccount);
            }}
          >
            {C.editPopupCancel}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationsPopup;
