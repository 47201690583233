import React from "react";
import UsersStr from "./UsersStr";
import UsersTitle from "./UsersTitle";

function UsersTable({ users }) {
  return (
    <div className="Main-table">
      <UsersTitle />
      <UsersStr users={users} />
    </div>
  );
}

export default UsersTable;
