import React from "react";
import { useHistory } from "react-router-dom";
import { creatingWords as C } from "../languages/languages";

const RequestsTable = ({ requests = [], hoverElement = null, path = "" }) => {
  const history = useHistory();
  return (
    <fieldset>
      <legend>
        {C.colorDesctiption.map((color) => {
          return (
            <div className="color-description">
              <div className="color" style={{ background: color.color }}></div>
              <div className="description">{color.name}</div>
            </div>
          );
        })}
      </legend>
      <div className="Requests-Table">
        <div className="col-description">
          <div className="index">{C.order}</div>
          <div className="number">{C.contractNumberTitle}</div>
          <div className="title">{C.objectTitle}</div>
          <div className="address">{C.addressTitle}</div>
          <div className="company">{C.organizationTitle}</div>
        </div>
        {requests.map((request) => {
          const {
            id,
            dateOfCreating,
            condition,
            addressOfOrganisation,
            organisation,
            contractNumber,
            object,
            stateId,
          } = request;
          return (
            <div
              key={id}
              onClick={path ? () => history.push(path + id) : null}
              className={"request " + "state-" + stateId}
            >
              <div className="index">{id}</div>
              <div className="number">{contractNumber}</div>
              <div className="title">
                <div className="top">
                  <div className="date">
                    {new Date(dateOfCreating).toLocaleDateString("ru-RU", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </div>
                  <div className="status">{condition}</div>
                </div>
                {object}
              </div>
              <div className="address">{addressOfOrganisation}</div>
              <div className="company">{organisation}</div>

              {hoverElement}
            </div>
          );
        })}
      </div>
    </fieldset>
  );
};

export default RequestsTable;
