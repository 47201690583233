import React from "react";

const Objects = () => {
  return (
    <div className="Objects">

    </div>
  )
}

export default Objects;