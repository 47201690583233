import React, { useState, useRef } from 'react';
import { creatingWords as C, materialNotFoundAlert } from '../../languages/languages';
import LowerTableCell from './LowerTableCell';
import Select from '../Select';
import Input from '../Input';
import Textarea from '../Textarea';
import { Positions, Materials, States, Accounts } from '../../api/methods';
import NameInputPopup from '../NameInputPopup';
import InformationPopup from '../InformationPopup';
import { useEffect } from 'react';
import AccountCreatingCard from '../AccountCreatingCard';
import StoragePopup from '../StoragePopup';
import RemoveMaterialPopup from '../RemoveMaterialPopup';
import AddNewMatertialPopup from '../AddNewMaterialPopup';
import { useDispatch } from 'react-redux';
import { showAlert } from '../../store/actions';
import DeletePositionsPopup from '../DeletePositionPopup';

const LowerTableRow = ({
	row = {},
	blur = '',
	columnNames = [],
	check = false,
	addNewPosition,
	dateTranslate = () => {},
	updatePosition = () => {},
	onAccountChange = () => {},
	id,
	button,
	completionsName,
	fetchCompletions,
	units,
	disabled,
	applicationUpdate = () => {},
	setIsCreating = () => {},
	hideCreatingRow = () => {},
	loadAllData = () => {},
	deletePosition = () => {},
	positionsLength,
	isCreating = false,
}) => {
	const [name, setPosition] = useState(row.name);
	const [count, setNumber] = useState(row.count);
	const [linkName, setLinkName] = useState(row.link);
	const [linkUrl, setLinkUrl] = useState(row.url);
	const [weight, setWeight] = useState(row.weight);
	const [volume, setVolume] = useState(row.volume);
	const [notes, setNotes] = useState(row.comment);
	const [materialId, setMaterialId] = useState(row.materialId);
	const [unitTableId, setUnitTableId] = useState();
	const [inStock, setInStock] = useState();
	const [releasedBuf, setRelBuf] = useState(row.released);
	const [returnedBuf, setRetBuf] = useState(row.returned);
	const [released, setReleased] = useState(row.released);
	const [returned, setReturned] = useState(row.returned);
	const [stateName, setStateName] = useState('');
	const [focus, setFocus] = useState(false);
	const [materials, setMaterials] = useState([]);
	const [isShowPopup, setIsShowPopup] = useState(false);
	const [isShowInformationPopup, setIsShowInformationPopup] = useState(false);
	const [isShowCreatePopup, setIsShowCreatePopup] = useState(false);
	const [isShowStoragePopup, setIsShowStoragePopup] = useState(false);
	const [isActiveStoragePopup, setIsActiveStoragePopup] = useState(false);
	const [isShowDeleteMaterialPopup, setIsShowDeleteMaterialPopup] = useState(false);
	const [isShowDeletePositionsPopup, setIsShowDeletePositionsPopup] = useState(false);
	const [unitId, setUnit] = useState(units[3]?.id);
	const [unitName, setUnitName] = useState(units[3]?.name);
	const [isShowAddNewMaterialPopup, setIsShowAddNewMaterialPopup] = useState(false);
	const [stateId, setStateId] = useState(row.stateId);
	const [accountId, setAccountId] = useState(row.accountId); // Просто порядковый номер
	const [accountNumber, setAccountNumber] = useState(row.accounts?.accountNumber); // Номер счета
	const [allAccountsId, setAllAccountsId] = useState([]);
	const [isMaterialExists, setIsMaterialExists] = useState(false);
	const [materialIsSave, setIsMaterialIsSave] = useState(true);
	const [materialDelete, setMaterialDelete] = useState({
		name: '',
		materialId: null,
	});
	const [selectNumberAccount, setSelectNumberAccount] = useState(row.accountId !== null ? row.accountId : 2);
	const [accountCreated, setAccountCreated] = useState(false);
	const [dateDelivery, setDeliveryDate] = useState({
		input: dateTranslate(row.dateDelivery, 'input'),
		span: dateTranslate(row.dateDelivery, 'span'),
	});
	const [realDateDelivery, setRealDateDelivery] = useState({
		input: dateTranslate(row.realDateDelivery, 'input'),
		span: dateTranslate(row.realDateDelivery, 'span'),
	});
	const [dateAccount, setDateAccount] = useState('—');
	const dispatch = useDispatch();
	const [isMaterialIdNull, setIsMaterialIdNull] = useState(row.materialId === null);
	const [isMaterialsUpdate, setIsMaterialsUpdate] = useState(false);
	const [idRow, setIdRow] = useState();
	const [nextAccountId, setNextAccountId] = useState(0);

	useEffect(() => {
		setIsMaterialIdNull(row.materialId === null);
	}, [row.materialId]);

	let inputName = useRef(null);
	let isChange = false;

	const updatePositions = (e, update = false) => {
		let rowId;
		let nameField;
		let value;
		if (update) {
			rowId = e.getAttribute('data-id');
			nameField = e.getAttribute('name');
			value = e.value;
		} else {
			rowId = e.target.getAttribute('data-id');
			nameField = e.target.getAttribute('name');
			value = e.target.value === undefined ? e.target.textContent : e.target.value;
		}
		updatePosition(rowId, nameField, value);

		if (id === false) {
			return;
		} else {
			apiPositionsUpdate(rowId);
		}
	};

	useEffect(() => {
		setIsShowAddNewMaterialPopup(false);
	}, [isShowDeleteMaterialPopup, isShowPopup]);

	useEffect(() => {
		setIsShowAddNewMaterialPopup(false);
	}, [isShowPopup]);

	useEffect(() => {
		setUnit(units[3]?.id);
		setUnitName(units[3]?.name);
		setUnitTableId(units[3]?.id);
	}, [isCreating]);

	useEffect(() => {
		setUnit(units[3]?.id);
		setUnitName(units[3]?.name);
		// setUnitTableId(units[3]?.id);
	}, [units]);

	useEffect(() => {
		if (accountId !== null) {
			Accounts.getAccount(accountId, res => {
				setDateAccount(dateTranslate(res.data.dateOfPayment, 'span'));
			});
		}
	}, [accountId]);

	let checkBlur = e => {
		const rowId = e.target.getAttribute('data-id');
		const nameField = e.target.getAttribute('name');
		updatePosition(rowId, nameField, e.target.value);

		if (id === false || check === true) {
			return;
		} else {
			let trueReleased = released;
			const available = Number(inStock) + Number(releasedBuf) - Number(returnedBuf);
			if (released > count) {
				dispatch(
					showAlert(
						`Нельзя отпустить ${released}${unitName} больше требуемого количества: ${count}${unitName}`
					)
				);
				setReleased(available);
				trueReleased = available;

				// return;
			}
			console.log('-----------------');
			console.log(`Доступно: ${available}`);
			console.log(`На складе: ${inStock}; Возвращено: ${returnedBuf}; Отпущено ${releasedBuf}`);
			console.log('-----------------');
			if (released > available) {
				dispatch(showAlert(`На складе нет ${released}${unitName} ${name}. Доступно: ${available}`));
				trueReleased = available;
				setReleased(available);
				// return;
			}
			// ? inStock >= count
			//   ? count
			//   : inStock
			// : released > inStock
			// ? inStock
			// : released;

			Positions.update(
				{
					Position: {
						id: Number(rowId),
						count: count,
						dateAdding: dateTranslate(row.dateAdding, 'input'),
						link: linkName,
						weight: weight,
						volume: volume,
						dateDelivery: dateDelivery.input === 'Invalid Date' ? '' : dateDelivery.input,
						applicationId: id,
						materialId: materialId,
						stateId: 1,
						released: trueReleased,
						comment: notes,
						realDateDelivery: realDateDelivery.input,
						returned: returned,
					},
				},
				() => {
					setReleased(trueReleased);
					getMaterial();
				}
			);
		}
	};

	useEffect(() => {
		if (name !== row.name && id !== false) {
			apiPositionsUpdate(row.id);
		} else if (name !== row.name && id === false) {
			updatePosition(row.id, 'materialId', materialId);
			updatePosition(row.id, 'name', name);
		}
	}, [materialId]);

	const getMaterial = () => {
		Materials.getAll(res => {
			setMaterials(res.data);
		});
		if (row.name !== '') {
			Materials.getMaterialById(row.materialId, res => {
				setInStock(res.data.count);
				setUnitTableId(res.data['unit.id']);
			});
		}
		Accounts.getAll(res => {
			setAllAccountsId([]);
			for (let account of res.data) {
				setAllAccountsId(prevAccount => [{ name: account.id, id: account.id }, ...prevAccount]);
				if (account.id > nextAccountId) setNextAccountId(account.id + 1);
			}
		});
		States.getAll(res => {
			setStateName(res.data.find(state => row.stateId === state.id).name);
		});
	};

	useEffect(() => {
		Materials.getMaterialById(row.materialId, res => {
			setInStock(res.data.count);
			setUnitTableId(res.data['unit.id']);
			setUnitName(res.data['unit.name']);
		});
		const materialIndex = materials.findIndex(material => material.name === name);
		if (materialIndex !== -1) {
			setIsMaterialExists(true);
		} else {
			setIsMaterialExists(false);
		}
	}, [name]);

	useEffect(() => {
		getMaterial();
	}, [row.name, row.stateId, accountCreated, isShowPopup]);

	useEffect(() => {
		setStateId(
			Number(released) >= Number(count) ? 5 : Number(inStock) >= Number(count) ? 4 : Number(inStock) === 0 ? 1 : 2
		);
	}, [inStock, released, count]);

	useEffect(() => {
		if (materialDelete.materialId !== null) {
			setIsShowDeleteMaterialPopup(true);
			setIsShowAddNewMaterialPopup(false);
		}
	}, [materialDelete.materialId]);

	const searchCompletion = value => {
		if (value.length === 0) {
			Materials.getAll(res => fetchCompletions(res.data));
		} else {
			console.log(value?.replace('/', '%252F'));
			Materials.getMaterialBySubstring(value?.replace('/', '%252F'), res => {
				fetchCompletions(res.data);
			});
		}
	};

	useEffect(() => {
		if (columnNames.find(name => name.title === 'Заявлено') && count - inStock - released <= 0) {
			deletePosition(row.id);
		}
	});

	const updateMaterialName = e => {
		updateMaterials();
		updatePositions(e, true);
	};

	const searchMaterial = e => {
		const materialIndex = materials.findIndex(
			material => material.name === (e.target.value === undefined ? e.target.textContent : e.target.value)
		);
		if (materialIndex !== -1) {
			setMaterialId(materials[materialIndex].id);
			setUnitTableId(materials[materialIndex]['unit.id']);
			setIsMaterialIsSave(true);
			loadAllData();
		} else {
			if (!isMaterialsUpdate) {
				setIsMaterialIsSave(false);
			}
			if (!isShowDeleteMaterialPopup && !isShowPopup && !isMaterialsUpdate && name !== '') {
				setIsShowAddNewMaterialPopup(true);
			}
			setIsMaterialsUpdate(false);
		}
	};

	const updateMaterials = () => {
		Materials.update({
			Material: {
				id: materialId,
				name: name,
				count: count,
				unitId: Number(unitTableId),
			},
		});
	};

	const updateMaterialsAvailable = e => {
		console.log('tyt', unitTableId);
		Materials.update(
			{
				Material: {
					id: row.materialId,
					name: name,
					count: inStock,
					unitId: unitTableId,
				},
			},
			e => {
				updatePositions(e);
			}
		);
	};

	const apiPositionsUpdate = (rowId, accountIdPopup = false, realDateDeliveryPopup = false) => {
		console.log(row.accounts);
		onAccountChange();
		Positions.update(
			{
				Position: {
					id: Number(rowId),
					count: count,
					dateAdding: dateTranslate(row.dateAdding, 'input'),
					link: linkName,
					weight: weight,
					volume: volume,
					dateDelivery: dateDelivery.input === 'Invalid Date' ? '' : dateDelivery.input,
					applicationId: id,
					materialId: materialId,
					stateId: stateId,
					released: released,
					returned: returned,
					comment: notes,
					name: name,
					realDateDelivery: realDateDeliveryPopup.input,
					realDateDeliveryPopup: realDateDeliveryPopup.input,
					accountId: accountIdPopup === null ? null : !accountIdPopup ? accountId : accountIdPopup,
				},
			},
			getMaterial
		);
	};

	if (check === true) {
		checkBlur = () => {
			setTimeout(() => {
				const rowCheck = document.getElementsByClassName('lower-table-up')[0];
				const inputsCheck = rowCheck.getElementsByTagName('input');
				const positionCheck = inputsCheck[0];
				const numberCheck = inputsCheck[1];
				const deliveryDateCheck = inputsCheck[2];
				const linkNameCheck = inputsCheck[3];
				const linkUrlCheck = inputsCheck[4];
				const weightCheck = inputsCheck[5];
				const volumeCheck = inputsCheck[6];
				const unitCheck = rowCheck.getElementsByTagName('select')[2];
				const textareaCheck = rowCheck.getElementsByTagName('textarea')[0];

				if (materials.findIndex(material => material.name === positionCheck.value) === -1) {
					dispatch(showAlert(materialNotFoundAlert));
					return;
				}

				if (
					positionCheck !== document.activeElement &&
					numberCheck !== document.activeElement &&
					deliveryDateCheck !== document.activeElement &&
					linkNameCheck !== document.activeElement &&
					linkUrlCheck !== document.activeElement &&
					weightCheck !== document.activeElement &&
					volumeCheck !== document.activeElement &&
					textareaCheck !== document.activeElement &&
					unitCheck !== document.activeElement &&
					name &&
					count
				) {
					document.getElementsByClassName('lower-table-up')[0].classList.remove('hidden');

					const today = dateTranslate(new Date(), 'input');

					let materialId;
					Materials.getMaterialByName(name.replace('/', '%252F'), res => {
						console.log(name.replace('/', '%2F'));
						materialId = res.data[0].id;
						id
							? Positions.create(
									{
										Position: {
											count: count,
											dateAdding: today,
											link: linkName,
											weight: weight,
											volume: volume,
											dateDelivery:
												dateDelivery.input === 'Invalid Date' ? '' : dateDelivery.input,
											applicationId: id,
											materialId: materialId,
											stateId: 1,
											comment: notes,
											// realDateDelivery: realDateDeliveryPopup.input,
											returned: 0,
											released: 0,
										},
									},
									res => {
										addNewPosition({
											id: res.data.id,
											name: name,
											count: count,
											materialId: materialId,
											dateAdding: today,
											dateDelivery:
												dateDelivery.input === 'Invalid Date' ? '' : dateDelivery.input,
											stateId: 1,
											link: linkName,
											weight: weight,
											volume: volume,
											applicationId: id,
											comment: notes,
											realDateDelivery: '',
											returned: 0,
											released: 0,
										});
										applicationUpdate(2);
									}
							  )
							: addNewPosition({
									count: count,
									materialId: materials.find(material => material.name === name).id,
									dateAdding: today,
									dateDelivery: dateDelivery.input === 'Invalid Date' ? '' : dateDelivery.input,
									stateId: 1,
									link: linkName,
									weight: weight,
									volume: volume,
									comment: notes,
									name: name,
									unitId: unitTableId,
									realDateDelivery: '',
									returned: 0,
									released: 0,
							  });
					});

					const blurEl = document.getElementsByClassName('active')[0];
					blurEl.classList.toggle('active');
					setIsCreating(false);
					setPosition('');
					setNumber('');
					setUnit(row.unit);
					setUnitTableId(unitTableId);
					setLinkName('');
					setLinkUrl('');
					setWeight('');
					setVolume('');
					setNotes('');
					setDeliveryDate({
						input: '',
						span: '',
					});
				}
			}, 1);
		};
	}

	const onClickCancel = () => {
		setIsShowPopup(false);
	};

	const onClickOk = () => {
		Materials.create(
			{
				Material: {
					name: name,
					unitId: Number(unitTableId),
					count: 0,
				},
			},
			() => {
				Materials.getAll(res => {
					setMaterials(res.data);
				});
				setIsMaterialIsSave(true);
				inputName.current.focus();
			}
		);
		setIsShowPopup(false);
	};

	return (
		<>
			<div
				className={
					(columnNames.find(name => name.title === 'Заявлено') && count - inStock - released) <= 0
						? 'Lower-table-row-content hide'
						: 'Lower-table-row-content'
				}
			>
				<div className={'Lower-table-row ' + blur}>
					{columnNames.map((title, index) => {
						let content;
						switch (title.className) {
							case 'materialId':
								content = (
									<Select
										classNameSelect={title.className + ' cell'}
										value={unitTableId}
										onClickHandler={e => {
											e.target.classList.toggle('arrow-active');
										}}
										onBlurHandler={e => {
											e.target.classList.remove('arrow-active');
											updateMaterials();
										}}
										onChangeHandler={e => {
											// setUnitName(e.target.value);
											setUnitTableId(e.target.value);
										}}
										options={units}
										dataId={row.id}
										name="materialId"
										disabled={!button || disabled || check || isMaterialIdNull}
									/>
								);
								break;
							case 'link':
								content = (
									<div className={title.className + ' cell'}>
										<label
											onClick={e => followingLink(e, linkName, button)}
											onMouseMove={e => linkPointerEnter(e, button)}
											onMouseOut={e => linkPointerOut(e)}
										>
											<Input
												onBlurHandler={checkBlur}
												onChangeHandler={e => setLinkName(e.target.value)}
												value={linkName}
												type="text"
												classNameInput="link-name"
												placeholder={C.newPosition[0]}
												dataId={row.id}
												name="link-name"
											/>
											<Input
												onBlurHandler={checkBlur}
												onChangeHandler={e => setLinkName(e.target.value)}
												value={linkName}
												type="url"
												classNameInput="link-url"
												placeholder={C.newPosition[1]}
												dataId={row.id}
												name="link"
												disabled={disabled || isMaterialIdNull || (!button ? true : false)}
											/>
										</label>
									</div>
								);
								break;
							case 'stateId':
								content = (
									<div
										className={
											title.className +
											' cell ' +
											(check
												? C.requestStatesColors[1].className
												: C.requestStatesColors[stateId].className)
										}
									>
										{check ? 'Новая' : C.requestStatesColors[stateId].name}
									</div>
								);
								break;
							case 'dateAdding':
								content = (
									<div className={title.className + ' cell'}>
										{check
											? dateTranslate(new Date(), 'span')
											: dateTranslate(row[title.className], 'span')}
									</div>
								);
								break;
							case 'dateDelivery':
								content = (
									<div className={'cell ' + title.className}>
										<Input
											type="date"
											required={true}
											onChangeHandler={e => {
												setDeliveryDate({
													input: e.target.value,
													span: dateTranslate(e.target.value, 'span'),
												});
											}}
											onBlurHandler={checkBlur}
											value={dateDelivery.input}
											spanText={dateDelivery.span}
											dataId={row.id}
											name="dateDelivery"
											disabled={disabled || isMaterialIdNull}
										/>
									</div>
								);
								break;
							case 'dateAccount':
								content = (
									<div className={'cell ' + title.className}>
										{dateAccount === '' ? '—' : dateAccount}
									</div>
								);
								break;
							case 'name':
								content = (
									<div className="nameContainer">
										<Input
											onChangeHandler={e => {
												setPosition(e.target.value);
												searchCompletion(e.target.value);
											}}
											onBlurHandler={e => {
												setFocus(false);
												searchMaterial(e);
												if (check === true) {
													checkBlur();
												}
											}}
											classNameInput={
												title.className +
												' cell' +
												(!materialIsSave ? ' material-not-save' : '')
											}
											placeholder={C.newPosition[3]}
											required={true}
											value={name}
											dataId={row.id}
											name="name"
											className="nameInputContainer"
											onFocusHandler={e => {
												searchCompletion(e.target.value);
												setFocus(true);
												isChange = false;
											}}
											inputRef={inputName}
											disabled={(!button ? true : false) || disabled}
										/>

										{button ? (
											<div className={'applications' + (focus ? ' show' : '')}>
												{name === row.name || name.length === 0 || isMaterialExists ? (
													<></>
												) : (
													<div
														className="updateMaterial"
														onMouseDown={e => {
															updateMaterialName(
																e.target.parentNode.previousSibling.firstChild
																	.nextSibling
															);
															isChange = true;
															setIsMaterialsUpdate(true);
														}}
													>
														{C.updateNameMaterial}
													</div>
												)}
												{completionsName[0] ? (
													completionsName.map((completion, index) => {
														return (
															<div className="completionBlock">
																<div
																	className="completion"
																	key={index}
																	onMouseDown={e => {
																		if (id !== false) {
																			apiPositionsUpdate(row.id);
																		}
																		setMaterialId(completion.id);
																		setFocus(false);
																		setPosition(e.target.textContent);
																		searchCompletion(e.target.textContent);
																		inputName.current.focus();
																		isChange = true;
																	}}
																	name="name"
																	data-id={row.id}
																>
																	{completion.name}
																</div>
																<div className="removePosition-block">
																	<div
																		className="removePosition close"
																		onMouseDown={e => {
																			let materialName =
																				e.target.parentNode.previousSibling
																					.textContent;
																			setMaterialDelete({
																				name: materialName,
																				materialId: materials.find(
																					material =>
																						material.name === materialName
																				).id,
																			});
																			setIsShowDeleteMaterialPopup(true);
																			//searchCompletion(0);
																		}}
																	></div>
																</div>
															</div>
														);
													})
												) : (
													<div
														className="addNewMaterial"
														onMouseDown={() => {
															setFocus(false);
															setIsShowPopup(true);
														}}
													>
														{C.addNewMaterial}
													</div>
												)}
											</div>
										) : (
											<div></div>
										)}
									</div>
								);
								break;
							case 'buy':
								content = (
									<div
										className={
											count - inStock - released <= 0
												? title.className + ' cell buy-green'
												: title.className + ' cell'
										}
									>
										{count - inStock - released <= 0
											? 0
											: inStock === undefined
											? 0
											: count - inStock - released}
									</div>
								);
								break;
							case 'count':
								content = (
									<Input
										type="number"
										min="0"
										onBlurHandler={checkBlur}
										classNameInput={title.className + ' cell'}
										// className="cell"
										required={true}
										value={count}
										onChangeHandler={e =>
											setNumber(e.target.value.includes('-') ? count : e.target.value)
										}
										dataId={row.id}
										name="count"
										disabled={(!button ? true : false) || disabled || isMaterialIdNull}
										className="countInput"
									/>
								);
								break;
							case 'return':
								content = (
									<Input
										type="number"
										min="0"
										onBlurHandler={checkBlur}
										classNameInput={
											Number(returned) > Number(released)
												? 'warning ' + title.className + ' cell'
												: title.className + ' cell'
										}
										value={returned}
										onChangeHandler={e => {
											setReturned(e.target.value);
										}}
										dataId={row.id}
										name="return"
										disabled={isMaterialIdNull}
									/>
								);
								break;
							case 'weight':
								content = (
									<Input
										type="number"
										min="0"
										onBlurHandler={checkBlur}
										classNameInput={title.className + ' cell'}
										value={weight}
										onChangeHandler={e => setWeight(e.target.value)}
										dataId={row.id}
										name="weight"
										disabled={disabled || isMaterialIdNull}
									/>
								);
								break;
							case 'released':
								content = (
									<Input
										type="number"
										min="0"
										onBlurHandler={e => {
											checkBlur(e);
										}}
										classNameInput={title.className + ' cell'}
										value={parseInt(released)}
										onChangeHandler={e => {
											setReleased(e.target.value);
										}}
										dataId={row.id}
										name="released"
										disabled={isMaterialIdNull}
									/>
								);
								break;
							case 'inStock':
								content = <div className="inStock cell">{inStock === undefined ? 0 : inStock}</div>;
								break;
							case 'available':
								content = (
									<Input
										type="number"
										min="0"
										onBlurHandler={e => {
											updateMaterialsAvailable(e);
											updatePositions(e);
										}}
										classNameInput="available cell"
										value={inStock === null || typeof inStock === 'undefined' ? 0 : inStock}
										onChangeHandler={e => {
											setInStock(e.target.value);
										}}
										dataId={row.id}
										name="available"
										disabled={isMaterialIdNull}
									/>
								);
								break;
							case 'volume':
								content = (
									<Input
										min="0"
										type="number"
										onBlurHandler={checkBlur}
										classNameInput={title.className + ' cell'}
										value={volume}
										onChangeHandler={e => setVolume(e.target.value)}
										dataId={row.id}
										name="volume"
										disabled={disabled || isMaterialIdNull}
									/>
								);
								break;
							case 'accountId':
								{
									columnNames.find(name => name.title === 'В наличии') && accountId === null
										? (content = <div className="accountId cell accountIdNull">–</div>)
										: columnNames.findIndex(name => name.className === 'dateAccount') >= 0
										? (content = (
												<div
													className={
														accountId === null ? 'attach cell' : title.className + ' cell'
													}
												>
													<div
														className="accountId__number"
														onClick={() => {
															setIsShowInformationPopup(true);
															const allAccounts = allAccountsId.sort((a, b) => {
																return Number(b.name) - Number(a.name);
															});
															console.log(allAccounts);
															setAllAccountsId(allAccounts);
															if (selectNumberAccount === 2) {
																setSelectNumberAccount(allAccounts[0]?.id);
															}
															console.log(selectNumberAccount);
														}}
														// row.accounts.accountNumber
													>
														{accountId === null ? C.attach : accountId}
													</div>
													{accountId !== null ? (
														<div
															className="accountId__info"
															onClick={() => {
																setIsActiveStoragePopup(true);
															}}
														>
															{C.info}
														</div>
													) : (
														<></>
													)}
												</div>
										  ))
										: (content = (
												<div
													className={
														accountId == null ? 'attach cell' : title.className + ' cell'
													}
													onClick={() => {
														setIsShowStoragePopup(true);
													}}
												>
													{accountId == null ? C.attach : row.accounts.accountNumber}
												</div>
										  ));
								}
								break;
							default:
								content = <div className={title.className + ' cell'}>{row[title.className]}</div>;
								break;
						}
						return <LowerTableCell content={content} key={index} />;
					})}
					{!disabled ? (
						<div className="removePosition-block">
							<div
								className="removePosition close"
								onClick={() => {
									if (check) {
										hideCreatingRow();
										return;
									}
									setIsShowDeletePositionsPopup(true);
									setIdRow(row.id);
								}}
							></div>
						</div>
					) : null}
				</div>
				<div className="Lower-table-row-notes">
					<Textarea
						placeholder={C.newPosition[2]}
						onBlurHandler={checkBlur}
						value={notes}
						onChangeHandler={e => setNotes(e.target.value)}
						disabled={disabled || isMaterialIdNull}
						name="comment"
						dataId={row.id}
					/>
				</div>
			</div>
			<NameInputPopup
				value={name}
				isShowPopup={isShowPopup}
				onClickOk={onClickOk}
				onClickCancel={onClickCancel}
				onChangeName={setPosition}
				units={units}
				setUnit={setUnitTableId}
				unitId={unitTableId}
			/>
			<InformationPopup
				isShowInformationPopup={isShowInformationPopup}
				setIsShowInformationPopup={setIsShowInformationPopup}
				dateTranslate={dateTranslate}
				dateDelivery={dateDelivery.span}
				allAccountsId={allAccountsId}
				setIsShowCreatePopup={setIsShowCreatePopup}
				selectNumberAccount={selectNumberAccount}
				setSelectNumberAccount={setSelectNumberAccount}
				realDateDelivery={realDateDelivery}
				setRealDateDelivery={setRealDateDelivery}
				dataId={row.id}
				updatePositions={apiPositionsUpdate}
				setAccountId={setAccountId}
				accountId={accountId}
				setAccountNumber={setAccountNumber}
			/>
			<AccountCreatingCard
				isShowCreatePopup={isShowCreatePopup}
				setIsShowCreatePopup={setIsShowCreatePopup}
				setSelectNumberAccount={setSelectNumberAccount}
				serialNumber={nextAccountId}
				setAccountCreated={setAccountCreated}
				accountCreated={accountCreated}
				setNextAccountId={setNextAccountId}
			/>
			<StoragePopup active={isShowStoragePopup} id={accountId} onClickExit={setIsShowStoragePopup} />
			<RemoveMaterialPopup
				isShowDeleteMaterialPopup={isShowDeleteMaterialPopup}
				setIsShowDeleteMaterialPopup={setIsShowDeleteMaterialPopup}
				materialDelete={materialDelete}
				searchCompletion={searchCompletion}
				name={name}
				loadAllData={loadAllData}
			/>
			<AddNewMatertialPopup
				isShowAddNewMaterialPopup={isShowAddNewMaterialPopup}
				setIsShowAddNewMaterialPopup={setIsShowAddNewMaterialPopup}
				materialName={name}
				unitName={unitName}
				setIsMaterialIsSave={setIsMaterialIsSave}
				name={name}
				unitTableId={unitTableId}
				setMaterials={setMaterials}
				setPosition={setPosition}
				apiPositionsUpdate={apiPositionsUpdate}
				rowId={row.id}
				setMaterialId={setMaterialId}
				inputName={inputName}
			/>
			<DeletePositionsPopup
				setIsShowDeletePositionsPopup={setIsShowDeletePositionsPopup}
				id={idRow}
				applicationId={id}
				deletePosition={deletePosition}
				applicationUpdate={applicationUpdate}
				positionsCount={positionsLength}
				isShowDeletePositionsPopup={isShowDeletePositionsPopup}
				setIsShowDeletePositionsPopup={setIsShowDeletePositionsPopup}
			/>
			<StoragePopup id={accountId} active={isActiveStoragePopup} onClickExit={setIsActiveStoragePopup} />
		</>
	);
};

const followingLink = (e, url, button) => {
	if (e.ctrlKey || !button) {
		window.open(url);
	}
};

const linkPointerEnter = (e, button) => {
	if (e.ctrlKey || !button) {
		e.target.style.cursor = 'pointer';
	} else {
		e.target.style.cursor = 'text';
	}
};

const linkPointerOut = e => {
	e.target.style.cursor = 'text';
};

export default LowerTableRow;
