import axios from "axios";
import { API_URL } from "./constants";

const C = {
  Users: "users",
  OurOrganizations: "OurOrganizations",
  Materials: "Materials",
  Customers: "Customers",
  Buildings: "Buildings",
  Units: "Units",
  States: "States",
  Positions: "Positions",
  Regions: "Regions",
  measuringEmployees: "measuringEmployees",
  Applications: "application",
  Accounts: "accounts",
  Provider: "provider",
};

const T = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

const regionId = () => Number(localStorage.getItem("regionId"));

const addRegionId = (data, methodClass, requestType) => {
  if (
    (methodClass === C.Applications || methodClass === C.Materials) &&
    (requestType === T.POST || requestType === T.PUT)
  ) {
    if (methodClass === C.Applications) {
      data.regionId = Number(localStorage.getItem("regionId"));
    } else {
      data.Material.regionId = Number(localStorage.getItem("regionId"));
    }
  }
};

const commonRequest = (
  methodClass,
  requestType,
  method,
  data,
  success = () => console.error("Функция success не задана")
) => {
  const token = localStorage.getItem("accessToken");
  if (token === null && !document.location.pathname.includes("auth")) {
    document.location.replace("/authorization");
    return;
  }

  let response;
  addRegionId(data, methodClass, requestType);

  requestType === "GET"
    ? (response = (token) =>
        axios.get(API_URL + methodClass + "/" + method + data, {
          headers: {
            token,
            "Content-Type": "text/plain",
          },
          responseType:
            typeof data === "string"
              ? data.includes("document")
                ? "arraybuffer"
                : "json"
              : "json",
        }))
    : requestType === "DELETE"
    ? (response = (token) =>
        axios.delete(API_URL + methodClass + "/" + method + data, {
          headers: { token },
        }))
    : (response = (token) =>
        axios({
          method: requestType,
          baseURL: API_URL,
          // responseType: "json",
          url: methodClass + "/" + method,
          headers: { token },
          data,
        }));
  response(token)
    .then(success)
    .catch((err) => {
      if (method === "updateTokens") {
        localStorage.clear();
        if (document.location.pathname !== "/authorization") {
          document.location.replace("/authorization");
        }
      }
      if (err.message.includes("401")) {
        if (localStorage.length === 0) {
          if (document.location.pathname !== "/authorization") {
            document.location.replace("/authorization");
          }
          return;
        }
        const refreshToken = localStorage.getItem("refreshToken");
        const id = localStorage.getItem("id");
        Users.updateTokens({ refreshToken, id }, (res) => {
          const { accessToken, refreshToken } = res.data;
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          response(accessToken).then(success);
        });
        return;
      }

      console.log(
        `Ошибка в методе ${method} с данными ${JSON.stringify(
          data
        )}: ${JSON.stringify(err)}`
      );
    });
};

export const Accounts = {
  create: (
    data = {
      accountNumber: "",
      invoiceAmount: 0,
      dateAccount: "",
      client: "",
      supplier: "",
    },
    success
  ) => commonRequest(C.Accounts, T.POST, "", data, success),
  update: (
    data = {
      accountNumber: "",
      invoiceAmount: 0,
      dateAccount: "",
      dateOfPayment: new Date(),
      client: "",
      supplier: "",
    },
    id,
    success
  ) => commonRequest(C.Accounts, T.PUT, id, data, success),
  getAll: (success) => commonRequest(C.Accounts, T.GET, "", "", success),
  getAccount: (id = null, success) =>
    commonRequest(C.Accounts, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Accounts, T.DELETE, "", id, success),
};

export const Applications = {
  create: (
    data = {
      Application: {
        object: "",
        addressOfOrganisation: "",
        organisation: "",
        nameCreator: "",
        contractNumber: "",
        dateAccount: "",
        dateOfPayment: "",
        nameUserCompleteCalculation: "",
        comment: "",
      },
    },
    success
  ) => commonRequest(C.Applications, T.POST, "", data, success),
  update: (
    data = {
      condition: "",
      dateOfCreating: "",
      object: "",
      addressOfOrganisation: "",
      organisation: "",
      nameCreator: "",
      contractNumber: "",
      dateAccount: "",
      dateOfPayment: "",
      nameUserCompleteCalculation: "",
      comment: "",
    },
    success,
    id
  ) => commonRequest(C.Applications, T.PUT, id, data, success),
  getAll: (success) =>
    commonRequest(C.Applications, T.GET, "", regionId(), success),
  getApplication: (id = null, success) =>
    commonRequest(C.Applications, T.GET, "id/", id, success),
  getApplicationReport: (id = null, success) =>
    commonRequest(C.Applications, T.GET, "", id + "/document", success),
  delete: (id = null, success) =>
    commonRequest(C.Applications, T.DELETE, "", id, success),
};

export const Buildings = {
  create: (
    data = { Building: { name: "", address: "", customerId: null } },
    success
  ) => commonRequest(C.Buildings, T.POST, "", data, success),
  update: (
    data = { Building: { id: null, name: "", address: "", customerId: null } },
    success
  ) => commonRequest(C.Buildings, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.Buildings, T.GET, "", "", success),
  getBuilding: (id = null, success) =>
    commonRequest(C.Buildings, T.GET, "", id, success),
  getBuildingByCustomerId: (customerId = null, success) =>
    commonRequest(C.Buildings, T.GET, "customer/", customerId, success),
  delete: (id = null, success) =>
    commonRequest(C.Buildings, T.DELETE, "", id, success),
};

export const Customers = {
  create: (data = { Customer: { name: "" } }, success) =>
    commonRequest(C.Customers, T.POST, "", data, success),
  update: (data = { Customer: { id: null, name: "" } }, success) =>
    commonRequest(C.Customers, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.Customers, T.GET, "", "", success),
  getCustomer: (id = null, success) =>
    commonRequest(C.Customers, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Customers, T.DELETE, "", id, success),
};

export const Materials = {
  create: (
    data = {
      Material: {
        name: "",
        count: null,
        unitId: null,
      },
    },
    success
  ) => commonRequest(C.Materials, T.POST, "", data, success),
  update: (
    data = {
      Material: {
        id: null,
        name: "",
        count: null,
        unitId: null,
      },
    },
    success
  ) => commonRequest(C.Materials, T.PUT, "", data, success),
  getAll: (success) =>
    commonRequest(C.Materials, T.GET, "", regionId(), success),
  getMaterialById: (id = null, success) =>
    commonRequest(C.Materials, T.GET, "id/", id, success),
  getMaterialBySubstring: (substring = "", success) =>
    commonRequest(
      C.Materials,
      T.GET,
      "subname/",
      substring + "/" + regionId(),
      success
    ),
  getMaterialByName: (name = "", success) =>
    commonRequest(
      C.Materials,
      T.GET,
      "name/",
      name + "/" + regionId(),
      success
    ),
  delete: (id = null, success) =>
    commonRequest(C.Materials, T.DELETE, "", id, success),
};

export const measuringEmployees = {
  create: (
    data = {
      MeasuringEmployee: {
        name: "",
      },
    },
    success
  ) => commonRequest(C.measuringEmployees, T.POST, "", data, success),
  update: (
    data = {
      MeasuringEmployee: {
        id: null,
        name: "",
      },
    },
    success
  ) => commonRequest(C.measuringEmployees, T.PUT, "", data, success),
  getAll: (success) =>
    commonRequest(C.measuringEmployees, T.GET, "", "", success),
  getMeasuringEmployee: (id = null, success) =>
    commonRequest(C.measuringEmployees, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.measuringEmployees, T.DELETE, "", id, success),
};

export const OurOrganizations = {
  create: (data = { OurOrganization: { name: "" } }, success) =>
    commonRequest(C.OurOrganizations, T.POST, "", data, success),
  update: (data = { OurOrganization: { id: null, name: "" } }, success) =>
    commonRequest(C.OurOrganizations, T.PUT, "", data, success),
  getAll: (success) =>
    commonRequest(C.OurOrganizations, T.GET, "", "", success),
  getOrganization: (id = null, success) =>
    commonRequest(C.OurOrganizations, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.OurOrganizations, T.DELETE, "", id, success),
};

export const Providers = {
  create: (data = { name: "" }, success) =>
    commonRequest(C.Provider, T.POST, "", data, success),
  update: (data = { id: null, name: "" }, success) =>
    commonRequest(C.Provider, T.PUT, data.id, data, success),
  getAll: (success) => commonRequest(C.Provider, T.GET, "", "", success),
  getProvider: (id = null, success) =>
    commonRequest(C.Provider, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Provider, T.DELETE, "", id, success),
};

export const Positions = {
  create: (
    data = {
      Position: {
        count: null,
        dateAdding: "",
        link: "",
        weight: null,
        volume: null,
        dateDelivery: "",
        applicationId: null,
        materialId: null,
        stateId: null,
      },
    },
    success
  ) => commonRequest(C.Positions, T.POST, "", data, success),
  update: (
    data = {
      Position: {
        id: null,
        count: null,
        dateAdding: "",
        link: "",
        weight: null,
        volume: null,
        dateDelivery: "",
        applicationId: null,
        materialId: null,
        stateId: null,
      },
    },
    success
  ) => commonRequest(C.Positions, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.Positions, T.GET, "", "", success),
  getPosition: (id = null, success) =>
    commonRequest(C.Positions, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Positions, T.DELETE, "", id, success),
};

export const Regions = {
  create: (data = { Region: { name: "" } }, success) =>
    commonRequest(C.Regions, T.POST, "", data, success),
  update: (data = { Region: { id: null, name: "" } }, success) =>
    commonRequest(C.Regions, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.Regions, T.GET, "", "", success),
  getById: (id = null, success) =>
    commonRequest(C.Regions, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Regions, T.DELETE, "", id, success),
};

export const States = {
  create: (data = { State: { name: "" } }, success) =>
    commonRequest(C.States, T.POST, "", data, success),
  update: (data = { State: { id: null, name: "" } }, success) =>
    commonRequest(C.States, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.States, T.GET, "", "", success),
  getState: (id = null, success) =>
    commonRequest(C.States, T.GET, "id/", id, success),
  delete: (id = null, success) =>
    commonRequest(C.States, T.DELETE, "", id, success),
};

export const Units = {
  create: (data = { Unit: { name: "" } }, success) =>
    commonRequest(C.Units, T.POST, "", data, success),
  update: (data = { Unit: { id: null, name: "" } }, success) =>
    commonRequest(C.Units, T.PUT, "", data, success),
  getAll: (success) => commonRequest(C.Units, T.GET, "", "", success),
  getUnit: (id = null, success) =>
    commonRequest(C.Units, T.GET, "", id, success),
  delete: (id = null, success) =>
    commonRequest(C.Units, T.DELETE, "", id, success),
};

export const Users = {
  updateDataUser: (
    data = {
      email: "",
      login: "",
      name: "",
      status: null,
      regions: [],
      roots: [],
    },
    success
  ) => commonRequest(C.Users, T.POST, "updateDataUser", data, success),
  createUser: (
    data = {
      email: "",
      login: "",
      name: "",
      status: null,
      regions: [],
      roots: [],
    },
    success
  ) => commonRequest(C.Users, T.POST, "createUser", data, success),
  getUsers: (success) => commonRequest(C.Users, T.GET, "getUsers", "", success),
  getUserById: (id = null, success) =>
    commonRequest(C.Users, T.GET, "getUsers/", id, success),
  updateTokens: (
    data = {
      id: null,
      refreshToken: "",
    },
    success
  ) => commonRequest(C.Users, T.POST, "updateTokens", data, success),
  logout: (success) => commonRequest(C.Users, T.POST, "logout", "", success),
  auth: (data = { email: "", password: "" }, success) =>
    commonRequest(C.Users, T.POST, "auth", data, success),
  deleteUser: (id = null, success) =>
    commonRequest(C.Users, T.DELETE, "deleteUser/", id, success),
};
