import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Users } from "../api/methods";

const Header = ({ subtitle, backButton, backButtonPath }) => {
  const history = useHistory();
  const [regionId, setRegionId] = useState(localStorage.getItem("regionId"));
  const regions = JSON.parse(localStorage.getItem("regions")) || [];

  return (
    <div className="Header">
      <div className="title">
        Заявка<span>+</span>
      </div>
      <select
        onChange={(e) => {
          localStorage.setItem("regionId", e.target.value);
          setRegionId(e.target.value);
          history.push("/");
        }}
        value={regionId}
      >
        {regions.map((region) => (
          <option key={region.id} value={region.id}>
            {region.name}
          </option>
        ))}
      </select>
      <div className="subtitle">{subtitle}</div>
      <div
        className="backButton"
        onClick={
          backButton === "Назад"
            ? () => history.push(backButtonPath)
            : () =>
                Users.logout(() => {
                  localStorage.clear();
                  history.push("/authorization");
                })
        }
      >
        {backButton}
      </div>
    </div>
  );
};

export default Header;
