import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "../store/actions";

const Alert = () => {
  const { isShow, text } = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const close = () => dispatch(closeAlert());
  useEffect(() => {
    if (isShow) setTimeout(close, 7000);
  }, [isShow]);
  return (
    <div className="Alert" style={{ right: isShow ? 50 : "-100%" }}>
      <div className="Alert__text">{text}</div>
      <div className="Alert__close" onClick={close}>
        <div className="close"></div>
      </div>
    </div>
  );
};

export default Alert;
